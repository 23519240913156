import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.component';

import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

ReactDOM.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<App />
			</LocalizationProvider>
		</StyledEngineProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
