import { RequestError } from "../serverRequests/ServerRequest";
import { dispatchPopupEvent } from "./popupEvent";

export function dispatchRequestErrorEvent(error: RequestError) {
    let message: string = error.message ?? "S-a produs o eroare.";
    let statusCode: number = error.statusCode ?? 522;
    let title = "Error";
    let shouldLogoutUser = false;

    switch(statusCode) {
        case 400:
            title = "Bad request";
            break;
        case 401:
            title = "Acces neautorizat";
            shouldLogoutUser = true;
            break;
        case 403:
            title = "Forbidden";
            break;
        case 404:
            title = "Not found";
            break;
        case 409:
            title = "";
            break;
        case 500:
            title = "Internal server error";
            break;
        case 521: 
            title = "Eroare baza de date";
            break;
        case 522:
            title = "Eroare";
            break;
        default:
            title = "Error";
            break;
    }
    
    dispatchPopupEvent(title, message, shouldLogoutUser)
}