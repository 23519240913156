import { combineReducers } from 'redux';

import { RootState } from '../types';
import alerts from './alert';
import auth from './auth';
import application from './application';
import popup from './popup';

export default combineReducers<RootState>({
	alerts,
	auth,
	application,
	popup
});
