export interface InvoicePaymentState {
    paymentData: PaymentData,
    paymentSeries: PaymentSeriesData
}

export interface PaymentData {
    _id: string;
    totalValue: number;
    type: string;
    number: number;
    series: string;
    encashmentDate: string;
    creationDate: string;
}

export interface PaymentSeriesData {
    code: string;
    number: number;
}

export enum PaymentType {
    RECEIPT = 'payment',
    BANK = 'bank'
}