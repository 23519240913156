const ThousandSuffixes = ["", "mii", "milioane", "miliarde"];
const DigitSuffixes = ["", "zeci", "sute"];
const Digits = ["", "unu", "doi", "trei", "patru", "cinci", "șase", "șapte", "opt", "nouă"];
const Exceptions = [
    [ "unu zeci și nouă", "nouăsprezece" ],
    [ "unu zeci și opt", "optsprezece" ],
    [ "unu zeci și șapte", "șaptesprezece" ],
    [ "unu zeci și șase", "șaiprezece" ],
    [ "unu zeci și cinci", "cincisprezece" ],
    [ "unu zeci și patru", "paisprezece" ],
    [ "unu zeci și trei", "treisprezece" ],
    [ "unu zeci și doi", "doisprezece" ],
    [ "unu zeci și unu", "unsprezece" ],
    [ "unu zeci", "zece" ],
    [ "unu sute", "o sută" ],
    [ "unu mii", "o mie" ],
    [ "unu milioane", "un milion" ],
    [ "unu miliarde", "un miliard" ],
    [ "doi zeci", "două zeci" ],
    [ "doi sute", "două sute" ],
    [ "doi mii", "două mii" ],
    [ "doi milioane", "două milioane" ],
    [ "doi miliarde", "două miliarde" ]
];

export function parseNumbersToRoWords(value: number): string {
    let prefix = "";

    if (value < 0) {
            value = -value;
            prefix = "minus ";
    }

    value = parseFloat(value.toFixed(2));

    let wholePart = Math.floor(value);
    let decimalPart = parseFloat(((value - wholePart) * 100).toFixed(0));

    let result = prefix + (wholePart === 1 ? "un leu" : Int2RoString(wholePart) + " lei");
    if (decimalPart > 0) {
            result += " și " + Int2RoString(decimalPart) + (decimalPart === 1 ? " ban" : " bani");
    }

    return result;
}

function Int2RoString(value: number) {
    if (value === 0) {
        return "zero";
    }

    let parts: string[] = [];

    let thousandSuffix = 0;
    //iteram prin grupuri de cate 1000
    while (value > 0) {
        parts.push((Int2RoStringProcessThousand(value%1000) + " " + ThousandSuffixes[thousandSuffix]).trim());
        value = parseFloat((Math.floor(value/1000)).toFixed(0));
        thousandSuffix += 1;
    }

    var result = parts.reverse().join(" ");

    result = result.replaceAll(",", " ");
    //inlocuim exceptiile
    Exceptions.forEach((exception) => {
        result = result.replace(exception[0], exception[1]);
    });

    result = result.trim();

    return result;
}

function Int2RoStringProcessThousand(value: number) {
    let result = [];

    let index = 0;
    while (value > 0) {
        let digit = value % 10;
        value = Math.floor(value/10);

        if(digit === 0) {
            index += 1;
            continue;
        }

        if(index === 0) {
            result.push("și " + Digits[digit]);
        } else {
            result.push(Digits[digit] + " " + DigitSuffixes[index]);
        }

        index += 1;
    }

    result = result.reverse();

    if(result[0].startsWith("și")) {
        result[0] = result[0].substring(2);
    }

    return result;
}