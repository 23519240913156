import axios, { AxiosResponse } from 'axios';
import { RequestConfig, RequestError } from './types';
import { FilterForBackend } from '../../components/layout/invoicePage/types/types';
import { TimeRangeFilter } from '../../components/layout/scaleReportsPage/types/types';

export class BaseDataProvider {
    protected getRequestHeaders(requiresAuth: boolean): RequestConfig {
        const config: RequestConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (requiresAuth) {
            config.headers['x-session-id'] = localStorage.getItem('sessionId');
        }

        return config;
    }

    protected treatRequestErrors(err: any | import('axios').AxiosError) {
        if (axios.isAxiosError(err) && err.response) {
            const errors = err.response.data.errors;

            if (errors) {
                errors.forEach((error: any) => {
                    console.log(error);
                });
            }

            throw new RequestError(errors[0].msg, err.response.status);
        } else {
            console.log(err);
            throw new RequestError('Internal Server Error', 500);
        }
    }

    protected async launchPostRequest(uri: string, requestBody: any, requestHeaders?: any) {
        if (!requestHeaders) {
            requestHeaders = this.getRequestHeaders(true);
        }
        
        try {
            return await axios.post(uri, requestBody, requestHeaders);
        } catch (err: any | import('axios').AxiosError) {
            console.log(err);
            this.treatRequestErrors(err);
        }
    }

    protected async launchGetRequest<T>(uri: string, requestHeaders?: any, appliedFilters?: Array<FilterForBackend | TimeRangeFilter | undefined>): Promise<AxiosResponse<T>> {
        if (!requestHeaders) {
            requestHeaders = this.getRequestHeaders(true);
        }

        let requestResult = {} as AxiosResponse<T>;

        if(!!appliedFilters) {
            requestHeaders.params = {};
            
            const filtersRequestBody = {
                filters: JSON.stringify(appliedFilters)
            }

            requestHeaders.params = filtersRequestBody;
        }
        
        try {
            requestResult = await axios.get<T>(uri, requestHeaders);
        } catch (err) {
            this.treatRequestErrors(err);
        }

        return requestResult;
    }
}