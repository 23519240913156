export interface LicenseKey {
    _id: string;
    key: string;
    type: EvtLicenseKeyType;
    licenseKeyValabilityInMonths: number;
    licenseKeyActivationTimestamp: number;
    status: EvtLicenseKeyStatus;
    companyCui: string;
}

export enum EvtLicenseKeyType {
    PREMIUM = "premium"
}

export enum EvtLicenseKeyStatus {
    NEW = "new",
    ACTIVE = "active",
    EXPIRED = "expired"
}