export interface TimeRangeFilterState {
    selectedOption: TimeRangeFilterOptions;
    disabledCustomOption: boolean;
    datePickerStartDate: Date;
    datePickerEndDate: Date;
}

export interface TimeRangeFilterProps {
    onChange: (selectedTimeRange: TimeRange) => any;
}

export interface TimeRange {
    startInterval: number;
    endInterval: number;
}

export enum TimeRangeFilterOptions {
    TODAY = 'today',
    LAST_24_HOURS = 'last24Hours',
    LAST_7_DAYS = 'last7Days',
    CUSTOM_DATE = 'customDate'
}