import { ApplicationAction } from "../types"
import { ApplicationActionTypes } from "./types"

export const setActiveModule = (module: string) => (dispatch: any) => {
    const setModuleAction: ApplicationAction = {
        type: ApplicationActionTypes.SET_ACTIVE_MODULE,
        payload: {
            module: module
        }
    };

    dispatch(setModuleAction);
}

export const setScaleReportsForInvoice = (scaleReportsIds: Array<string>) => (dispatch: any) => {
    const setScaleReportsForInvoice: ApplicationAction = {
        type: ApplicationActionTypes.SET_SCALE_REPORTS_FOR_INVOICE,
        payload: {
            selectedScaleReportsIds: scaleReportsIds
        }
    };

    dispatch(setScaleReportsForInvoice);
}