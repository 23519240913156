
export function translateStatus(reportStatus: string): string {
    switch(reportStatus) {
        case "Complete":
            return "Complet";
        case "New":
            return "Nou";
        case "Pending":
            return "In asteptare";
        case "Cancelled":
            return "Anulat";
        default:
            return reportStatus;
    }
}