import { Component, ReactElement, RefObject, createRef } from 'react';

import './anafTokenForm.styles.css';

interface AnafTokenFormProps {
    title: string;
    token: string;
    automaticallyRefresh: boolean;
    launchAnafRequest: () => void;
    handleCheckChange: () => void;
}

interface AnafTokenFormState {
    automaticallyRefresh: boolean;
}

class AnafTokenForm extends Component<AnafTokenFormProps, AnafTokenFormState> {

    private accessTokenInput: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    constructor(props: AnafTokenFormProps) {
        super(props);

        this.handleAutomaticallyRefreshCheck = this.handleAutomaticallyRefreshCheck.bind(this);
    }

    private handleAutomaticallyRefreshCheck() {
        this.props.handleCheckChange();
    }

    public render(): ReactElement {
        return (
            <div id='token-section-container'>
                <h3 id='token-section-title'>{this.props.title}</h3>
                <button className='et-button' onClick={this.props.launchAnafRequest}>Realizeaza autentificarea</button>
                <div className='et-form-group'>
                    <label className='et-form-label'>Access Token:</label>
                    <input
                        ref={this.accessTokenInput}
                        id='access-token-input'
                        className='et-form-input'
                        type='text'
                        name='companyName'
                        disabled={true}
                        value={this.props.token}
                        required
                    />
                </div>
                <div className='et-form-group'>
                    <label className='et-form-label'>Prelungire token automata:</label>
                    <input
                        id='automatically-refresh-token'
                        className='et-form-input'
                        type='checkbox'
                        checked={this.props.automaticallyRefresh}
                        onChange={this.handleAutomaticallyRefreshCheck}
                    />
                </div>
            </div>
        )
    }
}

export default AnafTokenForm;