import React, { useState } from 'react';
import { ProfileRowData } from '../../types';
import ProfileAlert from '../profileAlert/profileAlert.component';
import './profileRowDetails.styles.css';

interface ProfileRowDetailsProps {
    data: ProfileRowData
    removeDetails: () => void;
    submitForm: (e: React.SyntheticEvent) => void;
}

interface ProfileRowDetailsState {
    [key: string]: string;
}

const ProfileRowDetails = (props: ProfileRowDetailsProps) => {
    const createState = () => {
        const state = {} as ProfileRowDetailsState;
        
        for (const data of props.data.formGroupData) {
            state[data.inputName] = data.inputDefaultValue;
        }

        return state;
    }

    const stateObj = createState();
    const [state, setState] = useState<ProfileRowDetailsState>(stateObj);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
		setState({ ...state, [e.target.name]: e.target.value });

    const { visible, type, message } = props.data.alert;

    return (
        <div className='profile-row-details-container'>
            <form onSubmit={props.submitForm} className='profile-details-form'>
                <div id='form-group-container'>
                    {props.data.formGroupData.map((groupData, index) => (
                            <div key={index} className='et-form-group data-form-group'>
                                <label className='et-form-label profile-form-label'>{groupData.labelValue}</label>
                                <input id={groupData.inputId} className='profile-data-input et-form-input' value={state[groupData.inputName]} name={groupData.inputName} type={groupData.inputType} onChange={(e) => onChange(e)} />
                            </div>
                    ))}
                </div>
                <div className='et-form-group'>
                    <input className='et-button' id='submit-form-button' type='submit' value='Salvare' />
                    <button onClick={props.removeDetails} className='et-button-no-borders' id='cancel-button'>Renunta</button>
                </div>
            </form>
            <ProfileAlert visible={visible} type={type} message={message}/>
        </div>
    )
}

export default ProfileRowDetails;