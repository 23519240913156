export function getWidgetUnitMeasure(value: number) {
    return value >= 1000000 ? "k t" : " kg";
}

export function addComas(value: number) {
    const valueParsed = value.toString().split('').reverse().join('').match(/.{1,3}/g)?.join(",").split('').reverse().join('');;
    return `${valueParsed}`;
}

export function parseTime(time: number): string {
    time = Math.round(time/1000); // transform to seconds

    if(time >= 3600) {
        const hoursValue = Math.round(time / 3600);
        const hoursText = hoursValue > 1 ? "ore" : "ora";
        const minutesValue = Math.round((time % 3600) / 60);
        const minutesText = minutesValue > 1 ? "minute" : "minut"
        return `${hoursValue} ${hoursText} ${minutesValue} ${minutesText}`;
    }
    if(time >= 60) {
        const minutesValue = Math.round((time % 3600) / 60);
        const minutesText = minutesValue > 1 ? "minute" : "minut";
        const secondsValue = Math.round(time%60);
        const secondsText = secondsValue > 1 ? "secunde" : "secunda"
        return `${minutesValue} ${minutesText} ${secondsValue} ${secondsText}`;
    }

    const secondsValue = Math.round(time * 100) / 100;
    const secondsText = secondsValue === 1 ? 'secunda' : 'secunde';
    return `${secondsValue} ${secondsText}`;
}

export function splitNetWeightsWidgetArray(input: any[][]): [string[], number[]] {
    const strings: string[] = [];
    const numbers: number[] = [];

    input.forEach(innerArray => {
        strings.push(innerArray[0]);
        numbers.push(Number((innerArray[1] / 1000).toFixed(0)));
    });

    return [strings, numbers];
}