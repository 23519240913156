import { Fragment, ReactElement } from 'react';
import { Provider, useSelector } from 'react-redux';

import store from './redux/store';
import { RootState } from './redux/types';
import LandingPage from './components/layout/landingPage/landingPage.component';
import LoginPage from './components/layout/loginPage/loginPage.component';

import './app.styles.css';
import { registerHandlers } from './redux/errorHandlers';

// Register global error handlers
registerHandlers(store);

const AppWrapper = () => (
	<Provider store={store}>
		<App />
	</Provider>
)

const App = () => {
	const isAuth = useSelector((state: RootState) => state.auth.isAuthenticated);

	const elementToShow: ReactElement = isAuth ? <LandingPage /> : <LoginPage />;

	return (
		<div className='App'>
			<Fragment>
				{elementToShow}
			</Fragment>
		</div>
	);
}

export default AppWrapper;
