import React, { ChangeEvent, Component, ReactElement, RefObject } from "react";
import seeMoreIcon from '../../../images/view.png';
import fIcon from '../../../images/f-icon.png';
import generatePdf from '../../../images/icons8-pdf-16.png';
import { ScaleReport, ScaleReportDetails } from "../../../lib/types";
import ScaleReportsSidePanel from "../../lib/scaleReportsSidePanel/scaleReportsSidePanel.component";
import { ScaleReportsController } from "./controller/scaleReports.controller";
import './scaleReports.styles.css';
import { FilterTypeToScaleReportFieldMap, FilterTypes, GetReportsResponse, ScaleReportDetailsFields, ScaleReportFields, TimeRangeFilter } from "./types/types";
import EtTimeRangeFilter from "../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.component";
import { TimeRange } from "../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.types";
import { connect } from "react-redux";
import { translateStatus } from "../../../lib/utils/translate";
import { Utils } from "../../../lib/utils/utils";
import { ETDropdown, DropdownOption } from "../../ETComponents/ETDropdown/etdropdown.component";
import { setActiveModule, setScaleReportsForInvoice } from "../../../redux/actions/application";
import { CONSTANTS } from "../../../lib/constants";
import { WorkPoint } from "../companyProfilePage/types/types";
import { PdfBuilderFactory, PdfDocumentType } from "../../../lib/pdfBuilder/pdfBuilderFactory";
import { ETIcon } from "../../ETComponents/ETIcon/eticon.component";
import { dispatchRequestErrorEvent } from "../../../lib/events/requestErrorEvent";
import { dispatchPopupEvent } from "../../../lib/events/popupEvent";

import { saveAs } from "file-saver";

import { TableVirtuoso } from "react-virtuoso";
import LoadingProgress from "./innerComponents/loadingProgress/loadingProgress.component";

import './innerComponents/loadingProgress/loadingProgress.styles.css';
import JSZip from "jszip";

interface ScaleReportsPageState {
    scaleReports: Array<ScaleReport>;
    scaleReportsFiltered: Array<ScaleReport>,
    activeFilters: Map<FilterTypes, Array<{label: string, value: string}>>,
    filtersOptions: Map<FilterTypes, Array<{label: string, value: string}>>,
    selectedReport: ScaleReport | null;
    viewId: string;
    timeRange: TimeRange;
    checkedReportsIds: Array<string>;
    totalWeight: number;
    currentDownloadProgress: number;
    maxDownloadProgress: number;
    stopArchiveDownloading: boolean;
}

interface ScaleReportsPageProps {
    setSelectedReportsIds: (scaleReportsIds: Array<string>) => void;
    setActiveModule: (moduleName: string) => void;
}

class ScaleReportsPage extends Component<ScaleReportsPageProps, ScaleReportsPageState> {
    private filterSelectRef: RefObject<any>;
    private dialogRef: RefObject<any>;
    private dialogCloseButtonRef: RefObject<any>;
    
    constructor(props: any) {
        super(props);

        this.state = {
            scaleReports: [],
            scaleReportsFiltered: [],
            activeFilters: new Map(),
            filtersOptions: new Map(),
            selectedReport: null,
            viewId: '',
            timeRange: {
                startInterval: 0,
                endInterval: 0
            },
            checkedReportsIds: [],
            totalWeight: 0,
            currentDownloadProgress: 0,
            maxDownloadProgress: 0,
            stopArchiveDownloading: false
        }

        this.filterSelectRef = React.createRef<any>();
        this.dialogRef = React.createRef<any>();
        this.dialogCloseButtonRef = React.createRef<any>();

        // bind here event handlers
        this.timeRangeFilterChanged = this.timeRangeFilterChanged.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleAllCheckboxChange = this.handleAllCheckboxChange.bind(this);
        this.applyTableFilters = this.applyTableFilters.bind(this);
        this.exportCsvData = this.exportCsvData.bind(this);
        this.getSelectOptions = this.getSelectOptions.bind(this);
        this.loadDynamicFiltersAndSummaryData = this.loadDynamicFiltersAndSummaryData.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.downloadNotices = this.downloadNotices.bind(this);
        this.closeDownloadingStateModal = this.closeDownloadingStateModal.bind(this);
    }

    async componentDidMount() {
        await this.loadWorkPointsFilter();

        this.dialogCloseButtonRef.current.addEventListener("click", this.closeDownloadingStateModal);
    }
    
    private async loadWorkPointsFilter(): Promise<void> {
        let workPoints: Array<WorkPoint> = [];
        try {
            workPoints = await ScaleReportsController.getCompanyWorkPoints();
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }
        
        const workPointsFilterOptions = workPoints.map((workPoint: WorkPoint) => ({label: workPoint.name, value: workPoint.evtId}));
        
        const stateFilterOptions =  this.state.filtersOptions;
        stateFilterOptions.set(FilterTypes.workPoints, workPointsFilterOptions);

        this.setState({
            filtersOptions: stateFilterOptions
        });
    }

    private async fetchScaleReports(): Promise<void> {
        const timeRangeFilter: TimeRangeFilter = {
            name: 'timeRange',
            values: this.state.timeRange
        }

        let result: GetReportsResponse = {
            valid: false
        };

        try {
            result = await ScaleReportsController.getReports(timeRangeFilter);
        } catch(err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }

        if (!result.valid) {
            dispatchPopupEvent('Eroare', 'Nu s-au putut prelua rapoartele.');
            return;
        }

        const scaleReportsArr = result.scaleReports ? result.scaleReports : [] as Array<ScaleReport>;
        const reportsIdsArray = scaleReportsArr.map(report => report._id);

        this.setState({
            scaleReports: scaleReportsArr.reverse(),
            checkedReportsIds: this.state.checkedReportsIds.filter((reportId) => reportsIdsArray.indexOf(reportId) >= 0)
        }, () => { 
            this.loadDynamicFiltersAndSummaryData();
        });
    }

    private loadDynamicFiltersAndSummaryData() {
        let totalWeight = 0;

        const filtersOptions = this.state.filtersOptions;

        for(let filterType in FilterTypes) {
            // reset filter options (except from workPoints)
            if (filterType.includes('workPoints')) {
                continue;
            }

            filtersOptions.set(filterType as FilterTypes, []);
        }

        for (const report of this.state.scaleReports) {
            totalWeight += this.getScaleReportNetWeight(report);

            for (const filterType in FilterTypes) {
                //take the appropiate ScaleReport property
                const scaleReportProperty: keyof ScaleReport | keyof ScaleReportDetails | undefined = FilterTypeToScaleReportFieldMap.get(filterType as FilterTypes);

                if (filterType.includes('workPoints')) {
                    continue;
                }

                if (!scaleReportProperty) {
                    throw Error("Property not found for filterType: " + filterType);
                }

                // take filter option to add it in filter drop-downs
                if (filterType.includes('transporters')) {
                    filtersOptions.get(filterType as FilterTypes)!.push({
                        label: report.details[scaleReportProperty as keyof ScaleReportDetails] as string,
                        value: report.details[scaleReportProperty as keyof ScaleReportDetails] as string
                    });
                } else if (filterType.includes('status')) {
                    filtersOptions.get(filterType as FilterTypes)!.push({
                        label: translateStatus(report[scaleReportProperty as keyof ScaleReport] as string),
                        value: report[scaleReportProperty as keyof ScaleReport] as string
                    });
                } else {
                    filtersOptions.get(filterType as FilterTypes)!.push({
                        label: report[scaleReportProperty as keyof ScaleReport] as string,
                        value: report[scaleReportProperty as keyof ScaleReport] as string
                    });
                }
            }
        }

        // iterrate again through filterOptions to remove duplicates
        for (const filterType in FilterTypes) {
            const scaleReportProperty: keyof ScaleReport | keyof ScaleReportDetails | undefined = FilterTypeToScaleReportFieldMap.get(filterType as FilterTypes);

            if (!scaleReportProperty) {
                return;
            }

            const newlySetOptions = filtersOptions.get(filterType as FilterTypes)!;
            const uniqueOptions = Utils.removeDuplicatesFromArray(newlySetOptions);

            filtersOptions.set(filterType as FilterTypes, uniqueOptions);
        }

        this.setState({
            filtersOptions: filtersOptions,
        });

        let anyFilterIsOn = false;

        for (let filterType in FilterTypes) {
            const specificFilterOptions = filtersOptions.get(filterType as FilterTypes);

            if (!specificFilterOptions || !specificFilterOptions.length) {
                continue;
            }

            this.applyTableFilters();
            anyFilterIsOn = true;
            break;
        }

        if (!anyFilterIsOn) {
            this.setState({
                scaleReportsFiltered: this.state.scaleReports.reverse(),
                totalWeight
            });
        }
    }

    private timeRangeFilterChanged(selectedTimeRange: TimeRange): void {
        this.setState(
            {
                timeRange: selectedTimeRange
            },
            () => this.fetchScaleReports()
        );
    }

    private handleCheckboxChange(checkedReportId: string, isChecked: boolean) {
        if (!isChecked) {
            const newCheckedReportsIdsArray = this.state.checkedReportsIds.filter((reportId: string) => reportId !== checkedReportId);

            this.setState({
                checkedReportsIds: newCheckedReportsIdsArray
            });
        } else {
            const newCheckedReportsIdsArray = JSON.parse(JSON.stringify(this.state.checkedReportsIds));
            newCheckedReportsIdsArray.push(checkedReportId);

            this.setState({
                checkedReportsIds: newCheckedReportsIdsArray
            });
        }
    }

    private handleAllCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
        if (!event.target.checked) {
            this.setState({
                checkedReportsIds: []
            });

            return;
        }

        const allReportsIds = this.state.scaleReportsFiltered.map((scaleReport: ScaleReport) => scaleReport._id);

        this.setState({
            checkedReportsIds: allReportsIds
        });
    }

    private showDetailsPanel(reportId: string): void {
        const selectedReport = this.state.scaleReports.filter((report) => report._id === reportId)[0];

        this.setState({
            selectedReport
        });

        const detailsPanelEL = document.getElementById('details-panel') ? document.getElementById('details-panel') : null;
        // make css class
        if (detailsPanelEL) {
            detailsPanelEL.style.width = '530px';
            detailsPanelEL.style.paddingLeft = '20px';
            detailsPanelEL.style.color = '#000000';
        }
    }

    private exportCsvData(): void {
        if(this.state.scaleReports.length === 0) {
            dispatchPopupEvent('Atentie', 'Nu sunt rapoarte de cantarire de exportat!');
            return;
        }

        const workPointIdToNameMap = new Map();

        (this.state.filtersOptions.get(FilterTypes.workPoints) || []).forEach((workPointFilterOption: {value: string, label: string }) => {
            workPointIdToNameMap.set(workPointFilterOption.value, workPointFilterOption.label);    
        });
        
        ScaleReportsController.exportCsvData(this.state.scaleReportsFiltered, this.state.totalWeight, workPointIdToNameMap);
    }

    private getWeightInTonnes() {
        return (this.state.totalWeight / 1000).toFixed(2);
    }

    private getSelectOptions(type: FilterTypes): Array<{ value: string; label: string; }> {
        const filterOptions = this.state.filtersOptions.get(type);
        return filterOptions ?? [];
    }

    public async handleSelectChanged(selectedOptions: DropdownOption, filterType: FilterTypes) {
        if(!selectedOptions || !Array.isArray(selectedOptions)) {
            return;
        }

        const activeFilters = this.state.activeFilters;
        const convertedOptions = selectedOptions.map((option) => ({ value: option.value, label: option.label }));

        activeFilters.set(filterType, convertedOptions)

        this.setState({
            activeFilters
        }, () => this.applyTableFilters());
    }

    private getScaleReportNetWeight(scaleReport: ScaleReport) {
        let weight = 0;
        const weightIn = scaleReport.details.weightIn;
        const weightOut = scaleReport.details.weightOut;

        if (weightOut && scaleReport.status === 'Complete') {
            weight += Math.abs(weightIn - weightOut);
        }

        return weight;
    }

    private async applyTableFilters() {
        const activeFilters = this.state.activeFilters;
        const filteredScaleReports: Array<ScaleReport> = [];
        let totalWeight = 0;

        for (const scaleReport of this.state.scaleReports) {
            let reportIsValid = true;

            for (const filterType in FilterTypes) {
                // get applied filter options
                const activeFilterValues = (activeFilters.get(filterType as FilterTypes) || []).map((filter) => filter.value);

                //if empty
                if (!activeFilterValues || activeFilterValues.length === 0) {
                    continue;
                }

                // get associated scale report field
                const reportField = FilterTypeToScaleReportFieldMap.get(filterType as FilterTypes);
                
                if (!reportField) {
                    continue;
                }

                if(filterType.includes('transporters')) {
                    if (!activeFilterValues.includes(scaleReport.details[reportField as ScaleReportDetailsFields]!)) {
                        reportIsValid = false;
                    }
                } else {
                    if (!activeFilterValues.includes(scaleReport[reportField as ScaleReportFields])) {
                        reportIsValid = false;
                    }
                }
            }

            if (reportIsValid) {
                filteredScaleReports.push(scaleReport);
                totalWeight += this.getScaleReportNetWeight(scaleReport);
            }
        }

        const filteredScaleReportsIds = filteredScaleReports.map(scaleReport => scaleReport._id);

        // we uncheck the reports that are filtered out
        const checkedReportsAfterFiltering = this.state.checkedReportsIds.filter(scaleReportId => filteredScaleReportsIds.includes(scaleReportId));

        this.setState({
            scaleReportsFiltered: filteredScaleReports,
            totalWeight: totalWeight,
            checkedReportsIds: checkedReportsAfterFiltering
        });
    }

    private createInvoice() {
        const checkedScaleReports = this.state.scaleReports.filter((scaleReport: ScaleReport) => this.state.checkedReportsIds.includes(scaleReport._id))
        const firstReportsClient = checkedScaleReports[0].clientName;

        let allCheckedReportsHaveTheSameClient = true;
        let allCheckedReportsAreComplete = true;
        let allScaleReportsAreNotInvoiced = true;

        for (const scaleReport of checkedScaleReports) {
            if (scaleReport.clientName !== firstReportsClient) {
                allCheckedReportsHaveTheSameClient = false;
            }

            if (scaleReport.status !== "Complete") {
                allCheckedReportsAreComplete = false;
            }

            if (scaleReport.invoiceDetails && scaleReport.invoiceDetails.invoiced) {
                allScaleReportsAreNotInvoiced = false;
            }
        }

        if (!allCheckedReportsAreComplete) {
            dispatchPopupEvent(
                'Atentie',
                'Nu puteti emite factura pe baza rapoartelor de cantarire incomplete / anulate. ' +
                'Va rugam asigurati-va ca selectati doar rapoartele de cantarire complete.'
            );
            return;
        }

        if (!allScaleReportsAreNotInvoiced) {
            dispatchPopupEvent(
                'Atentie',
                'Nu puteti emite factura pe baza rapoartelor de cantarire deja facturate. ' +
                'Va rugam asigurati-va ca selectati doar rapoartele de cantarire nefacturate.'
            );
            return;
        }

        if (!allCheckedReportsHaveTheSameClient) {
            dispatchPopupEvent(
                'Atentie',
                'Nu puteti emite factura pe baza rapoartelor de cantarire efectuate pentru mai multi clienti. ' + 
                'Va rugam asigurati-va ca selectati rapoarte de cantarire efectuate pentru un singur client.'
            );
            return;
        }

        this.props.setSelectedReportsIds(this.state.checkedReportsIds);
        this.props.setActiveModule(CONSTANTS.MODULES.INVOICE_PAGE);
    }

    private async downloadNotices( ) {
        this.dialogRef.current.showModal();

        this.setState({
            stopArchiveDownloading: false
        }, async () => {

            const checkedScaleReports = this.state.scaleReports.filter((scaleReport: ScaleReport) => this.state.checkedReportsIds.includes(scaleReport._id))
            
            this.setState({
                maxDownloadProgress: checkedScaleReports.length
            });

            const zip = new JSZip();

            for (const scaleReport of checkedScaleReports) {
                try {
                    if (this.state.stopArchiveDownloading) {
                        break;
                    }

                    await ScaleReportsController.createScaleReportPdfForArchive(scaleReport, zip);

                    this.setState({
                        currentDownloadProgress: this.state.currentDownloadProgress + 1
                    });
                } catch (err: any) {
                    console.log(err);
                }
            };

            this.setState({
                currentDownloadProgress: 0
            });
            
            if(!this.state.stopArchiveDownloading) {
                const zipBlob = await zip.generateAsync({ type: "blob"});
                saveAs(zipBlob, "Avize_Evertech_Portal.zip");
                this.closeDownloadingStateModal();
                this.setState({
                    stopArchiveDownloading: false
                })
            }
            })
    }

    private regenerateDeliveryNote(report: ScaleReport) {
        const pdfBuilder = PdfBuilderFactory.getPdfBuilder(PdfDocumentType.DeliveryNote);

        if(!pdfBuilder) {
            return;
        }

        pdfBuilder.downloadDocument(report);
    }

    private closeDownloadingStateModal() {
        this.dialogRef.current.close();

        if(!this.state.stopArchiveDownloading) {
            // set state
            this.setState({
                stopArchiveDownloading: true
            });
        }
    }

    render(): ReactElement { 
        const featureDate = new Date();
        featureDate.setFullYear(2024, 5, 1);// 01.06.2024, yes, months start from 0 but days and years do not
        featureDate.setHours(0,0,1);
        
        const checkAllChecked = this.state.scaleReportsFiltered.length > 0 && this.state.scaleReportsFiltered.length === this.state.checkedReportsIds.length;
        
        return (
            <div className='etportal-page-container'>
                <h1 className='module-title'>Rapoarte de cantarire</h1>
                <ScaleReportsSidePanel scaleReport={this.state.selectedReport}/>
                <dialog id="loading-dialog" ref={this.dialogRef}>
                    <div id="loading-dialog-wrapper">
                        <button autoFocus id="close-loading-dialog" ref={this.dialogCloseButtonRef}></button>
                        {<LoadingProgress  currentProgress={this.state.currentDownloadProgress} maxProgress={this.state.maxDownloadProgress}></LoadingProgress>}
                    </div>
                </dialog>
                <div id='filters-container'>
                    <div id='table-filters-container'>
                        <ETDropdown
                            id='supplier-filter'
                            selectRef={this.filterSelectRef}
                            selectOptions={this.getSelectOptions(FilterTypes.suppliers)}
                            name='supplier'
                            isClearable={true}
                            isMulti={true}
                            createLabelStartText="Adauga '"
                            createLabelEndText="'"
                            placeholder='Furnizor...'
                            handleSelectChanged={(newValue) => this.handleSelectChanged(newValue, FilterTypes.suppliers)}
                            handleNoOptionsMessage={() => 'Nu s-a gasit niciun furnizor.'}
                        />
                        <ETDropdown
                            id='client-filter'
                            selectRef={this.filterSelectRef}
                            selectOptions={this.getSelectOptions(FilterTypes.clients)}
                            name='client'
                            isClearable={true}
                            isMulti={true}
                            createLabelStartText="Adauga '"
                            createLabelEndText="'"
                            placeholder='Client...'
                            handleSelectChanged={(newValue) => this.handleSelectChanged(newValue, FilterTypes.clients)}
                            handleNoOptionsMessage={() => 'Nu s-a gasit niciun client.'}
                        />
                        <ETDropdown
                            id='transporter-filter'
                            selectRef={this.filterSelectRef}
                            selectOptions={this.getSelectOptions(FilterTypes.transporters)}
                            name='transporter'
                            isClearable={true}
                            isMulti={true}
                            createLabelStartText="Adauga '"
                            createLabelEndText="'"
                            placeholder='Transportator...'
                            handleSelectChanged={(newValue) => this.handleSelectChanged(newValue, FilterTypes.transporters)}
                            handleNoOptionsMessage={() => 'Nu s-a gasit niciun transportator.'}
                        />
                        <ETDropdown
                            id='product-filter'
                            selectRef={this.filterSelectRef}
                            selectOptions={this.getSelectOptions(FilterTypes.products)}
                            name='product'
                            isClearable={true}
                            isMulti={true}
                            createLabelStartText="Adauga '"
                            createLabelEndText="'"
                            placeholder='Produs...'
                            handleSelectChanged={(newValue) => this.handleSelectChanged(newValue, FilterTypes.products)}
                            handleNoOptionsMessage={() => 'Nu s-a gasit niciun produs.'}
                        />
                    </div>
                    <div id='table-filters-container'>
                        <ETDropdown
                            id='work-point-filter'
                            selectRef={this.filterSelectRef}
                            selectOptions={this.getSelectOptions(FilterTypes.workPoints)}
                            name='workPoint'
                            isClearable={true}
                            isMulti={true}
                            createLabelStartText="Adauga '"
                            createLabelEndText="'"
                            placeholder='Punct de lucru...'
                            handleSelectChanged={(newValue) => this.handleSelectChanged(newValue, FilterTypes.workPoints)}
                            handleNoOptionsMessage={() => 'Nu s-a gasit niciun punct de lucru.'}
                        />
                        <ETDropdown
                            id='status-filter'
                            selectRef={this.filterSelectRef}
                            selectOptions={this.getSelectOptions(FilterTypes.status)}
                            name='status'
                            isClearable={true}
                            isMulti={true}
                            createLabelStartText="Adauga '"
                            createLabelEndText="'"
                            placeholder='Status...'
                            handleSelectChanged={(newValue) => this.handleSelectChanged(newValue, FilterTypes.status)}
                            handleNoOptionsMessage={() => 'Nu s-a gasit niciun status.'}
                        />
                    </div>
                    <div id="table-filters-container">
                        <EtTimeRangeFilter onChange={this.timeRangeFilterChanged}/>
                    </div>
                </div>
                <div className="virtualized-table-container">
                    <TableVirtuoso 
                        className={"virtualized-table"}
                        data={this.state.scaleReportsFiltered}
                        fixedHeaderContent={() => (
                            <tr>
                                <th className='orders-reports-table-head-cell reports-cell small-action-cell'>
                                    <input
                                        type="checkbox"
                                        checked={checkAllChecked}
                                        onChange={this.handleAllCheckboxChange}
                                    />
                                </th>
                                <th className='orders-reports-table-head-cell reports-cell'>
                                    Data
                                </th>
                                <th className='orders-reports-table-head-cell reports-cell'>
                                    Nume furnizor
                                </th>
                                <th className='orders-reports-table-head-cell reports-cell'>
                                    Nume client
                                </th>
                                <th className='orders-reports-table-head-cell reports-cell'>
                                    Nume transportator
                                </th>
                                <th className='orders-reports-table-head-cell reports-cell'>
                                    Produs
                                </th>
                                <th className='orders-reports-table-head-cell reports-cell-small'>
                                    Status
                                </th>
                                <th className='orders-reports-table-head-cell reports-cell'>
                                    Greutate neta
                                </th>
                                <th className='orders-reports-table-head-cell reports-cell-small'>
                                    Actiuni
                                </th>
                            </tr>
                        )}
                        itemContent={(index, report: ScaleReport) => {
                            const reportDate = new Date(report.details.timestampIn);
                            const month = new Intl.DateTimeFormat('en', {month: 'short'}).format(reportDate);
                            const day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(reportDate);
                            const hour = new Intl.DateTimeFormat('en', {hour:'numeric', hour12:false}).format(reportDate);
                            const minute = new Intl.DateTimeFormat('en', {minute: 'numeric'}).format(reportDate);
                            const parsedMinute = parseInt(minute) <= 9 ? '0' + minute : minute;
                            const netWeight = (report.status === 'Complete' && report.details.weightOut) ? Math.abs(report.details.weightIn - report.details.weightOut) : 'N/A';
                            const isPrintable = report.details.attachDocumentType === "Aviz" ? (new Date(report.details.timestampIn) > featureDate ? true : false) : false;
                            
                            return (
                                <>
                                    <td id={index + ""} className='orders-reports-table-body-cell small-action-cell'>
                                        <input
                                            type="checkbox"
                                            checked={this.state.checkedReportsIds.indexOf(report._id) >= 0}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => this.handleCheckboxChange(report._id, event.target.checked)}
                                        />
                                    </td>
                                    <td className='orders-reports-table-body-cell reports-cell'>{`${day} ${month} ${hour}:${parsedMinute}`}</td>
                                    <td className='orders-reports-table-body-cell reports-cell eclipse-text' title={`${report.supplierName}`}>{report.supplierName}</td>
                                    <td className='orders-reports-table-body-cell reports-cell eclipse-text' title={`${report.clientName}`}>{report.clientName}</td>
                                    <td className='orders-reports-table-body-cell reports-cell eclipse-text' title={`${report.details.transporter}`}>{report.details.transporter}</td>
                                    <td className='orders-reports-table-body-cell reports-cell' title={`${report.productName}`}>{report.productName}</td>
                                    <td className={`orders-reports-table-body-cell reports-cell-small ${Utils.setColorByState(report.status)}`}>{translateStatus(report.status)}</td>
                                    <td className='orders-reports-table-body-cell reports-cell'>{netWeight}</td>
                                    <td className='orders-reports-table-body-cell reports-cell-small'>
                                        <div className="display-flex">
                                            <ETIcon image={seeMoreIcon} altText={'see-more'} tooltipText={'Vezi detalii'} onClick={() => this.showDetailsPanel(report._id)}/>
                                            {report.invoiceDetails && report.invoiceDetails.invoiced ? <ETIcon image={fIcon} altText='invoiced' tooltipText={'Facturat'}  /> : ''}
                                            {isPrintable ? <ETIcon image={generatePdf} altText='delivery-note' tooltipText={'Descarca aviz'} onClick={() => this.regenerateDeliveryNote(report)} /> : ''}
                                        </div>
                                    </td>
                                </>
                            )
                        }}
                    />
                </div>
                <br/>
                <div id="total-weight">
                    <p>Greutate neta totala: {this.state.totalWeight} kg ( {this.getWeightInTonnes()} t )</p>
                </div>
                <input 
                    id='download-csv-form-button'
                    className='et-button'
                    type='submit'
                    value='Export CSV'
                    onClick={this.exportCsvData}
                ></input>
                <input
                    id='create-invoice-form-button'
                    className='et-button'
                    type='submit'
                    value='Emite factura'
                    onClick={this.createInvoice}
                    disabled={this.state.checkedReportsIds.length > 0 ? false : true}
                ></input>
                <input
                    id='download-scale-report-button'
                    className='et-button'
                    type='submit'
                    value='Descarca avize'
                    onClick={this.downloadNotices}
                    disabled={this.state.checkedReportsIds.length > 0 ? false : true}
                ></input>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedReportsIds: (scaleReportsIds: Array<string>) => dispatch(setScaleReportsForInvoice(scaleReportsIds)),
        setActiveModule: (moduleName: string) => dispatch(setActiveModule(moduleName))
    }
}

export default connect(null, mapDispatchToProps)(ScaleReportsPage);