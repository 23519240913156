import { BaseDataProvider } from "../../../../../../lib/baseDataProvider/base.dataProvider";
import { RequestError } from "../../../../../../lib/baseDataProvider/types";
import { CancelInvoiceResponse, GetZipFromEFacturaResponse, UploadInvoiceToEFacturaResponse } from "../types/invoiceTableRow.types";

export class InvoiceTableRowDataProvider extends BaseDataProvider {
    public async uploadInvoiceToEFactura(invoiceId: string): Promise<UploadInvoiceToEFacturaResponse> {
        const requestBody = { invoiceId };
        const response = await this.launchPostRequest('/api/invoices/uploadInvoiceToEFactura', requestBody);

        if (!response) {
            return { success: false };
        }

        return response.data as UploadInvoiceToEFacturaResponse;
    }

    public async waitForInvoiceUploadToFinish(invoiceId: string): Promise<UploadInvoiceToEFacturaResponse> {
        const requestBody = { invoiceId };
        const response = await this.launchPostRequest('/api/invoices/waitForInvoiceUploadToFinish', requestBody);

        if (!response) {
            return { success: false };
        }

        return response.data as UploadInvoiceToEFacturaResponse;
    }

    public async downloadANAFInvoiceZip(downloadId: string): Promise<GetZipFromEFacturaResponse> {
        let response;

        response = await fetch(
            `api/invoices/downloadConfirmationZip?downloadId=${downloadId}`, 
            {
                method: 'GET',
                headers: 
                {
                    'Accept': 'application/zip',
                    'x-session-id': localStorage.getItem('sessionId')!
                }
            }
        );

        if(!response.ok) {
            throw new RequestError("", response.status);
        }

        const filename = response.headers.get('content-disposition')!.substring(response.headers.get('content-disposition')!.indexOf("filename=") + 9);

        return {
            success: true,
            data: await response.blob(),
            filename: filename
        } as GetZipFromEFacturaResponse;
    }

    public async cancelInvoice(invoiceId: string): Promise<CancelInvoiceResponse> {
        const requestBody = { invoiceId: invoiceId };
        const response = await this.launchPostRequest('/api/invoices/cancelInvoice', requestBody);

        if (!response) {
            return { success: false };
        }

        return { success: true };
    }
}