import axios from "axios";
import ServerRequest, { RequestConfig } from "../ServerRequest";
import { TokensData } from "../../../components/layout/anafOauthPage/types/types";

export class AnafOauthRequest extends ServerRequest {

    static getAnafOauthToken = async (): Promise<TokensData> => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        let result: TokensData = {
            eTransportAccessToken: '',
            shouldAutomaticallyRefreshETransportToken: false,
            eFacturaAccessToken: '',
            shouldAutomaticallyRefreshEFacturaToken: false
        }

        try {
            const requestResult = await axios.get('api/companies/getUsersCompany', requestHeaders);
            const companyData = requestResult.data;

            const eTransportAccessToken = companyData.spvAuthData && companyData.spvAuthData.eTransportAuth ? companyData.spvAuthData.eTransportAuth.accessToken : undefined;
            const shouldAutomaticallyRefreshETransportToken = companyData.spvAuthData && companyData.spvAuthData.eTransportAuth ? companyData.spvAuthData.eTransportAuth.automaticallyRefresh : undefined;
            const eFacturaAccessToken = companyData.spvAuthData && companyData.spvAuthData.eFacturaAuth ? companyData.spvAuthData.eFacturaAuth.accessToken : undefined;
            const shouldAutomaticallyRefreshEFacturaToken = companyData.spvAuthData && companyData.spvAuthData.eFacturaAuth ? companyData.spvAuthData.eFacturaAuth.automaticallyRefresh : undefined;

            result.eTransportAccessToken = eTransportAccessToken ?? '';
            result.shouldAutomaticallyRefreshETransportToken = !!shouldAutomaticallyRefreshETransportToken;
            result.eFacturaAccessToken = eFacturaAccessToken ?? '';
            result.shouldAutomaticallyRefreshEFacturaToken = !!shouldAutomaticallyRefreshEFacturaToken;
        } catch (err) {
            this.treatRequestErrors(err);
        }

        return result;
    }

    static async updateAutomaticallyRefreshSetting(requestBody: { automaticallyRefreshETransportToken?: boolean, eTransportTokenExpiryTimestamp?: number, automaticallyRefreshEFacturaToken?: boolean , eFacturaTokenExpiryTimestamp?: number}): Promise<boolean> {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);

        try {
            await axios.post('api/companies/updateCompanyData', requestBody, requestHeaders);
        } catch (err) {
            this.treatRequestErrors(err);
            return false;
        }

        return true;
    }
}