import { ApplicationActionTypes } from "../actions/types";
import { ApplicationAction, ApplicationState } from "../types";

const initialState: ApplicationState = {
    activeModule: '',
    scaleReportsForInvoice: [],
}

export default function application(state: ApplicationState = initialState, action: ApplicationAction) {
    const { type, payload } = action;

    switch (type) {
        case ApplicationActionTypes.SET_ACTIVE_MODULE:
            return {
                ...state,
                activeModule: payload.module
            };
        case ApplicationActionTypes.SET_SCALE_REPORTS_FOR_INVOICE:
            return {
                ...state,
                scaleReportsForInvoice: payload.selectedScaleReportsIds
            }
        default:
            return state;
    }


}