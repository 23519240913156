import { BaseDataProvider } from "../../../../../../lib/baseDataProvider/base.dataProvider";
import { PaymentData } from "../../../types/types";
import { InvoicePaymentState, PaymentSeriesData } from "../types/invoicePayment.types";

export default class InvoicePaymentProvider extends BaseDataProvider {
    
    public async getInvoicePayment(paymentId: string): Promise<PaymentData> {
        const requestResult = await this.launchGetRequest<PaymentData>(`/api/payments/getPayment/${paymentId}`);
        return requestResult.data;
    }
    
    public async createInvoicePayment(invoicePaymentData: InvoicePaymentState, invoiceId: string) {
        const response = await this.launchPostRequest('api/payments/createPayment', {
            paymentData: invoicePaymentData.paymentData,
            invoiceId: invoiceId
        });

        return response?.data.id;
    }

    public async deleteInvoicePayment(invoicePaymentData: InvoicePaymentState, invoiceId: string) {
        await this.launchPostRequest('api/payments/deletePayment', {
            paymentData: invoicePaymentData.paymentData,
            invoiceId: invoiceId
        });
    }

    public async getPaymentSeriesData(): Promise<PaymentSeriesData> {
        const requestResult = await this.launchGetRequest<PaymentSeriesData>('api/paymentSeries/getPaymentSeriesData');
        return requestResult.data;
    }
}