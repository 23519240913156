import ConveyorReportsPageDataProvider from "../dataProvider/conveyorReports.dataProvider";
import { ConveyorReport, TimeRangeFilter } from "../types/types";

export class ConveyorReportsController {
    private static dataProvider: ConveyorReportsPageDataProvider = new ConveyorReportsPageDataProvider();

    static async getReports(timeRangeFilter: TimeRangeFilter): Promise<Array<ConveyorReport>> {
        const allFilters: Array<TimeRangeFilter> = [timeRangeFilter];
        return await this.dataProvider.getConveyorReports(allFilters);
    }
}