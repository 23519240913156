import { Component, ReactElement } from "react";
import { connect } from "react-redux";

import 'react-datepicker/dist/react-datepicker.css';
import './invoicePage.styles.css';
import './innerComponents/invoicePayment/invoicePayment.styles.css'

import { Invoice, InvoiceCancellationStatus, InvoicePageState } from "./types/types";
import InvoiceForm from "./innerComponents/invoiceForm/invoiceForm.component";
import { InvoicePageController } from "./controller/invoicePage.controller";
import InvoiceTableRow from "./innerComponents/invoiceTableRow/invoiceTableRow.component";
import { RootState } from "../../../redux/types";
import EtTimeRangeFilter from "../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.component";
import { TimeRange } from "../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.types";
import { TimeRangeFilter } from "../scaleReportsPage/types/types";
import { InvoicePayment } from "./innerComponents/invoicePayment/invoicePayment.component";
import { dispatchRequestErrorEvent } from "../../../lib/events/requestErrorEvent";

const invoicePageController = new InvoicePageController();

interface InvoicePageProps{
    scaleReportsForInvoice?: Array<string>
}

class InvoicePage extends Component<InvoicePageProps, InvoicePageState> {
    private dialog:any;
    
    constructor(props: InvoicePageProps) {
        super(props);

        this.state = this.getEmptyState();

        // bind here event handlers
        this.getEmptyState = this.getEmptyState.bind(this);
        this.getInvoices = this.getInvoices.bind(this);
        this.addNewInvoice = this.addNewInvoice.bind(this);
        this.hideInvoiceSection = this.hideInvoiceSection.bind(this);
        this.reloadInvoices = this.reloadInvoices.bind(this);
        this.editInvoice = this.editInvoice.bind(this);
        this.timeRangeFilterChanged = this.timeRangeFilterChanged.bind(this);
        this.displayPaymentModal = this.displayPaymentModal.bind(this);
        this.hidePaymentModal = this.hidePaymentModal.bind(this);
        this.updateInvoicePayment = this.updateInvoicePayment.bind(this);
        this.updateInvoice = this.updateInvoice.bind(this);
    }

    private getEmptyState(): InvoicePageState {
        return {
            invoices: [],
            selectedInvoice: null,
            lastInvoice: null,
            showInvoiceForm: false,
            timeRange: {
                startInterval: 0,
                endInterval: 0
            },
            invoicePayment: this.getEmptyInvoice()
        };
    }

    private getEmptyInvoice(): Invoice {
        return {
            _id: "",
            invoiceHeaderInfo: {
                supplierData: {
                    name: "",
                    vatCode: "",
                    vatPayer: false,
                    regComCode: "",
                    iban: "",
                    bankName: "",
                    address: "",
                    detailedAddress : {
                        countyCode: "",
                        postalCode: "",
                        cityName: "",
                        streetName: ""
                    }
                },
                clientData: {
                    _id: "",
                    name: "",
                    vatCode: "",
                    vatPayer: false,
                    regComCode: "",
                    iban: "",
                    address: "",
                    detailedAddress : {
                        countyCode: "",
                        postalCode: "",
                        cityName: "",
                        streetName: ""
                    }
                },
                vatOnPayment: false,
                date: "",
                dueDate: "",
                number: "",
                series: "",
            },
            details: {
                netAmount: 0,
                totalAmount: 0,
                vatAmount: 0
            },
            productList: [],
            deliveryNotes: [],
            eFacturaData: {
                status: 0,
                downloadId: ''
            },
            paymentId: "",
            invoiceCancellationInfo: {
                status: InvoiceCancellationStatus.NORMAL,
                pairInvoiceId: ""
            }
        }
    }

    async componentDidMount() {
        if (this.props.scaleReportsForInvoice && this.props.scaleReportsForInvoice.length) {
            // open invoice form and auto-fill
            this.addNewInvoice();
        }

        // get dialog element and bind the close button event
        this.dialog = document.getElementById("payment-dialog");
        const closeButton = document.getElementById("close-payment-dialog");
        closeButton!.addEventListener("click", () => {
            this.dialog!.close();
        });
    }

    private async getInvoices() {
        let invoicesArray: Array<Invoice> = [];
        try {

            const timeRangeFilter: TimeRangeFilter = {
                name: 'timeRange',
                values: this.state.timeRange
            }
    
            const invoicesResponse = await invoicePageController.getInvoices(timeRangeFilter);

            if(invoicesResponse.invoices) {
                invoicesArray = invoicesResponse.invoices;
            }
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }

        invoicesArray.reverse();

        this.setState({
            invoices: invoicesArray,
            lastInvoice: invoicesArray.reverse()[0]
        });
    }
        
    private async displayPaymentModal(invoice: Invoice) {
        this.dialog!.showModal();

        this.setState({
            invoicePayment: invoice
        });
    }

    private async hidePaymentModal() {
        this.dialog!.close();
    }
   
    private addNewInvoice() {
        this.setState({
            selectedInvoice: null,
            showInvoiceForm: true
        });
    }

    private hideInvoiceSection() {
        this.setState({
            selectedInvoice: null,
            showInvoiceForm: false
        });
    }

    private async reloadInvoices() {
        this.setState({
            selectedInvoice: null,
            invoices: [],
            lastInvoice: null,
            invoicePayment: this.getEmptyInvoice()
        }, async () => {
            await this.getInvoices()
        });
    }

    private editInvoice(invoice: Invoice) {
        this.setState({
            showInvoiceForm: false,
            selectedInvoice: null,
        }, async () => {
            this.setState({
                showInvoiceForm: true,
                selectedInvoice: invoice,
            });
        });
    }

    private updateInvoice(updatedInvoice: Invoice) {
        const stateInvoices = this.state.invoices;
        const index = stateInvoices.findIndex(invoice => invoice._id === updatedInvoice._id);

        if(index === -1){
            return;
        }

        stateInvoices[index] = updatedInvoice;

        this.setState({
            showInvoiceForm: false,
            invoices: stateInvoices,
        });
    }

    private async timeRangeFilterChanged (selectedTimeRange: TimeRange) {
        // convert to GMT+3
        selectedTimeRange.startInterval += 3600 * 3 * 1000;
        // endInterval does not need this change!
        this.setState(
            {
                timeRange: selectedTimeRange
            },
            () => this.getInvoices()
        );
    }

    private updateInvoicePayment(paymentId: string) {
        try {
            const index = this.state.invoices.indexOf(this.state.invoicePayment!);
            const invoicesCopy = this.state.invoices;
            invoicesCopy[index].paymentId = paymentId;

            const invoicePaymentCopy = this.state.invoicePayment;
            
            this.setState({
                invoices: [],
                invoicePayment: this.getEmptyInvoice()
            }, () => this.reRenderInvoices(invoicesCopy, invoicePaymentCopy!));
        } catch(error: any) {
            console.log(error);
        }
    }

    private reRenderInvoices(invoices: Array<Invoice>, invoicePayment: Invoice): void {
        this.setState({
            invoices: invoices,
            invoicePayment: invoicePayment
        });
    }

    render(): ReactElement {
        return (
            <div className='etportal-page-container'>
                <h1 className='module-title'>Facturare</h1>
                <div>
                    {!this.state.showInvoiceForm ? 
                        <div>
                            <input
                                id='show-invoice-form-button'
                                className='et-button'
                                type='submit'
                                value='Adauga factura'
                                onClick={this.addNewInvoice}
                            ></input>
                            <EtTimeRangeFilter onChange={this.timeRangeFilterChanged} />
                        </div>
                        :
                        <></>
                    }
                </div>

                <div>
                    {this.state.showInvoiceForm ? <InvoiceForm handleCancelInvoice={this.hideInvoiceSection} handleInvoiceCreated={this.reloadInvoices} handleUpdateInvoice={this.reloadInvoices} invoiceToBeUpdated={this.state.selectedInvoice}></InvoiceForm> : <></>}
                </div>

                <dialog id="payment-dialog">
                    <div id="payment-dialog-wrapper">
                        <button autoFocus id="close-payment-dialog"></button>
                        {<InvoicePayment invoice={this.state.invoicePayment} hidePaymentModal={this.hidePaymentModal} updateInvoicePayment={this.updateInvoicePayment}></InvoicePayment>}
                    </div>
                </dialog>

                <div id='table-container'>
                    <table id='invoice-table' className='header-fixed'>
                        <thead id='invoice-table-head'>
                            <tr className='invoice-table-head-row'>
                                <th className='orders-reports-table-head-cell invoice-cell-small'></th>
                                <th className='orders-reports-table-head-cell invoice-cell-small'>
                                    Numar
                                </th>
                                <th className='orders-reports-table-head-cell invoice-cell'>
                                    Data
                                </th>
                                <th className='orders-reports-table-head-cell invoice-cell'>
                                    Client Name
                                </th>
                                <th className='orders-reports-table-head-cell invoice-cell'>
                                    Scadenta
                                </th>
                                <th className='orders-reports-table-head-cell invoice-cell'>
                                    Valoare
                                </th>
                                <th className='orders-reports-table-head-cell invoice-cell'>
                                    TVA
                                </th>
                                <th className='orders-reports-table-head-cell invoice-cell'>
                                    Total
                                </th>
                                <th className='orders-reports-table-head-cell invoice-cell'>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody id='invoice-table-body'>
                            {this.state.invoices.map((invoice) => (<InvoiceTableRow key={invoice._id} invoice={invoice} editInvoice={this.editInvoice} updateInvoice={this.updateInvoice} invoiceInUpdateForm={this.state.selectedInvoice} displayPaymentModal={this.displayPaymentModal} reloadInvoices={this.reloadInvoices}/>)).reverse()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (rootState: RootState) => {
    return {
        scaleReportsForInvoice: rootState.application.scaleReportsForInvoice
    }
}

export default connect(mapStateToProps)(InvoicePage);