import PdfMake from "pdfmake/build/pdfmake";
import PdfFonts from "pdfmake/build/vfs_fonts";
import { Content } from "pdfmake/interfaces";
import { ScaleReport } from "../../types";
import { BasePdfBuilder } from "../basePdfBuilder";

export class DeliveryNotePdfBuilder extends BasePdfBuilder<ScaleReport> {

    // left, top, right, bottom
    private negativeMargins: [number, number, number, number] = [-5, 0, -5, -3];
    private zeroMargins: [number, number, number, number] = [0, 0, 0, 0];
    private pageMargins: [number, number, number, number] = [10, 10, 10, 10];
    private productsMargins: [number, number, number, number] = [0, 0, 0, -1];

    public downloadDocument(scaleReport: ScaleReport): void {
        const doc = this.generateDocument(scaleReport);

        var pdfName = `Aviz_${scaleReport.details.attachDocumentSeries?.toUpperCase()}${scaleReport.details.attachDocumentNumber}_${scaleReport.clientName}_${this.parseTimestampToDate(scaleReport.details.timestampOut!)}`;
        doc.download(pdfName + '.pdf');
    }

    public generateDocument(scaleReport: ScaleReport) {
        PdfMake.vfs = PdfFonts.pdfMake.vfs;

        var docDefinition = {
            content: [
                this.insideBorder(
                    {
                        stack: [
                            this.generateDeliveryNoteContent(scaleReport)
                        ],
                        margin: this.negativeMargins
                    }
                )
            ],
            pageMargins: this.pageMargins,
            styles: {
                body: {
                    fontSize: 12
                },
                big_text: {
                    fontSize: 12
                },
                big_text_bold: {
                    fontSize: 12,
                    bold: true
                },
                medium_text: {
                    fontSize: 10
                },
                medium_text_bold: {
                    fontSize: 10,
                    bold: true
                },
                small_text: {
                    fontSize: 8
                },
                small_text_bold: {
                    fontSize: 8,
                    bold: true
                },
                small_medium_text: {
                    fontSize: 9
                },
                table_text: {
                    fontSize: 9
                },
                table_text_bold: {
                    fontSize: 9,
                    bold: true
                }
            }
        };

        return PdfMake.createPdf(docDefinition);
    }
    
    private generateDeliveryNoteContent(scaleReport: ScaleReport): Content {
        return {
            stack: [
                {
                    columns: [
                        this.generateDeliveryNoteSupplierDetails(scaleReport),
                        this.generateDeliveryNoteClientDetails(scaleReport)
                    ]
                },
                this.generateDeliveryNoteTitleAndDetails(scaleReport),
                this.generateDeiveryNoteProductList(scaleReport),
                this.generateDeliveryNoteLowerDetails(scaleReport)
            ],
            margin: this.zeroMargins
        };
    }

    private generateDeliveryNoteSupplierDetails(scaleReport: ScaleReport): Content {

        let tableEntries = [];

        tableEntries.push([{text: "Furnizor: "}, {text: scaleReport.supplierName}]);
        tableEntries.push([{text: "C.I.F.: "}, {text: scaleReport.supplierData?.vatNo}]);
        tableEntries.push([{text: "Nr. Reg. Com.: "}, {text: scaleReport.supplierData?.tradeRegNo}]);
        tableEntries.push([{text: "Adresa: "}, {text: scaleReport.supplierData?.address}]);
        tableEntries.push([{text: "IBAN: "}, {text: scaleReport.supplierData?.bankAccount}]);
        tableEntries.push([{text: "Banca: "}, {text: scaleReport.supplierData?.bankName}]);
        tableEntries.push([{text: "Punct de lucru: "}, {text: scaleReport.workPoint}]);

        let table = {
            body: tableEntries,
            widths: [60, "*"]
        };

        return {
            table: table,
            style: 'small_medium_text',
            margin: [5, 5, 0, 0],
            layout: {
                hLineColor: function () {
                    return 'white';
                },
                vLineColor: function () {
                    return 'white';
                }
            }
        };
    }

    private generateDeliveryNoteClientDetails(scaleReport: ScaleReport): Content {

        let tableEntries = [];

        tableEntries.push([{text: "Client: "}, {text: scaleReport.clientName}]);
        tableEntries.push([{text: "C.I.F.: "}, {text: scaleReport.clientVatCode ? scaleReport.clientVatCode : '-'}]);
        tableEntries.push([{text: "Nr. Reg. Com.: "}, {text: scaleReport.clientData?.tradeRegNo}]);
        tableEntries.push([{text: "Adresa: "}, {text: scaleReport.clientData?.address}]);
        tableEntries.push([{text: "IBAN: "}, {text: scaleReport.clientData?.bankAccount}]);
        tableEntries.push([{text: "Banca: "}, {text: scaleReport.clientData?.bankName}]);

        let table = {
            body: tableEntries,
            widths: [60, "*"]
        };

        return {
            table: table,
            style: 'small_medium_text',
            margin: [5, 5, 0, 0],
            layout: {
                hLineColor: function () {
                    return 'white';
                },
                vLineColor: function () {
                    return 'white';
                }
            }
        };
    }

    private generateDeliveryNoteTitleAndDetails(scaleReport: ScaleReport): Content {
        return {
            columns: [
                "",
                [
                    {
                        text: "AVIZ DE INSOTIRE A MARFII",
                        marginBottom: 6,
                        bold: true,
                    },
                    this.insideBorder(
                        {
                            stack: [
                                {
                                    columns: [
                                        "Serie: " + scaleReport.details.attachDocumentSeries,
                                        "Nr: " + scaleReport.details.attachDocumentNumber
                                    ]
                                },
                                "Data: " + this.parseTimestampToDate(scaleReport.details.timestampIn)
                            ],
                            margin: [50,0,50,0],
                            style: "small_medium_text",
                            alignment: 'left'
                        }
                    ),
                    {
                        text: 'Cod UIT: ' + (scaleReport.details.uitCode ? scaleReport.details.uitCode : "-"),
                        marginTop: 6,
                        style: "small_medium_text"
                    }
                ],
                ""
            ],
            margin: [0, 10, 0, 10],
            alignment: 'center'
        };
    }

    private generateDeiveryNoteProductList(scaleReport: ScaleReport): Content {

        let tableEntries = [];

        tableEntries.push([
            {
                text: 'Nr. crt.',
                alignment: 'center'
            },
            {
                stack: ['Specificatia', '(produse, ambalaje, etc)'],
                marginLeft: 5
            },
            {
                text: 'U.M.',
                alignment: 'center'
            },
            {
                stack: ["Cantitatea", "livrata"],
                marginLeft: 5
            },
            {
                stack: ["Pret unitar", "(fara TVA)", "- lei -"],
                alignment: 'center'
            },
            {
                stack: ["Valoare", "- lei -"],
                alignment: 'center'
            }
        ]);

        let total = 0;

        for(let productID = 0; productID < scaleReport.products!.length; productID++) {
            const product = scaleReport.products![productID];

            total = this.addFloatsTwoDecimals(total, (product.quantity.value * product.price));

            let tableItem = [
                {
                    text: productID + 1,
                    margin: [5,2,0,0]
                },
                {
                    stack: [
                        {text: product.name, margin: [5,2,0,0]},
                        {text: "Cod tarifar:         " + product.tariffCode, margin: [5,2,0,0]},
                    ],
                    marginBottom: 3
                },
                {
                    text: this.parseUnitMeasure(product.quantity.unitMeasureCode),
                    margin: [5,2,0,0]
                },
                {
                    text: product.quantity.value,
                    margin: [5,2,0,0]
                },
                {
                    text: product.price,
                    margin: [5,2,0,0]
                },
                {
                    text: this.addFloatsTwoDecimals(0, product.quantity.value * product.price),
                    margin: [5,2,0,0]
                },
            ];
            tableEntries.push(tableItem);
        }

        tableEntries.push([
            {},{
                stack: [
                {text: "Loc incarcare:    " + scaleReport.details.loadingPlace, margin: [5,2,0,0]},
                {text: "Loc descarcare: " + scaleReport.details.unloadingPlace, margin: [5,2,0,0]},
                {text: "Transportator:    " + scaleReport.details.transporter, margin: [5,2,0,0]},
            ],
            marginBottom: 3
        },{},{},{},{}
        ]);

        tableEntries.push([
            {},{},{},{},{text: "Total:", margin: [5, 2, 0, 0]},{text: total, margin: [5, 2, 0, 0]}
        ]);

        let table = {
            headerRows: 1,
            widths: [(550 * 8) / 100, (550 * 28.1) / 100, (550 * 8) / 100, (550 * 12) / 100, (550 * 12) / 100, (550 * 27) / 100],
            body: tableEntries
        };
    
        return {
            table: table,
            style: 'small_medium_text',
            margin: this.productsMargins
        };
    }

    private generateDeliveryNoteLowerDetails(scaleReport: ScaleReport): Content {

        let tableEntries = [];

        let transport = scaleReport.details.licencePlateTruck;

        if(scaleReport.details.licencePlateTrail) {
            transport += "; " + scaleReport.details.licencePlateTrail;
        }

        tableEntries.push([
            {
                stack: [
                    "Semnatura si",
                    "stampila furnizorului"
                ],
                margin: [5, 5, 0, 0]
            },
            {
                stack: [
                    {text:"Datele privind expeditia",margin:[0,5,0,0]},
                    {text:"Numele delegatului: " + scaleReport.details.driverName,margin:[0,5,0,0]},
                    {text:"Carte identitate:        " + scaleReport.details.driverIDNumber,margin:[0,5,0,0]},
                    {text:"Mijloc de transport:  " + transport,margin:[0,5,0,0]},
                    {text:"Expedierea s-a efectuat in prezenta noastra la",margin:[0,5,0,0]},
                    {text:" data de: " + this.parseTimestampToDate(scaleReport.details.timestampOut!),margin:[0,5,0,0]},
                ],
                marginLeft: 5
            },
            {
                text: 'Semnatura client',
                margin: [5, 5, 0, 0]
            }
        ]);

        let table = {
            headerRows: 1,
            widths: [(550 * 20) / 100, '*', (550 * 27) / 100],
            body: tableEntries
        };
    
        return {
            table: table,
            margin: this.zeroMargins,
            style: 'small_medium_text'
        };
    }
}