import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { grey } from "@mui/material/colors";
import { ETDoughnutChart } from "../../../ETComponents/ETDoughnutChart/etdoughnutchart.component";
import { addComas } from "../helper/scaleReportsDashboard.helper";

export interface DoughnutWidgetProps {
    valueLabel: string;
    doughnutValue: number;
    doughnutTotal: number;
    fillLabel: string;
    unfilledLabel: string;
    doughnutChartColour: string;
}

export interface DoughnutWidgetState {
}

class DoughnutWidget extends Component<DoughnutWidgetProps, DoughnutWidgetState> {

    render() {
        return (
            <Card sx={{borderRadius: 2, flexGrow: 1}} >
                <Stack direction="row">
                    <Box height={150} width={150} paddingLeft={1.5} alignContent={'center'}>
                        <ETDoughnutChart 
                            value={this.props.doughnutValue} 
                            fillLabel={this.props.fillLabel} 
                            max={this.props.doughnutTotal} 
                            unfilledLabel={this.props.unfilledLabel}
                            colour={this.props.doughnutChartColour} 
                            size={105}                                            
                        />
                    </Box>
                    <Box alignContent={'center'}>
                        <Typography sx={{ marginTop: 0}} variant="h4" component="div">
                            {addComas(this.props.doughnutValue)}/{addComas(this.props.doughnutTotal)}
                        </Typography>
                        <Typography sx={{ color: grey[700], marginTop: 0}} variant="body2" component="div">
                            {this.props.valueLabel}
                        </Typography>
                    </Box>
                </Stack>
            </Card>
        )
    }
}

export default DoughnutWidget;