import { PopupButtonTypes } from "../../components/ETComponents/ETPopup/constants";
import { PopupAction } from "../types";
import { PopupActionTypes } from "./types";

export const displayPopup = (title: string, text: string, buttonsType: PopupButtonTypes, shouldLogoutUser: boolean, handleYesButton?: () => void, handleNoButton?: () => void) => (dispatch: any) => {
    const displayPopupAction: PopupAction = {
        type: PopupActionTypes.DISPLAY_POPUP,
        payload: { title, text, buttonsType, shouldLogoutUser, handleYesButton, handleNoButton, visible: true },
    }

    dispatch(displayPopupAction);
}

export const closePopup = () => (dispatch: any) => {
    const closePopupAction: PopupAction = {
        type: PopupActionTypes.CLOSE_POPUP,
        payload: {
            title: '',
            text: '',
            buttonsType: PopupButtonTypes.OK,
            shouldLogoutUser: false,
            visible: false
        }
    }

    dispatch(closePopupAction);
}