export enum AlertsActionTypes {
    SET_ALERT = 'SET_ALERT',
    REMOVE_ALERT = 'REMOVE_ALERT'
};

export enum LoginActionTypes {
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAIL = 'LOGIN_FAIL',
    LOGOUT = 'LOGOUT',
    MODIFY_USER_NAME = 'MODIFY_USER_NAME',
    MODIFY_USER_EMAIL = 'MODIFY_USER_EMAIL',
    MODIFY_USER_PHONE = 'MODIFY_USER_PHONE',
    MODIFY_USER_JOB_TITLE = 'MODIFY_USER_JOB_TITLE'
}

export enum ApplicationActionTypes {
    SET_ACTIVE_MODULE = 'SET_ACTIVE_MODULE',
    SET_SCALE_REPORTS_FOR_INVOICE = 'SET_SCALE_REPORTS_FOR_INVOICE'
}

export enum PopupActionTypes {
    DISPLAY_POPUP = 'DISPLAY_POPUP',
    CLOSE_POPUP = 'CLOSE_POPUP'
}