import './overviewElement.styles.css';

interface OverviewElementProps {
    value: string;
    title: string;
}

const OverviewElement = (props: OverviewElementProps) => {
    return (
        <div className='overview-el-container'>
            <h2 className='overview-content'>{props.value}</h2>
            <p className='overview-title'>{props.title}</p>
        </div>
    )
}

export default OverviewElement;