import axios from 'axios';
import { FilterForBackend, ScaleReportsView,TimeRangeFilter } from '../../components/layout/scaleReportsPage/types/types';
import { Company, ScaleReport } from '../types';


export interface RequestConfig {
    headers: {
        [key: string]: string | null;
    },
    params?: any;
}

interface GenerateTokenRequestBody {
    companyName: string;
}

interface CreateUserRequestBody {
    name: string;
    email: string;
    password: string;
    userRole: string;
    companyId: string;
    companyName: string;
}

interface UpdateUserRequestBody {
    name?: string;
    email?: string;
    password?: string;
    phone?: string;
    jobTitle?: string;
}

interface LogoutUserRequestBody {
    sessionId: string;
}

export class RequestError extends Error {

    public statusCode: number;

    constructor(msg: string, statusCode: number) {
        super(msg);
        Object.setPrototypeOf(this, RequestError.prototype);
        this.statusCode = statusCode;
    }
}

class ServerRequest {

    static generateToken = async (body: GenerateTokenRequestBody) => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        const requestBody = JSON.stringify(body);

        try {
           await axios.post('/api/apiTokens', requestBody, requestHeaders);
        } catch (err: any | import('axios').AxiosError) { 
            this.treatRequestErrors(err);
        }
    }

    static getExistingCompanies = async (): Promise<Array<Company>> => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);

        let result: Array<Company> = [];
        
        try {
            const requestResult = await axios.get('/api/companies/all', requestHeaders);
            result = requestResult.data;
        } catch (err: any | import('axios').AxiosError) {
            this.treatRequestErrors(err);
        }

        return result;
    }

    static createUser = async (body: CreateUserRequestBody) => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        const requestBody = JSON.stringify(body);

        try {
            await axios.post('api/users', requestBody, requestHeaders);
        } catch(err: any | import('axios').AxiosError) {
            this.treatRequestErrors(err);
        }
    }

    static logout = async (body: LogoutUserRequestBody) => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        const requestBody =  JSON.stringify(body);

        try {
            await axios.post('api/users/logout', requestBody, requestHeaders);
        } catch (err: any | import('axios').AxiosError) {
            this.treatRequestErrors(err);
        }
    }

    static getScaleReports = async (appliedFilters: Array<FilterForBackend | TimeRangeFilter | undefined>) => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);

        requestHeaders.params = {};

        const filtersRequestBody = {
            filters: JSON.stringify(appliedFilters)
        }

        requestHeaders.params = filtersRequestBody;

        let result: Array<ScaleReport> = [];

        try {
            const requestResult = await axios.get('api/scaleReports', requestHeaders);
            result = requestResult.data.scaleReports;
        } catch (err: any | import('axios').AxiosError) {
            this.treatRequestErrors(err);
        }

        return result;
    }

    static updateUser = async (body: UpdateUserRequestBody, userId: string) => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        const requestBody = JSON.stringify(body);

        try {
            await axios.post(`api/users/modify/${userId}`, requestBody, requestHeaders);
        } catch(err: any | import('axios').AxiosError) {
            this.treatRequestErrors(err);
        }
    }

    static loadView = async (): Promise<ScaleReportsView> => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        let result = {
            filters: [] as Array<FilterForBackend>,
            viewId: ''
        };

        try {
            const requestResult = await axios.get(`api/scaleReportsViews`, requestHeaders);
            result.viewId = requestResult.data._id;
            result.filters = requestResult.data.filters as Array<FilterForBackend>;
        } catch (err: any | import('axios').AxiosError) {
            this.treatRequestErrors(err);
        }

        return result;
    }

    static updateView = async (viewId: string, filters: Array<FilterForBackend>): Promise<void> => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        const requestBody = JSON.stringify(filters);
        
        try {
            await axios.post(`api/scaleReportsViews/${viewId}`, requestBody, requestHeaders);
        } catch (err: any | import('axios').AxiosError) {
            this.treatRequestErrors(err);
        }
    }

    protected static getRequestHeaders = (requiresAuth: boolean): RequestConfig => {
        const config: RequestConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (requiresAuth) {
            config.headers['x-session-id'] = localStorage.getItem('sessionId');
        }

        return config;
    }

    protected static treatRequestErrors = (err: any | import('axios').AxiosError) => {
        if (axios.isAxiosError(err) && err.response) {
            const errors = err.response.data.errors;

            if (errors) {
                errors.forEach((error: any) => {
                    console.log(error);
                });
            }

            throw new RequestError(errors[0].msg, err.response.request.status);
        } else {
            console.log(err);
            throw new RequestError('Internal Server Error', 500);
        }
    }
}

export default ServerRequest;