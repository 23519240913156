export interface TokenData {
    companyName: string;
    companyId: string;
    apiToken: string;
    tokenStatus: string;
}

export interface GeneralCompanyData {
	socialAddress?: string;
	regComCode?: string;
	iban?: string;
    bankName?: string;
    detailedAddress: CompanyDetailedAddress;
    vatOnPayment: boolean;
    vatPayer: boolean;
}

export interface CompanyDetailedAddress {
    countyCode: string,
    postalCode: string,
    cityName: string,
    streetName: string
}

export interface Company {
    _id: string;
    logo: string;
    logoDataType: string;
    companyName: string;
    companyCui: string;
    features: Array<number>;
    generalCompanyData?: GeneralCompanyData;
    evertechToken?: string;
    evertechTokenActive?: boolean;
    anafAccessToken?: string;
}

export interface ScaleReportDetails {
    weightIn: number;
    weightOut?: number;
    timestampIn: number;
    timestampOut?: number;
    licencePlateTruck: string;
    licencePlateTrail: string;
    driverName: string;
    driverIDNumber?: string;
    attachDocumentType?: string;
    attachDocumentNumber?: number;
    attachDocumentSeries?: string;
    ticketNumber: number;
    operatorName: string;
    uitCode?: string;
    transporter?: string;
    loadingPlace?: string;
    unloadingPlace?: string;
    operationScope?: string;
}

export interface ScaleReportInvoiceDetails {
    invoiced?: boolean;
    invoiceId?: string;
    invoiceNo?: string;
    invoiceSeries?: string;
}

export interface ScaleReport {
    _id: string;
    companyId: string;
    endpointId: string;
    supplierName: string;
    supplierData? : CompanyData;
    clientName: string;
    clientData? : CompanyData;
    clientVatCode?: string;
    productName: string;
    status: string;
    details: ScaleReportDetails;
    invoiceDetails?: ScaleReportInvoiceDetails;
    products?: Array<Product>;
    workPoint?: string;
}

export interface CompanyData {
    name: string;
    vatNo: string;
    tradeRegNo: string;
    address: string;
    bankAccount: string;
    bankName: string;
}

export interface Product {
    name: string;
    code: string;
    price: number;
    eTransportPrice: number;
    type: string;
    tariffCode: number;
    secondPriceUsed: boolean;
    weightInKg: number;
    quantity: Quantity;
    defaultUnitMeasure: string;
    vatPercentage: number;
}

// to be kept in sync with back-end enum
export enum UnitMeasureCodes {
    KG = 'KGM',
    TNE = 'TNE',
    METRIC_CUBE = 'MTQ',
    BUC = 'H87',
    KILOMETER = 'KMT'
}

export interface Quantity {
    value: number;
    unitMeasureCode: UnitMeasureCodes;
}

interface PurchaseOrderClient {
    name: string;
    address: string;
    vatNumber: string;
    regComNumber: string;
    bankName: string;
    bankAccount: string;
}

interface PurchaseOrderDetails {
    grossQuantity?: number;
    netQuantity?: number;
    timestampIn?: number;
    timestampOut?: number;
    licencePlateTruck?: string;
    licencePlateTrail?: string;
    driverName?: string;
    driverIDNumber?: string;
    seal?: string;
    attachDocumentNumber?: number;
    destination?: string;
    incoterm?: string;
    loadingDate?: string;
}

export interface PurchaseOrder {
    _id: string;
    companyId: string;
    orderNumber: string;
    supplierName: string;
    client: PurchaseOrderClient;
    productName: string;
    orderedQuantity: number;
    lot: string;
    status: string;
    details?: PurchaseOrderDetails;
    insertedAt: number;
}

export interface EVAnafRecordResponse {
    anafCompanyRecord: ANAFCompanyRecord;
    errMessage: string;
}

export interface ANAFCompanyRecord {
    date_generale: {
        adresa: string;
        cui: number;
        denumire: string;
        iban: string;
        nrRegCom: string;
    },
    adresa_sediu_social: {
        scod_JudetAuto: string;
        scod_Judet: string;
        scod_Localitate: string;
        scod_Postal: string;
        sdenumire_Localitate: string;
        sdenumire_Strada: string;
    },
    inregistrare_scop_Tva: {
        scpTVA: boolean;
    }
}
export interface ClientDetailedAddress {
    countyCode: string;
    postalCode: string;
    cityName: string;
    streetName: string;
}