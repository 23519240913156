import { ClientData, InvoiceFormState, InvoiceSeriesData, Product } from "../types/invoiceForm.types";
import InvoiceFormDataProvider from "../dataProvider/invoiceForm.dataProvider";
import { Company, ScaleReport } from "../../../../../../lib/types";

export default class InvoiceFormController {
    private dataProvider: InvoiceFormDataProvider = new InvoiceFormDataProvider();

    public async createInvoice(invoiceData: InvoiceFormState, preselectedScaleReportsIds: Array<string>) {
        // we should limit the digit number on products price to 2, so we won't get eFactura errors
        invoiceData.productList = invoiceData.productList.map((product) => {
            return {
                ...product,
                unitPrice: parseFloat(product.unitPrice).toFixed(2),
                quantity: parseFloat(product.quantity).toFixed(2),
                netAmount: parseFloat(product.netAmount.toFixed(2))
            }
        });
        
        await this.dataProvider.createInvoice(invoiceData, preselectedScaleReportsIds);
    }

    public async updateInvoice(invoiceData: InvoiceFormState, invoiceId: string) {
        await this.dataProvider.updateInvoice(invoiceData, invoiceId);
    }

    public computeTotals(productList: Array<Product>): { totalAmount: number, netAmount: number, vatAmount: number } {
        let totalAmount = 0;
        let netAmount  = 0;

        for (const product of productList) {
            totalAmount += product.totalAmount;
            netAmount +=  product.netAmount;
        }

        let vatAmount = totalAmount - netAmount;

        totalAmount = parseFloat(totalAmount.toFixed(2));
        netAmount = parseFloat(netAmount.toFixed(2));
        vatAmount = parseFloat(vatAmount.toFixed(2));

        return { totalAmount, netAmount, vatAmount }
    }

    public async getAvailableClients(): Promise<Array<ClientData>> {
        const clients = await this.dataProvider.getAvailableClients();

        return clients;
    }

    public async getInvoiceSeriesData(): Promise<InvoiceSeriesData> {
        const invoiceSeriesData = await this.dataProvider.getInvoiceSeriesData();

        return invoiceSeriesData;
    }

    public async getScaleReports(scaleReportsIds: Array<string>): Promise<Array<ScaleReport>> {
        const scaleReports = await this.dataProvider.getScaleReports(scaleReportsIds);

        return scaleReports;
    }

    public async getCompanyData(): Promise<Company> {
        return await this.dataProvider.getCompanyData();
    }
}