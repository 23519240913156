import { useEffect } from 'react';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import './wasteStockPage.styles.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from 'chart.js';
import OverviewElement from './innerComponents/overviewElement/overviewElement.component';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
);

const WasteStockPage = () => {
    useEffect(() => {
        
    }, [])

    const buffer1Color = 'rgba(54, 162, 235, 0.5)';
    const buffer2Color = 'rgba(154, 162, 135, 0.5)';
    const buffer3Color = 'rgba(123, 22, 205, 0.5)';
    const buffer4Color = 'rgba(150, 52, 105, 0.5)';

    const buffer1ColorBold = 'rgba(54, 162, 235)';
    const buffer2ColorBold = 'rgba(154, 162, 135)';
    const buffer3ColorBold = 'rgba(123, 22, 205)';
    const buffer4ColorBold = 'rgba(150, 52, 105)';

    const buffer1ColorLight = 'rgba(54, 162, 235, 0.2)';
    const buffer2ColorLight = 'rgba(154, 72, 135, 0.2)';
    const buffer3ColorLight = 'rgba(123, 22, 205, 0.2)';
    const buffer4ColorLight = 'rgba(150, 52, 105, 0.2)';

    const getSortingBufferChartsConfiguration = () => {

        const sortingBufferOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom' as const,
                },
                title: {
                    display: false,
                    text: 'Sorting Buffer',
                },
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            elements: {
                line: {
                    borderWidth: 1
                },
                point: {
                    radius: 0
                }
            }
        };
          
        const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
          
        const sortingBufferData = {
            labels,
            datasets: [
                {
                    label: 'Buffer 1',
                    data: [100, 500, 600, 400, 700, 900, 300],
                    borderColor: buffer1ColorBold,
                    backgroundColor: buffer1ColorLight,
                    tension: 0.2,
                    pointBackgroundColor: buffer1ColorBold,
                    fill: true
                },
                {
                    label: 'Buffer 2',
                    data: [200, 400, 300, 700, 500, 600, 700],
                    borderColor: buffer2ColorBold,
                    backgroundColor: buffer2ColorLight,
                    tension: 0.2,
                    pointBackgroundColor: buffer2ColorBold,
                    fill: true
                },
                {
                    label: 'Buffer 3',
                    data: [250, 550, 440, 660, 880, 700, 230],
                    borderColor: buffer3ColorBold,
                    backgroundColor: buffer3ColorLight,
                    tension: 0.2,
                    pointBackgroundColor: buffer3ColorBold,
                    fill: true
                },
                {
                    label: 'Buffer 4',
                    data: [530, 220, 880, 650, 990, 770, 530],
                    borderColor: buffer4ColorBold,
                    backgroundColor: buffer4ColorLight,
                    tension: 0.2,
                    pointBackgroundColor: buffer4ColorBold,
                    fill: true
                }
            ],
        };

        return { sortingBufferOptions, sortingBufferData };
    }

    const getSortingTotalsChartsConfiguration = () => {

        const sortingTotalsOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom' as const,
                },
                title: {
                    display: false,
                    text: 'Sorting Buffer',
                },
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            elements: {
                line: {
                    borderWidth: 1
                }
            }
        };
          
        const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
          
        const sortingTotalsData = {
            labels,
            datasets: [
                {
                    label: 'Totals',
                    data: [1080, 1670, 2220, 2410, 3070, 2970, 1760],
                    borderColor: buffer1ColorBold,
                    backgroundColor: buffer1ColorLight,
                    tension: 0.2,
                    pointBackgroundColor: buffer1ColorBold,
                    fill: true
                },
            ],
        };

        return { sortingTotalsOptions, sortingTotalsData };
    }

    const getDoughnutConfiguration = () => {
        const doughnutOptions = {
            responsive: false,
            maintainAspectRation: false,
            plugins: {
                legend: {
                    display: false
                },
            },
            cutout: 40
        }
    
        const plugins1 = [{
            id:'11',
            beforeDraw: function(chart: any) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                    ctx.restore();
                    var fontSize = (height / 100).toFixed(2);
                    ctx.font = fontSize + "em sans-serif";
                    ctx.textBaseline = "center";
                    var text = "58%",
                    textX = Math.round((width - ctx.measureText(text).width) / 2) + 3,
                    textY = height / 2 + 5;
                    ctx.fillText(text, textX, textY);
                    ctx.save();
            } 
        }];
    
        const plugins2 = [{
            id:'12',
            beforeDraw: function(chart: any) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                    ctx.restore();
                    var fontSize = (height / 100).toFixed(2);
                    ctx.font = fontSize + "em sans-serif";
                    ctx.textBaseline = "center";
                    var text = "58%",
                    textX = Math.round((width - ctx.measureText(text).width) / 2) + 3,
                    textY = height / 2 + 5;
                    ctx.fillText(text, textX, textY);
                    ctx.save();
            } 
        }];
    
        const plugins3 = [{
            id:'13',
            beforeDraw: function(chart: any) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                    ctx.restore();
                    var fontSize = (height / 100).toFixed(2);
                    ctx.font = fontSize + "em sans-serif";
                    ctx.textBaseline = "center";
                    var text = "58%",
                    textX = Math.round((width - ctx.measureText(text).width) / 2) + 3,
                    textY = height / 2 + 5;
                    ctx.fillText(text, textX, textY);
                    ctx.save();
            } 
        }]
    
        const plugins4 = [{
            id:'14',
            beforeDraw: function(chart: any) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                    ctx.restore();
                    var fontSize = (height / 100).toFixed(2);
                    ctx.font = fontSize + "em sans-serif";
                    ctx.textBaseline = "center";
                    var text = "58%",
                    textX = Math.round((width - ctx.measureText(text).width) / 2) + 3,
                    textY = height / 2 + 5;
                    ctx.fillText(text, textX, textY);
                    ctx.save();
            } 
        }]
    
        const data1 = {
            labels: ['Occupied', 'Remaining'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [19, 12],
                    backgroundColor: [
                        buffer1ColorLight,
                        'rgba(150, 150, 150, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        const data2 = {
            labels: ['Occupied', 'Remaining'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [19, 12],
                    backgroundColor: [
                        buffer1ColorLight,
                        'rgba(150, 150, 150, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        const data3 = {
            labels: ['Occupied', 'Remaining'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [19, 12],
                    backgroundColor: [
                        buffer1ColorLight,
                        'rgba(150, 150, 150, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        const data4 = {
            labels: ['Occupied', 'Remaining'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [19, 12],
                    backgroundColor: [
                        buffer1ColorLight,
                        'rgba(150, 150, 150, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        return { doughnutOptions, plugins1, plugins2, plugins3, plugins4, data1, data2, data3, data4 };
    }

    const getBarChartConfiguration = () => {
        const barChartOptions = {
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom' as const,
              },
              title: {
                display: false,
                text: 'Chart.js Bar Chart',
              },
            },
        };
          
        const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
          
        const barChartData = {
            labels,
            datasets: [
                {
                    label: 'Supplier 1',
                    data: [400, 700, 1000, 300, 500, 600, 800],
                    backgroundColor: buffer1Color,
                },
                {
                    label: 'Supplier 2',
                    data: [500, 600, 300, 500, 800, 1000, 700],
                    backgroundColor: buffer2Color,
                },
                {
                    label: 'Supplier 3',
                    data: [320, 500, 450, 180, 660, 900, 570],
                    backgroundColor: buffer3Color,
                },
                {
                    label: 'Supplier 4',
                    data: [570, 630, 320, 540, 630, 300, 430],
                    backgroundColor: buffer4Color,
                },
            ],
        };

        return { barChartOptions, barChartData };
    }

    const { sortingBufferOptions, sortingBufferData } = getSortingBufferChartsConfiguration();
    const { sortingTotalsOptions, sortingTotalsData } = getSortingTotalsChartsConfiguration();
    const { doughnutOptions, plugins1, plugins2, plugins3, plugins4, data1, data2, data3, data4 } = getDoughnutConfiguration();
    const { barChartOptions, barChartData } = getBarChartConfiguration();

    return (
        <div className='etportal-page-container'>
            <h1 className='module-title'>Waste Management</h1>
            <div id='overview-container'>
                <OverviewElement title='Plastic materials' value='272 kg' />
                <OverviewElement title='Metals' value='3700 kg' />
                <OverviewElement title='Cardboards & paper' value='2200 kg' />
                <OverviewElement title='Wood materials' value='6544 kg' />
                <OverviewElement title='Waste materials' value='15200 kg' />
            </div>
            <div id='line-charts-container'>
                <div id='first-chart-container'>
                    <h3>Sorting buffer</h3>
                    <div className='line-chart'>
                        <Line options={sortingBufferOptions} data={sortingBufferData} />
                    </div>
                </div>
                <div id='second-chart-container'>
                    <h3>Sorting totals</h3>
                    <div className='line-chart'>
                        <Line options={sortingTotalsOptions} data={sortingTotalsData} />
                    </div>
                </div>
            </div>
            <div id='second-level-container'>
                <div>
                    <h3 className='waste-management-section-title'>Main supliers</h3>
                    <div className='bar-chart'>
                        <Bar options={barChartOptions} data={barChartData} />
                    </div>
                </div>
                <div>
                    <h3 className='waste-management-section-title'>Buffers loading level</h3>
                    <div id='doughnuts-container'>
                        <div className='dough-container'>
                            <Doughnut options={doughnutOptions} data={data1} height={'120px'} width={'120px'} plugins={plugins1}/>
                            <h4 className='doughnut-title'>Buffer 1</h4>
                        </div>
                        <div className='dough-container'>
                            <Doughnut options={doughnutOptions} data={data2} height={'120px'} width={'120px'} plugins={plugins2}/>
                            <h4 className='doughnut-title'>Buffer 2</h4>
                        </div>
                        <div className='dough-container'>
                            <Doughnut options={doughnutOptions} data={data3} height={'120px'} width={'120px'} plugins={plugins3}/>
                            <h4 className='doughnut-title'>Buffer 3</h4>
                        </div>
                        <div className='dough-container'>
                            <Doughnut options={doughnutOptions} data={data4} height={'120px'} width={'120px'} plugins={plugins4}/>
                            <h4 className='doughnut-title'>Buffer 4</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WasteStockPage;