import { LoginActionTypes } from '../actions/types';
import { AuthState, LoginAction } from '../types';

const initialState: AuthState = {
	sessionId: localStorage.getItem('sessionId'),
	isAuthenticated: false,
	loading: true,
	user: {
		id: '',
		name: '',
		email: '',
		role: '',
		phone: '',
		jobTitle: ''
	},
	company: {
		id: '',
		name: '',
		features: []
	}
};

export default function auth(state: AuthState = initialState, action: LoginAction) {
	const { type, payload } = action;

	switch (type) {
		case LoginActionTypes.LOGIN_SUCCESS:
			if (payload.sessionId) localStorage.setItem('sessionId', payload.sessionId);
			return {
				...state,
				sessionId: payload.sessionId,
				user: payload.userData,
				company: payload.companyData,
				isAuthenticated: true,
				loading: false,
			};
		case LoginActionTypes.MODIFY_USER_NAME:
			return {
				...state,
				user: {
					...state.user,
					name: payload.userData.name
				}
			}
		case LoginActionTypes.MODIFY_USER_EMAIL:
			return {
				...state,
				user: {
					...state.user,
					email: payload.userData.email
				}
			}
		case LoginActionTypes.MODIFY_USER_PHONE:
			return {
				...state,
				user: {
					...state.user,
					phone: payload.userData.phone
				}
			}
		case LoginActionTypes.MODIFY_USER_JOB_TITLE:
			return {
				...state,
				user: {
					...state.user,
					jobTitle: payload.userData.jobTitle
				}
			}
		case LoginActionTypes.LOGOUT:
		case LoginActionTypes.LOGIN_FAIL:
			localStorage.removeItem('sessionId');
			return {
				...state,
				sessionId: null,
				isAuthenticated: false,
				loading: false,
			};
		default:
			return state;
	}
}
