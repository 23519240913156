import axios from 'axios';
import { CONSTANTS } from '../../lib/constants';
import ServerRequest from '../../lib/serverRequests/ServerRequest';

import { setAlert } from './alert';
import { ApplicationActionTypes, LoginActionTypes } from './types';

export const login = (email: string, password: string) => async (dispatch: any) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	const body = JSON.stringify({ email, password });

	try {
		const result = await axios.post('api/users/authenticate', body, config);

		const landingPage = result.data.userRole === CONSTANTS.USER_ROLES.SUPERADMIN ? CONSTANTS.MODULES.SUPERADMIN_PAGE : CONSTANTS.MODULES.SCALE_REPORTS_DASHBOARD_PAGE;

		dispatch({
			type: LoginActionTypes.LOGIN_SUCCESS,
			payload: {
				sessionId: result.data.sessionId,
				userData: {
					id: result.data.userId,
					name: result.data.userName,
					email: email,
					role: result.data.userRole,
					phone: result.data.userPhone,
					jobTitle: result.data.userJobTitle
				},
				companyData: {
					id: result.data.companyId,
					name: result.data.companyName,
					features: result.data.companyFeatures
				}
			}
		});

		dispatch({
			type: ApplicationActionTypes.SET_ACTIVE_MODULE,
			payload: {
				module: landingPage
			}
		});
	} catch (err: any | import("axios").AxiosError) {

		if (axios.isAxiosError(err) && err.response) {
			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach((error: any) => dispatch(setAlert(error.msg, 'danger')));
			}
		} else {
			dispatch(setAlert('Server error', 'danger'));
		}

		dispatch({
			type: LoginActionTypes.LOGIN_FAIL,
		});
	}
};

export const logout = (sessionId: string) => async (dispatch: any) => {
		await ServerRequest.logout({sessionId});
		dispatch({type: LoginActionTypes.LOGOUT});
		dispatch({
			type: ApplicationActionTypes.SET_ACTIVE_MODULE,
			payload: {
				module: ''
			}
		})
};

export const modifyUserName = (userName: string) => async (dispatch: any) => {
	dispatch({
		type: LoginActionTypes.MODIFY_USER_NAME,
		payload: {
			userData: {
				name: userName
			}
		}
	});
}

export const modifyUserEmail = (userEmail: string) => async (dispatch: any) => {
	dispatch({
		type: LoginActionTypes.MODIFY_USER_EMAIL,
		payload: {
			userData: {
				email: userEmail
			}
		}
	});
}

export const modifyUserPhone = (userPhone: string) => async (dispatch: any) => {
	dispatch({
		type: LoginActionTypes.MODIFY_USER_PHONE,
		payload: {
			userData: {
				phone: userPhone
			}
		}
	});
}
export const modifyUserJobTitle = (userJobTitle: string) => async (dispatch: any) => {
	dispatch({
		type: LoginActionTypes.MODIFY_USER_JOB_TITLE,
		payload: {
			userData: {
				jobTitle: userJobTitle
			}
		}
	});
}