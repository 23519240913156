import { BaseDataProvider } from "../../../../lib/baseDataProvider/base.dataProvider";
import { FilterForBackend } from "../../scaleReportsPage/types/types";
import { ScaleDeviceRawEvent, TimeRangeFilter } from "../types/types";

export default class ScaleDevicesRawEventsPageDataProvider extends BaseDataProvider {

    public async getRawEvents(timeRangeFilter: Array<FilterForBackend | TimeRangeFilter | undefined>): Promise<ScaleDeviceRawEvent[]> {
        const result = await this.launchGetRequest<ScaleDeviceRawEvent[]>('api/scaleDevices/getRawEvents', null, timeRangeFilter);
        return result.data;
    }
}