import { BarChart } from "@mui/x-charts/BarChart";
import { Component } from "react";

export interface ETBarChartProps {
    data: any;
    labels: any;
    margins: any;
    colours: Array<string>;
    skipTicks?(value:any, index:number): boolean;
    showValuesOnBars?: boolean;
    roundedBorders?: boolean;
    hideXAxisLabels?: boolean;
    hideYAxisLabels?: boolean;
    isHorizontal?: boolean;
    showYAxisValues?: boolean;
}

export interface ETBarChartState {

}

export class ETBarChart extends Component<ETBarChartProps, ETBarChartState> {
    public constructor(props: ETBarChartProps) {
        super(props);
    }

    render() {
        return (
            <BarChart
                series={[{data: this.props.data}]}
                {...(this.props.isHorizontal && {yAxis: [{
                    scaleType: 'band', 
                    data: this.props.labels,
                    tickInterval: this.props.skipTicks,
                    colorMap: {
                        type: 'ordinal',
                        colors: this.props.colours
                    }
                }]
                })}
                {...(!this.props.isHorizontal && {xAxis: [{
                    scaleType: 'band', 
                    data: this.props.labels,
                    tickInterval: this.props.skipTicks,
                    colorMap: {
                        type: 'ordinal',
                        colors: this.props.colours
                    }
                }]
                })}
                sx={{
                    "& .MuiChartsAxis-left": 
                        this.props.hideXAxisLabels ? { display: this.props.showYAxisValues ? "true" : "none" } : {}
                    ,
                    "& .MuiChartsAxis-bottom": 
                        this.props.hideYAxisLabels ? { display: 'none' } : {}
                    ,
                    "& .MuiChartsAxis-line": {
                        display: 'none'
                    },
                    "& .MuiChartsAxis-tick": {
                        display: 'none'
                    },
                    "& .MuiBarLabel-root": {
                        fill: "rgba(255,255,255,1)" // bar label value colour #white
                    },
                }}
                borderRadius={ this.props.roundedBorders ? 10 : 0 }
                {...(this.props.showValuesOnBars && {barLabel: "value"})}
                margin={ this.props.margins }
                layout={ this.props.isHorizontal ? "horizontal" : "vertical" }
            />
        )
    }
}