import ScaleDevicesRawEventsPageDataProvider from "../dataProvider/scaleDevicesRawEvents.dataProvider";
import { ScaleDeviceRawEvent, TimeRangeFilter } from "../types/types";


export class ScaleDevicesRawEventsController {
    private static dataProvider: ScaleDevicesRawEventsPageDataProvider = new ScaleDevicesRawEventsPageDataProvider();

    static async getRawEvents(timeRangeFilter: TimeRangeFilter): Promise<Array<ScaleDeviceRawEvent>> {
        const allFilters: Array<TimeRangeFilter> = [timeRangeFilter];
        return await this.dataProvider.getRawEvents(allFilters);
    }
}