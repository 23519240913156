import { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/auth';
import { closePopup } from '../../../redux/actions/popup';
import { RootState } from '../../../redux/types';
import './etpopup.style.css'
import { PopupButtonTypes } from './constants';
import React from 'react';

export interface ETPopupProps {
    buttonsType?: PopupButtonTypes;
    popupTitle?: string;
    popupText?: string;
    popupVisible?: boolean;
    shouldLogoutUser?: boolean;
    sessionId?: string | null;
    closePopup: () => void;
    logout: (sessionId: string) => void;
    handleYesButton?: () => void;
    handleNoButton?: () => void;
}

class ETPopup extends Component<ETPopupProps> {

    protected handleOkButton = () => {
        if (this.props.shouldLogoutUser && this.props.sessionId) {
            this.props.logout(this.props.sessionId)
        }

        this.props.closePopup();
    }

    protected handleYesButton = () => {
        if (this.props.handleYesButton) this.props.handleYesButton();
        this.props.closePopup();
    }

    protected handleNoButton = () => {
        if (this.props.handleNoButton) this.props.handleNoButton();
        this.props.closePopup();
    }

    protected handleCancelButton = () => {
        console.log('to implement');
        this.props.closePopup();
    }

    private displayButtons = () => {
        const { buttonsType } = this.props;
        switch (buttonsType) {
            case PopupButtonTypes.YES_NO:
                return (
                    <div id='popup-buttons-yes-no-container'>
                        <button className='et-button' onClick={this.handleYesButton}>Yes</button>
                        <button className='et-button' onClick={this.handleNoButton}>No</button>
                    </div>
                )
            case PopupButtonTypes.OK: 
                return (
                    <div id='popup-buttons-ok-container'>
                        <button className='et-button' onClick={this.handleOkButton}>Ok</button>
                    </div>
                )
            case PopupButtonTypes.CANCEL:
                return (
                    <div id='popup-buttons-cancel-container'>
                        <button className='et-button' onClick={this.handleCancelButton}>Cancel</button>
                    </div>
                )
        }
    }

    private formatText(rawText: string) {
        return rawText.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== rawText.length - 1 && <br />}
            </React.Fragment>
        ));
    }
    
    public render = () => {
        const { popupTitle, popupText, popupVisible } = this.props;

        if (!popupText) {
            return null;
        }

        const formattedText = this.formatText(popupText);
        return popupVisible && (
            <div id='popup-container'>
                <div id='popup-inner-content'>
                    <h3 id='popup-title'>{popupTitle}</h3>
                    <p id='popup-text'>{formattedText}</p>
                    {this.displayButtons()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        buttonsType: state.popup.buttonsType,
        popupTitle: state.popup.title,
        popupText: state.popup.text,
        popupVisible: state.popup.visible,
        shouldLogoutUser: state.popup.shouldLogoutUser,
        sessionId: state.auth.sessionId,
        handleYesButton: state.popup.handleYesButton,
        handleNoButton: state.popup.handleNoButton
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closePopup: () => dispatch(closePopup()),
        logout: (sessionId: string) => dispatch(logout(sessionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ETPopup);