import { ScaleDeviceRawEvent } from "../../../types/types";
import ScaleDeviceRawEventFormDataProvider from "../dataProvider/scaleDeviceRawEventForm.dataProvider";


export class ScaleDeviceRawEventFormController {
    private static dataProvider: ScaleDeviceRawEventFormDataProvider = new ScaleDeviceRawEventFormDataProvider();

    static async updateRawEvent(event: ScaleDeviceRawEvent): Promise<void> {
        return await this.dataProvider.updateRawEvent(event);
    }
}