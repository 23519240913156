import { PopupButtonTypes } from "../../components/ETComponents/ETPopup/constants";
import { PopupActionTypes } from "../actions/types";
import { IPopup, PopupAction } from "../types";

const initialState: IPopup = {
    text: '',
    title: '',
    buttonsType: PopupButtonTypes.OK,
    visible: false,
    shouldLogoutUser: false
}

const popup = (state: IPopup = initialState, action: PopupAction) => {
    const { type, payload } = action;

    switch (type) {
        case PopupActionTypes.DISPLAY_POPUP:
            return {
                ...state,
                title: payload.title,
                text: payload.text,
                buttonsType: payload.buttonsType,
                shouldLogoutUser: payload.shouldLogoutUser,
                handleYesButton: payload.handleYesButton,
                handleNoButton: payload.handleNoButton,
                visible: true
            };
        case PopupActionTypes.CLOSE_POPUP:
            return {
                ...state,
                title: payload.title,
                text: payload.text,
                buttonsType: payload.buttonsType,
                shouldLogoutUser: payload.shouldLogoutUser,
                visible: false
            }

        default:
            return state;
    }
}

export default popup;