import { Content } from "pdfmake/interfaces";

export abstract class BasePdfBuilder<T>{
    public abstract downloadDocument(data: T, additionalData?: any): void

    public abstract generateDocument(data: T, additionalData?: any): pdfMake.TCreatedPdf

    public generateBlob(data: T, additionalData?: any): Promise<Blob | null> {
        const doc = this.generateDocument(data, additionalData);
        return new Promise((resolve, reject) => {
            doc.getBuffer((buffer: ArrayBuffer) => {
                try {
                    const blob = new Blob([buffer]);
                    resolve(blob);
                } catch (error) {
                    reject(null);
                }
            });
        });
    }

    protected generateInvoiceCompanyField(
        fieldName: string, 
        fieldValue: string, 
        margin: [number, number, number, number] = [0, 0, 0, 5], 
        fieldNameIsBold: boolean = true,
        useSeparator: boolean = true,
        style: string = ""
    ): Content {
        let separator = (useSeparator) ? ": " : "";
        let styleValue = (style === "") ? "small_text" : style;
        let isBold = (fieldNameIsBold) ? styleValue + "_bold" : styleValue;

        return {
            text: [
                {
                    text: (fieldName === undefined ? "-" : fieldName) + separator,
                    style: isBold
                }, 
                {
                    text: fieldValue
                }],
            style: styleValue,
            margin: margin
        };
    }

    protected parseUnitMeasure(unitMeasure: string)
    {
        if(unitMeasure === "H87")
        {
            return "BUC";
        }
        return unitMeasure;
    }

    protected insideBorder(content: Content) : Content {
        return {
            table: {
                body: [
                    [
                        content
                    ]
                ]
            }
        }
    }

    protected addFloatsTwoDecimals(float1: number, float2: number): number {
        float1 = parseFloat(float1.toFixed(2));
        float2 = parseFloat(float2.toFixed(2));
        let sum = float1 + float2;
        sum = parseFloat(sum.toFixed(2));
        return sum;
    }

    protected parseTimestampToDate(timestamp: number): string {
        let date = new Date(timestamp);

        return date.toLocaleDateString("en-GB", { year: "numeric",  month: "2-digit", day: "2-digit" });
    }
}