import { useSelector } from 'react-redux';
import warningIcon from '../../../images/warning-icon.png';
import { RootState } from '../../../redux/types';

import './alert.styles.css';

export const Alert = () => {
	const alerts = useSelector((state: RootState) => state.alerts);

	return (
		<>
			{
				alerts !== null &&
				alerts.length > 0 &&
				alerts.map((alert) => (
					<div
						id='alert-container'
						className={`alert-${alert.alertType}`}
						key={alert.id}
					>
						<div id='alert-image'>
							<img src={warningIcon} alt='warning-icon'/>
						</div>
						<div id='alert-message'>
							<p>{alert.msg}</p>
						</div>
					</div>
				))
			}
		</>
	);
};
