import { Component, ReactElement } from "react";
import { TimeRange } from "../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.types";
import { ScaleDeviceRawEvent, TimeRangeFilter } from "./types/types";
import EtTimeRangeFilter from "../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.component";
import { TableVirtuoso } from "react-virtuoso";
import { ScaleDevicesRawEventsController } from "./controller/scaleDevicesRawEvents.controller";
import { dispatchRequestErrorEvent } from "../../../lib/events/requestErrorEvent";
import editImg from '../../../images/edit-icon.png';

import "./scaleDevicesRawEvents.styles.css";
import { ETIcon } from "../../ETComponents/ETIcon/eticon.component";
import ScaleDeviceRawEventForm from "./innerComponents/scaleDeviceRawEventForm/scaleDeviceRawEventForm.component";
import { Utils } from "../../../lib/utils/utils";

interface ScaleDevicesRawEventsPageState {
    scaleDevicesRawEvents: Array<ScaleDeviceRawEvent>;
    timeRange: TimeRange;
    showScaleDeviceRawEventForm: boolean;
    editableScaleDeviceRawEvent: ScaleDeviceRawEvent | undefined;
}

class ScaleDevicesRawEventsPage extends Component<{}, ScaleDevicesRawEventsPageState> {
    
    constructor(props: any) {
        super(props);

        this.state = {
            scaleDevicesRawEvents: [],
            timeRange: {
                startInterval: 0,
                endInterval: 0
            },
            showScaleDeviceRawEventForm: false,
            editableScaleDeviceRawEvent: undefined
        }

        // bind here event handlers
        this.loadScaleDevicesRawEvents = this.loadScaleDevicesRawEvents.bind(this);
        this.timeRangeFilterChanged = this.timeRangeFilterChanged.bind(this);
        this.showEventEditForm = this.showEventEditForm.bind(this);
        this.hideEventEditForm = this.hideEventEditForm.bind(this);
    }

    async loadScaleDevicesRawEvents() {
        try {
            const timeRangeFilter: TimeRangeFilter = {
                name: 'timeRange',
                values: this.state.timeRange
            }
            
            const scaleDevicesRawEvents = (await ScaleDevicesRawEventsController.getRawEvents(timeRangeFilter)).reverse();
            this.setState({
                scaleDevicesRawEvents: scaleDevicesRawEvents
            });
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }

        this.hideEventEditForm();
    }

    private timeRangeFilterChanged(selectedTimeRange: TimeRange): void {
        this.setState(
            {
                timeRange: selectedTimeRange
            },
            () => this.loadScaleDevicesRawEvents()
        );
    }

    private showEventEditForm(scaleDeviceRawEvent: ScaleDeviceRawEvent): void {
        this.setState({
            showScaleDeviceRawEventForm: false,
            editableScaleDeviceRawEvent: undefined
        }, () => {
            this.setState({
                showScaleDeviceRawEventForm: true,
                editableScaleDeviceRawEvent: scaleDeviceRawEvent,
            });
        });
    }

    private hideEventEditForm(): void {
        this.setState({
            showScaleDeviceRawEventForm: false,
            editableScaleDeviceRawEvent: undefined
        })
    }

    render(): ReactElement { 

        return (
            <div className='etportal-page-container'>
                <h1 className='module-title'>Evenimente cantare</h1>
                <div>
                    {this.state.showScaleDeviceRawEventForm 
                    ? <ScaleDeviceRawEventForm scaleDeviceRawEvent={this.state.editableScaleDeviceRawEvent} handleUpdateScaleDeviceRawEvent={this.loadScaleDevicesRawEvents} handleCancelUpdateScaleDeviceRawEvent={this.hideEventEditForm}/> 
                    : <></>
                    }
                </div>
                <div id="table-filters-container">
                    <EtTimeRangeFilter onChange={this.timeRangeFilterChanged}/>
                </div>
                <div className="scale-devices-raw-events-virtualized-table-container">
                    <TableVirtuoso 
                        className={"scale-devices-raw-events-virtualized-table"}
                        data={this.state.scaleDevicesRawEvents}
                        fixedHeaderContent={() => (
                            <tr>
                                <th>
                                    Data
                                </th>
                                <th>
                                    Dispozitiv
                                </th>
                                <th>
                                    Valoare (kg)
                                </th>
                                <th>
                                    Nota
                                </th>
                                <th>

                                </th>
                            </tr>
                        )}
                        itemContent={(index, rawEvent: ScaleDeviceRawEvent) => {
                            const rawEventDate = new Date(rawEvent.metadata.dateInUTC);
                            const { month, day, hour, minute } = Utils.getDateElements(rawEventDate);
                            
                            return (
                                <>
                                    <td id={index + ""}  className='eclipse-text'>{`${hour}:${minute} ${day}/${month}`}</td>
                                    <td className='eclipse-text'>{rawEvent.metadata.macAddress}</td>
                                    <td className='eclipse-text'>{rawEvent.weighValue}</td>
                                    <td className='eclipse-text' title={`${rawEvent.note}`}>{rawEvent.note}</td>
                                    <td>
                                        <ETIcon image={editImg} altText={'edit'} isHidden={false} tooltipText={'Modifica nota'} onClick={() => this.showEventEditForm(rawEvent)}/>
                                    </td>
                                </>
                            )
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default ScaleDevicesRawEventsPage;