import './menuListItem.styles.css';

interface MenuListItemProps {
    name: string;
    icon: string;
}

const MenuListItem = (props: MenuListItemProps) => (
    <div className='menu-item-container'>
        <img className='medium-icon' alt='menu-icon' src={props.icon} />
        <p className='module-name'>{props.name}</p>
    </div>
    
)

export default MenuListItem;