import axios from "axios";
import { PurchaseOrder } from "../../types";
import ServerRequest, { RequestConfig } from "../ServerRequest";

export class PurchaseOrderRequest extends ServerRequest {
    
    static getPurchaseOrders = async (): Promise<Array<PurchaseOrder>> => {
        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        let result: Array<PurchaseOrder> = [];

        try {
            const requestResult = await axios.get('api/purchaseOrders', requestHeaders);
            result = requestResult.data;
        } catch (err) {
            this.treatRequestErrors(err);
        }

        return result;
    }

    static cancelPurchaseOrder = async (orderId: string): Promise<boolean> => {
        let cancelSuccessful = true;

        const requestHeaders: RequestConfig = ServerRequest.getRequestHeaders(true);
        const body = { status: "Cancelled" };

        try {
            await axios.post(`api/purchaseOrders/${orderId}`, body, requestHeaders)
        } catch (err: any | import('axios').AxiosError) {
            cancelSuccessful = false;
            this.treatRequestErrors(err);
        }

        return cancelSuccessful;
    }
}