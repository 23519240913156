import { ChangeEvent, Component } from "react";

export enum AcceptedNumberType {
    INTEGER = 'integer',
    POSITIVE_FLOAT = 'positive_float',
    FLOAT = 'float'
}

export interface NumberInputProps {
    id: string;
    name: string;
    className: string;
    acceptedNumberType: AcceptedNumberType;
    defaultValue: number;
    // send here the value directly
    onChange: (changedAttribute: {value: string; name: string}) => any;
    required?: boolean;
    disabled?: boolean;
}

interface NumberInputState {
    value: number | string;
    lastValidValue: number | string;
}

export class ETNumberInput extends Component<NumberInputProps, NumberInputState> {
    constructor(props: NumberInputProps) {
        super(props)

        this.state = {
            value: this.props.defaultValue,
            lastValidValue: this.props.defaultValue
        }

        this.parseInput = this.parseInput.bind(this);
    }

    componentDidMount() {
        if (!this.props.defaultValue ) {
            return;
        }

        this.props.onChange({name: this.props.name, value: this.props.defaultValue + ''});
        
    }

    private parseInput(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { value } = event.target;
        let parsedValue: number | string = this.props.defaultValue;

        switch(this.props.acceptedNumberType){
            case AcceptedNumberType.POSITIVE_FLOAT:
                parsedValue = this.parsePositiveFloatInput(value);
                event.target.value = parsedValue + "";
                break;
            case AcceptedNumberType.FLOAT:
                parsedValue = this.parseFloatInput(value);
                event.target.value = parsedValue + "";
                break;
            default:
                parsedValue = this.parsePositiveIntegerInput(value);
                event.target.value = parsedValue.toFixed(0);
                break;
        }

        this.props.onChange({name: event.target.name, value: event.target.value});
    }

    private parsePositiveIntegerInput(value: string) {
        let newValue = this.props.defaultValue;

        if (value.trim() === '') {
            this.setState({
                value: 0
            })
            return 0;
        }
        
        newValue = parseFloat(parseFloat(value.replace(/[^0-9]$/, '')).toFixed(2));

        this.setState({
            value: newValue
        })

        return newValue;
    }

    private parsePositiveFloatInput(value: string): number | string{
        let newValue: number | string = this.props.defaultValue;

        if (value.trim() === '') {
            this.setState({
                lastValidValue: 0,
                value: 0
            })
            return 0;
        }

        if(value.match(/^(?:\d+|(?:\d*\.\d{1,2}|\d+\.))$/)) {

            if(!value.includes(".")) {
                //number is integer, behave as above
                newValue = parseFloat(parseFloat(value.replace(/[^0-9]$/, '')).toFixed(2));

                this.setState({
                    lastValidValue: newValue,
                    value: newValue
                });

                return newValue;
            }

            newValue = value as string;

            this.setState({
                lastValidValue: newValue,
                value: newValue
            });
            return newValue;
        }

        this.setState({
            value: this.state.lastValidValue
        });

        return newValue;
    }

    private parseFloatInput(value: string): number | string{
        let newValue: number | string = this.props.defaultValue;
        let isNegative: boolean = false;
        let isPositive: boolean = false;

        if (value.trim() === '') {
            this.setState({
                lastValidValue: 0,
                value: 0
            });
            return 0;
        }

        if(value.search(/(-)/) > -1) {
            value = value.replace(/(-)/, '');
            isNegative = true;
        }

        if(value.search(/(\+)/) > -1) {
            value = value.replace(/(\+)/, '');
            isPositive = true;
        }

        if(value.search(/(\d)/) < 0 || value === "0") {
            newValue = this.assignCorrectSignToValue("0", isNegative, isPositive)
            this.setState({
                lastValidValue: newValue,
                value: newValue
            });
            return newValue;
        }

        if(value.match(/^(?:\d+|(?:\d*\.\d{1,2}|\d+\.))$/)) {

            if(!value.includes(".")) {
                //number is integer, behave as above
                newValue = parseFloat(parseFloat(value.replace(/[^0-9]$/, '')).toFixed(2));

                newValue = this.assignCorrectSignToValue(newValue + "", isNegative, isPositive);

                this.setState({
                    lastValidValue: newValue,
                    value: newValue
                });
                
                return newValue;
            }

            newValue = value as string;
            newValue = this.assignCorrectSignToValue(newValue, isNegative, isPositive);

            this.setState({
                lastValidValue: newValue,
                value: newValue
            });
            
            return newValue;
        }

        this.setState({
            value: this.state.lastValidValue
        });

        newValue = this.assignCorrectSignToValue(newValue + "", isNegative, isPositive);
        
        return newValue;
    }

    private assignCorrectSignToValue(value: string, isNegative: boolean, isPositive: boolean): string {
        let lastCorrectValueIsNegative: boolean = false;
        let shouldBePositive: boolean = false;

        if(typeof this.state.lastValidValue === "string") {
            lastCorrectValueIsNegative = (this.state.lastValidValue as string).search(/(-)/) >= 0 ? true : false;
        } else {
            lastCorrectValueIsNegative = (this.state.lastValidValue as number) < 0 ? true : false;
        }

        shouldBePositive = !lastCorrectValueIsNegative;

        if(lastCorrectValueIsNegative && isPositive ) {
            shouldBePositive = true;
        } else if(!lastCorrectValueIsNegative && isNegative) {
            shouldBePositive = false;
        }
        if (!isPositive && !isNegative) {
            shouldBePositive = true;
        }

        return shouldBePositive ? value : "-" + value;
    }

    public render() {
        return (
            <input
                id={this.props.id}
                name={this.props.name}
                className={this.props.className}
                value={this.state.value}
                onChange={this.parseInput}
                disabled={!!this.props.disabled}
                required={!!!this.props.required}
            />
        )
    }
}