import closeDetailsPanelIcon from '../../../images/close-arrow.png';
import { ScaleReport } from '../../../lib/types';
import { translateStatus } from '../../../lib/utils/translate';
import { Utils } from '../../../lib/utils/utils';
import './scaleReportsSidePanel.styles.css';

interface ReportsSidePanelProps {
    scaleReport: ScaleReport | null;
}

const ScaleReportsSidePanel = (props: ReportsSidePanelProps) => {

    const closeDetailsPanel = () => {
        const detailsPanelEL = document.getElementById('details-panel') ? document.getElementById('details-panel') : null;
        if (detailsPanelEL) {
            detailsPanelEL.style.width = '0px';
            detailsPanelEL.style.paddingLeft = '0px';
            detailsPanelEL.style.color = '#ffffff';
        }
    }

    return (
        <div id='details-panel'>
            <div id='details-panel-header'>
                <h3>Detalii raport</h3>
                <div id='status-container'>
                    <h3 id='report-status' className={props.scaleReport ? Utils.setColorByState(props.scaleReport.status) : ""}>{props.scaleReport ? translateStatus(props.scaleReport.status) : ''}</h3>
                </div>
                <div id='close-details-panel-img-container'>
                    <img id='details-panel-close-icon' src={closeDetailsPanelIcon} alt='close-panel' onClick={closeDetailsPanel}/>
                </div>
            </div>
            
            <div id='report-details-section'>
                <p className='scale-detail'>Numar tichet: <span className='info'>{(props.scaleReport) ? props.scaleReport.details.ticketNumber : ''}</span></p>
                <div id='details-table'>
                    <div id='details-table-column-1'>
                        <p className='scale-detail'>Nume furnizor: <span className='info'>{props.scaleReport ? props.scaleReport.supplierName : ''}</span></p>
                        <p className='scale-detail'>Nume client: <span className='info'>{props.scaleReport ? props.scaleReport.clientName : ''}</span></p>
                        <p className='scale-detail'>Produs: <span className='info'>{props.scaleReport ? props.scaleReport.productName : ''}</span></p>
                        <p className='scale-detail'>Greutate intrare: <span className='info'>{props.scaleReport ? props.scaleReport.details.weightIn : ''}</span></p>
                        <p className='scale-detail'>Greutate iesire: <span className='info'>{(props.scaleReport && props.scaleReport.details.weightOut && props.scaleReport.status === 'Complete') ? props.scaleReport.details.weightOut : 'N/A'}</span></p>
                        <p className='scale-detail'>Data intrare: <span className='info'>{(props.scaleReport) ? new Date(props.scaleReport.details.timestampIn).toLocaleString() : 'N/A'}</span></p>
                        <p className='scale-detail'>Data iesire: <span className='info'>{(props.scaleReport && props.scaleReport.details.timestampOut && props.scaleReport.status === 'Complete') ? new Date(props.scaleReport.details.timestampOut).toLocaleString() : 'N/A'}</span></p>
                    </div>
                    <div id='details-table-column-2'>
                        <p className='scale-detail'>Numar inmatriculare camion: <span className='info'>{props.scaleReport ? props.scaleReport.details.licencePlateTruck : ''}</span></p>
                        <p className='scale-detail'>Numar inmatriculare remorca: <span className='info'>{props.scaleReport && props.scaleReport.details.licencePlateTrail !== '' ? props.scaleReport.details.licencePlateTrail : 'N/A'}</span></p>
                        <p className='scale-detail'>Nume sofer: <span className='info'>{props.scaleReport ? props.scaleReport.details.driverName : ''}</span></p>
                        <p className='scale-detail'>Tip document insotitor: <span className='info'>{(props.scaleReport && props.scaleReport.details.attachDocumentType) ? props.scaleReport.details.attachDocumentType : 'N/A'}</span></p>
                        <p className='scale-detail'>Numar document insotitor: <span className='info'>{(props.scaleReport && props.scaleReport.details.attachDocumentNumber && props.scaleReport.status === 'Complete') ? props.scaleReport.details.attachDocumentNumber : 'N/A'}</span></p>
                        <p className='scale-detail'>Nume operator: <span className='info'>{props.scaleReport ? props.scaleReport.details.operatorName : ''}</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScaleReportsSidePanel;