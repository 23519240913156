import './profileAlert.styles.css';
import warningIcon from '../../../../../images/warning-icon.png';
import successIcon from '../../../../../images/check.png';

interface ProfileAlertProps {
    visible: boolean;
    type: string;
    message: string;
}

const ProfileAlert = (props: ProfileAlertProps) => {
    const { visible, type, message} = props;
    const image = (type === 'danger') 
                    ? <img src={warningIcon} alt='warning-icon' />
                    : <img src={successIcon} alt='success-icon' />
    return (
        <>
        {visible && 
            <div id='profile-alert-container' className={`alert-${type}`}>
                <div className='profile-alert-image'>
                    {image}
                </div>
                <div className='profile-alert-message'>
                    <p>{message}</p>
                </div>
            </div>
        }
        </>
        
    )
}

export default ProfileAlert;