import { DeliveryNotePdfBuilder } from "./DeliveryNotePdfBuilder/deliveryNotePdfBuilder";
import { InvoicePdfBuilder } from "./InvoicePdfBuilder/invoicePdfBuilder";
import { ReceiptPdfBuilder } from "./ReceiptPdfBuilder/receiptPdfBuilder";
import { BasePdfBuilder } from "./basePdfBuilder";

export enum PdfDocumentType {
    DeliveryNote, Invoice, Payment
}

export class PdfBuilderFactory {
    public static getPdfBuilder(documentType: PdfDocumentType): BasePdfBuilder<any> | undefined {
        switch(documentType) {
            case PdfDocumentType.DeliveryNote:
                return new DeliveryNotePdfBuilder();
            case PdfDocumentType.Payment:
                return new ReceiptPdfBuilder();
            case PdfDocumentType.Invoice:
                return new InvoicePdfBuilder();
            default:
                console.error(`No suitable PdfBuilder found for type: ${documentType}.`);
                return undefined;
        }
    }
}
export { BasePdfBuilder };