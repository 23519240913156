import React, { useEffect, useRef, useState } from 'react';
import { ProfileRowData } from '../../types';
import ProfileRowDetails from '../profileRowDetails/profileRowDetails.component';
import './profileRow.styles.css';

interface ProfileRowProps {
    title: string;
    buttonText: string;
    displayValue: string;
    detailsData: ProfileRowData,
    submitForm: (e: React.SyntheticEvent) => void;
}

interface ProfileRowState {
    detailsVisible: boolean;
}

const ProfileRow = (props: ProfileRowProps) => {
    const [state, setState] = useState<ProfileRowState>({
        detailsVisible: false
    })

    const buttonRef = useRef<HTMLButtonElement>(null);
    const paragraphRef = useRef<HTMLParagraphElement>(null);

    const displayValue = props.displayValue;
    const detailsVisible = state.detailsVisible;
    
    useEffect(() => {
        if (paragraphRef.current) {
            paragraphRef.current.innerHTML = displayValue;
        }
    }, [displayValue, detailsVisible]);


    const setDetailsVisible = () => {
        setState({...state, detailsVisible: true});

        if (!buttonRef.current) return;

        buttonRef.current.style.display = 'none';
    }

    const removeDetails = () => {
        setState({...state, detailsVisible: false});

        if (!buttonRef.current) return;

        buttonRef.current.style.display = '';
    }

    const detailsComponent: JSX.Element = <ProfileRowDetails removeDetails={removeDetails} data={props.detailsData} submitForm={props.submitForm}/>;

    const content: JSX.Element = (state.detailsVisible) ? detailsComponent : <p className='displayed-value' ref={paragraphRef}></p>
    

    return (
        <div className='profile-row'>
            <div className='row-title'>
                <h4>{props.title}</h4>
            </div>
            <div className='row-content'>
                {content}
            </div>
            <div className='row-action'>
                <button ref={buttonRef} id='action-button' onClick={setDetailsVisible} className='et-button'>{props.buttonText}</button>
            </div>
        </div>
    )
}

export default ProfileRow;