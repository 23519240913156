import { Component, RefObject } from "react";
import Select, { MultiValue, SingleValue } from 'react-select';
import CreatableSelect from "react-select/creatable";

export type DropdownOption = SingleValue<{label: string, value: string, __isNew__?: boolean}> | MultiValue<{ value: string; label: string; }>

export interface ETDropdownProps {
    id: string;
    selectRef: RefObject<any>;
    selectOptions: Array<{ value: string; label: string; }>;
    name: string;
    isClearable: boolean;
    isMulti: boolean;
    createLabelStartText?: string;
    createLabelEndText?: string;
    placeholder: string;
    handleSelectChanged: (newValue: DropdownOption) => any;
    handleNoOptionsMessage: () => string;
    creatable: boolean;
    disabled?: boolean;
}

export class ETDropdown extends Component<ETDropdownProps> {

    public static defaultProps = {
      creatable: false
    }

    constructor(props: ETDropdownProps) {
        super(props)

        this.customCreateLabel = this.customCreateLabel.bind(this);
    }

    private getCustomSelectStyles() {
        const height = '26px';
        return {
            control: (provided: any, state: any) => ({
              ...provided,
              background: '#fff',
              borderColor: '#0164A5',
              minHeight: height,
              boxShadow: state.isFocused ? null : null,
              fontSize: '13px',
              maxWidth: '105% !important',
              width: '105% !important'
            }),
        
            valueContainer: (provided: any) => ({
              ...provided,
              minHeight: height,
              padding: '0 6px',
              paddingBottom: '2px;'
            }),
        
            input: (provided: any) => ({
              ...provided,
              margin: '0px',
            }),

            indicatorsContainer: (provided: any) => ({
              ...provided,
              height: height,
            }),

            menu: (provided: any) => ({
                ...provided,
                fontSize: '13px'
            })
          };
    }

    private customCreateLabel(inputValue: string) {
        return `${this.props.createLabelStartText}${inputValue}${this.props.createLabelEndText}`
    }

    public render() {
        return (this.props.creatable)
            ? <CreatableSelect
                id={this.props.id}
                ref={this.props.selectRef}
                options={this.props.selectOptions}
                styles={this.getCustomSelectStyles()}
                name={this.props.name}
                onChange={this.props.handleSelectChanged}
                isClearable={this.props.isClearable}
                isMulti={this.props.isMulti}
                formatCreateLabel={this.customCreateLabel}
                placeholder={this.props.placeholder}
                noOptionsMessage={this.props.handleNoOptionsMessage}
                isDisabled={!!this.props.disabled}
              />
            : <Select
                id={this.props.id}
                ref={this.props.selectRef}
                options={this.props.selectOptions}
                styles={this.getCustomSelectStyles()}
                name={this.props.name}
                onChange={this.props.handleSelectChanged}
                isClearable={this.props.isClearable}
                isMulti={this.props.isMulti}
                placeholder={this.props.placeholder}
                noOptionsMessage={this.props.handleNoOptionsMessage}
                isDisabled={!!this.props.disabled}
              />
    }
}