import { Component, RefObject } from "react";
import './eticon.style.css'

export interface ETIconProps {
    refObject?: RefObject<any>;
    id?: string;
    image: any;
    altText: string;
    isHidden?: boolean;
    tooltipText: string;
    onClick?: () => void;
}

export class ETIcon extends Component<ETIconProps> {

    constructor(props: ETIconProps) {
        super(props)

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    private handleOnClick() {
        if(this.props.onClick) {
            this.props.onClick()
        }
    }

    private getContainerCssClass() {
        return `et-icon ${this.props.isHidden ? " hide-class" : ""}`;
    }

    public render() {
        return <div ref={this.props.refObject} className={this.getContainerCssClass()} onClick={this.handleOnClick}>
            <img 
                id={this.props.id !== "" ? this.props.id : ""}
                src={this.props.image}
                alt={this.props.altText}
                width="16px"
                height="16px"
            />
            <div className={this.props.tooltipText !== "" ? "et-icon-tooltip-text" : ""}>
                {this.props.tooltipText}
            </div>
        </div>
    }
}