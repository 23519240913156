import { ChangeEvent, Component, RefObject } from 'react';
import DatePicker from 'react-datepicker';
import { SingleValue } from 'react-select';
import './invoiceForm.styles.css';
import InvoiceProduct from '../invoiceProduct/invoiceProduct.component';
import InvoiceFormController from './controller/invoiceForm.controller';
import { Utils } from '../../../../../lib/utils/utils';
import { connect } from 'react-redux';
import React from 'react';
import { ClientData, CurrencyCodes, DeliveryNote, InvoiceFormState, Product } from './types/invoiceForm.types';
import { ETDropdown } from '../../../../ETComponents/ETDropdown/etdropdown.component';
import { RootState } from '../../../../../redux/types';
import { setScaleReportsForInvoice } from '../../../../../redux/actions/application';
import { ScaleReport, UnitMeasureCodes } from '../../../../../lib/types';
import { Invoice, ProductType } from '../../types/types';
import { dispatchRequestErrorEvent } from '../../../../../lib/events/requestErrorEvent';
import { dispatchPopupEvent } from '../../../../../lib/events/popupEvent';

interface InvoiceFormProps {
    handleCancelInvoice: () => void;
    handleInvoiceCreated: () => void;
    handleUpdateInvoice: () => void;
    setScaleReportsForInvoice: (scaleReportsIds: Array<string>) => void;
    scaleReportsForInvoice?: Array<string>;
    invoiceToBeUpdated?: Invoice;
}

class InvoiceForm extends Component<InvoiceFormProps, InvoiceFormState> {
    private controller: InvoiceFormController = new InvoiceFormController();
    private vatCodeSelectRef: RefObject<ETDropdown>;

    private skipSelectorOptionChangedCallback = false;
    private preselectedScaleReportsIds: Array<string> = [];

    constructor(props: InvoiceFormProps) {
        super(props);

        this.state = this.getEmptyState();

        this.vatCodeSelectRef = React.createRef<ETDropdown>();

        // bind event handlers here
        this.handleInvoiceHeaderInputChanged = this.handleInvoiceHeaderInputChanged.bind(this);
        this.handleSelectChanged = this.handleSelectChanged.bind(this);
        this.handleInvoiceDateChanged = this.handleInvoiceDateChanged.bind(this);
        this.handleInvoiceDueDateChanged = this.handleInvoiceDueDateChanged.bind(this);
        this.handleProductAttributeChanged = this.handleProductAttributeChanged.bind(this);
        this.handleTotals = this.handleTotals.bind(this);
        this.handleAddProductClicked = this.handleAddProductClicked.bind(this);
        this.handleAddTransportClicked = this.handleAddTransportClicked.bind(this);
        this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
        this.handleCancelInvoiceClicked = this.handleCancelInvoiceClicked.bind(this);
        this.handleCreateInvoiceClicked = this.handleCreateInvoiceClicked.bind(this);
        this.handleUpdateInvoiceClicked = this.handleUpdateInvoiceClicked.bind(this);
        this.searchCompanyToAnafAndFillForm = this.searchCompanyToAnafAndFillForm.bind(this);
        this.handleQuantityChanged = this.handleQuantityChanged.bind(this);
    }

    private getEmptyState(): InvoiceFormState {
        const today = new Date(Date.now());
        const { year, month, day } = Utils.getDateElements(today);

        return {
            clientId: '',
            clientList: [],
            deliveryNotes: [],
            productList: [this.getEmptyProduct()],
            netAmount: 0,
            vatAmount: 0,
            totalAmount: 0,
            clientName: '',
            clientVatCode: '',
            clientAddress: '',
            clientDetailedAddress: {
                countyCode: '',
                postalCode: '',
                cityName: '',
                streetName: '',
            },
            clientIban: '',
            clientRegCode: '',
            clientVatPayer: false,
            date: `${year}-${month}-${day}`,
            dueDate: `${year}-${month}-${day}`,
            currency: CurrencyCodes.RON,
            number: '',
            series: '',
            createdWithScaleReports: false,
            finishedLoadingDataFromScaleReports: true
        };
    }

    private getEmptyProduct(): Product {
        return {
            name: '',
            unitPrice: '0',
            unitMeasure: UnitMeasureCodes.KG,
            quantity: '0',
            vatPercentage: 19,
            netAmount: 0,
            totalAmount: 0,
            type: ProductType.CUSTOM
        }
    }

    async componentDidMount() {
        await this.loadClientList();
        await this.loadInvoiceSeriesData();

        if (this.props.scaleReportsForInvoice && this.props.scaleReportsForInvoice.length) {
            this.preselectedScaleReportsIds = JSON.parse(JSON.stringify(this.props.scaleReportsForInvoice));

            this.setState({
                finishedLoadingDataFromScaleReports: false
            }, async () => {
                await this.loadInvoiceDataFromSelectedReports(this.props.scaleReportsForInvoice!);
                this.props.setScaleReportsForInvoice([]);
                this.setState({
                    createdWithScaleReports: true,
                    finishedLoadingDataFromScaleReports: true
                });
            });
        }

        if(this.props.invoiceToBeUpdated) {
            await this.loadInvoiceDataFromInvoiceToBeUpdated(this.props.invoiceToBeUpdated);
        }
    }

    private async loadInvoiceDataFromInvoiceToBeUpdated(invoice: Invoice) {

        let productList: Product[] = [];

        invoice.productList.forEach((product) => {
            productList.push({
                name: product.name,
                unitPrice: product.unitPrice,
                unitMeasure: this.stringToUnitMeasureCodes(product.unitMeasure),
                quantity: product.quantity,
                vatPercentage: product.vatPercentage,
                netAmount: product.netAmount,
                totalAmount: product.totalAmount,
                type: product.type
            });
        });

        let deliveryNotes: DeliveryNote[] = [];
        let createdWithScaleReports: boolean = false;

        invoice.deliveryNotes.forEach((deliveryNote) => {
            deliveryNotes.push({
                scaleReportId: deliveryNote.scaleReportId ,
                number: +deliveryNote.number,
                series: deliveryNote.series
            });
            createdWithScaleReports = true;
        });

        this.setState({
                clientId: invoice.invoiceHeaderInfo.clientData._id,
                productList: [],
                netAmount: invoice.details.netAmount,
                vatAmount: invoice.details.vatAmount,
                totalAmount: invoice.details.totalAmount,
                date: invoice.invoiceHeaderInfo.date,
                dueDate: invoice.invoiceHeaderInfo.dueDate,
                currency: CurrencyCodes.RON,
                number: invoice.invoiceHeaderInfo.number,
                series: invoice.invoiceHeaderInfo.series,
                deliveryNotes: deliveryNotes,
                createdWithScaleReports: createdWithScaleReports
        }, () => {this.reRenderProducts(productList)});

        this.selectOptionWithExistingClient(invoice.invoiceHeaderInfo.clientData.vatCode);
    }

    private stringToUnitMeasureCodes(input: string): UnitMeasureCodes {
        switch(input){
            case "TNE":
                return UnitMeasureCodes.TNE;
            case "KGM":
                return UnitMeasureCodes.KG;
            case "H87":
                return UnitMeasureCodes.BUC;
            case "MTQ":
                return UnitMeasureCodes.METRIC_CUBE;
            case "KMT":
                return UnitMeasureCodes.KILOMETER;
            default:
                return UnitMeasureCodes.TNE;
        }
    }

    private async loadInvoiceDataFromSelectedReports(scaleReportsIds: Array<string>) {
        let scaleReports: Array<ScaleReport> = [];

        try {
            scaleReports = await this.controller.getScaleReports(scaleReportsIds);
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }

        this.setState({
            finishedLoadingDataFromScaleReports: false
        })
        
        let clientFromReportsVatCode: string = '';
        const productsFromReportList: Array<Product> = [];

        const excludedReports: Array<ScaleReport> = [];

        let deliveryNotes: Array<DeliveryNote> = [];

        for (const scaleReport of scaleReports) {
            if (scaleReport.clientVatCode) {
                clientFromReportsVatCode = scaleReport.clientVatCode;
            }

            // we look for the scale report's product in the products array that will get set on invoice
            const productInNewList = productsFromReportList.filter(product => {
                // we currently only offer this feature for only 1 products reports
                const scaleReportProduct = scaleReport.products![0];

                const srProductName = scaleReportProduct.name;
                const srProductUnitPrice = (scaleReportProduct.eTransportPrice / scaleReportProduct.quantity.value).toFixed(2);
                const srProductUnitMeasure = scaleReportProduct.quantity.unitMeasureCode;

                return product.name === srProductName
                    && product.unitPrice === srProductUnitPrice 
                    && product.unitMeasure === srProductUnitMeasure
            })[0];

            if (!scaleReport.details.weightOut) {
                excludedReports.push(scaleReport);
                // we should not reach this point with incomplete reports
                // however, if somehow the report is missing weight out, we should skip it
                continue;
            }
            
            
            if (!productInNewList) {
                const scaleReportProduct = scaleReport.products![0];
                // if the product is not already in invoice products array, add it as new product
                const newProduct = this.getEmptyProduct();

                newProduct.name = scaleReport.productName;
                newProduct.quantity = scaleReportProduct.quantity.value.toFixed(2);
                newProduct.unitMeasure = scaleReportProduct.quantity.unitMeasureCode;
                newProduct.vatPercentage = 19;
                newProduct.type = ProductType.FROM_REPORTS;
                newProduct.unitPrice = (scaleReportProduct.eTransportPrice / scaleReportProduct.quantity.value).toFixed(2);

                // calculate totals
                const unitPriceFloat = parseFloat(newProduct.unitPrice);
                const unitQuantityFloat = parseFloat(newProduct.quantity);
                newProduct.netAmount = parseFloat((unitPriceFloat * unitQuantityFloat).toFixed(2));
                const vatAmount = parseFloat((newProduct.netAmount * (newProduct.vatPercentage / 100)).toFixed(2));
                newProduct.totalAmount = parseFloat((newProduct.netAmount + vatAmount).toFixed(2));

                productsFromReportList.push(newProduct);
            } else {
                // if the product is already in the invoice products array, increase quantity
                const oldQuantity = parseFloat(productInNewList.quantity);
                const productFromReportQuantity = scaleReport.products![0].quantity.value;
                productInNewList.quantity = (oldQuantity + productFromReportQuantity).toFixed(2);

                // update totals
                const unitPriceFloat = parseFloat(productInNewList.unitPrice);
                const unitQuantityFloat = parseFloat(productInNewList.quantity);
                productInNewList.netAmount = parseFloat((unitPriceFloat * unitQuantityFloat).toFixed(2));
                const vatAmount = parseFloat((productInNewList.netAmount * (productInNewList.vatPercentage / 100)).toFixed(2));
                productInNewList.totalAmount = parseFloat((productInNewList.netAmount + vatAmount).toFixed(2));
            }

            // retain info about the scale report and the delivery note number

            deliveryNotes.push({scaleReportId: scaleReport._id, number: scaleReport.details.attachDocumentNumber!, series: scaleReport.details.attachDocumentSeries!});
        }

        if (clientFromReportsVatCode.length) {
            // remove 'RO' from clientVatCode
            clientFromReportsVatCode = clientFromReportsVatCode.includes('RO') ? clientFromReportsVatCode.slice(2) : clientFromReportsVatCode;
        } else {
            dispatchPopupEvent('Eroare', 'CIF-ul clientului nu a fost identificat.');
            return;
        }

        const clientAlreadyFetched = this.state.clientList.filter(client => client.details.vatCode === clientFromReportsVatCode).length > 0;

        if (clientAlreadyFetched) {
            this.getClientFromStateAndFillForm(clientFromReportsVatCode);
            this.selectOptionWithExistingClient(clientFromReportsVatCode)
        } else {
            const result = await this.searchCompanyToAnafAndFillForm(clientFromReportsVatCode);

            if (result) {
                this.selectOptionWithNewClient(clientFromReportsVatCode, result.clientName);
            }
        }

        const productListCopy = JSON.parse(JSON.stringify(this.state.productList));
        const newList = productListCopy.concat(productsFromReportList);
        newList.shift();

        this.setState({
            productList: []
        }, () => this.setState({
            clientVatCode: clientFromReportsVatCode,
            productList: newList,
            deliveryNotes: deliveryNotes
        }));

        if (!excludedReports.length) {
            return;
        }

        const excludedReportsIds = excludedReports.map(report => report._id);
        this.preselectedScaleReportsIds = this.preselectedScaleReportsIds.filter(id => !excludedReportsIds.includes(id));
        this.informUserAboutExcludedReports(excludedReports);
    }

    private informUserAboutExcludedReports(excludedReports: Array<ScaleReport>) {
        const ticketNumbers = excludedReports.map(scaleReport => scaleReport.details.ticketNumber);

        dispatchPopupEvent(
            'Atentie', 
            `Rapoartele de cantarire cu urmatoarele numere de tichet de cantar: ${ticketNumbers.join(', ')} nu au fost incluse. ` + 
            'Acestea nu contin suficiente informatii pentru calcularea cantitatii nete.'
        );
    }

    private selectOptionWithNewClient(clientVatCode: string, clientName: string): void {
        if (this.vatCodeSelectRef.current) {
            // we don't want the callback to get called since on this path we have already handled everything
            this.skipSelectorOptionChangedCallback = true;
            (this.vatCodeSelectRef.current as any).setValue({
                value: clientVatCode,
                label: `${clientVatCode} - ${clientName}`
            });
        }
    }

    private selectOptionWithExistingClient(clientVatCode: string): void {
        const clientList = this.state.clientList;

        const associatedClientData =  clientList.filter(client => client.details.vatCode === clientVatCode)[0];

        if (!associatedClientData) {
            return;
        }

        if (this.vatCodeSelectRef.current) {
            (this.vatCodeSelectRef.current as any).setValue({
                value: clientVatCode, 
                label: `${associatedClientData.details.vatCode} - ${associatedClientData.name}`
            }); 
        }
    }

    private async loadClientList() {
        try {
            const clients = await this.controller.getAvailableClients();
            this.setState({ clientList: clients });
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }
    }

    private async loadInvoiceSeriesData() {
        try {
            const { code, number } = await this.controller.getInvoiceSeriesData();
            this.setState({
                series: code,
                number: JSON.stringify(number + 1)
            });
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
        }
    }

    private handleInvoiceDateChanged(date: Date) {
        const { year, month, day } = Utils.getDateElements(date);
        this.setState({ date: `${year}-${month}-${day}` })
    }

    private handleInvoiceDueDateChanged(date: Date) {
        const { year, month, day } = Utils.getDateElements(date);
        this.setState({ dueDate: `${year}-${month}-${day}` })
    }

    private handleProductAttributeChanged(attribute: string, value: number | string, index: number): void {
        const productListCopy = JSON.parse(JSON.stringify(this.state.productList));

        const modifiedProduct = productListCopy[index];
        if(attribute === "vatPercentage") {
            value = parseFloat(value as string);
        }
        if(attribute === "name") {
            if((modifiedProduct as Product).type === ProductType.FROM_REPORTS) {
                this.setState({ deliveryNotes: []});
                this.preselectedScaleReportsIds = [];
            }
        }
        modifiedProduct[attribute] = value;

        this.setState({ productList: productListCopy })
    }

    private handleTotals(totalAmmount: number, netAmmount: number, index: number): void {
        const productListCopy = JSON.parse(JSON.stringify(this.state.productList));

        const modifiedProduct = productListCopy[index] as Product;
        modifiedProduct.totalAmount = totalAmmount;
        modifiedProduct.netAmount = netAmmount;

        this.setState(
            { productList: productListCopy },
            this.computeInvoiceTotals
        )
    }

    private handleInvoiceHeaderInputChanged(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;

        // todo: find if it is correct to use 'as unknown'
        this.setState({ [name as keyof InvoiceFormState]: value } as unknown as Pick<InvoiceFormState, keyof InvoiceFormState>);
    }

    private async handleSelectChanged(newValue: SingleValue<{label: string, value: string, __isNew__?: boolean}> | any) {
        if (this.skipSelectorOptionChangedCallback) {
            // we only skip this callback when we fill the form automatically with data when accessing the page
            this.skipSelectorOptionChangedCallback = false;
            return;
        }
        
        // occurs when the user clears the C.I.F.select and we should clear all company data
        if (newValue === null) {
            this.setState({
                clientVatCode: '',
                clientName: '',
                clientRegCode: '',
                clientAddress: '',
                clientIban: ''
            });
            return;
        }

        if (newValue.__isNew__) {
            await this.searchCompanyToAnafAndFillForm(newValue.value);
        } else {
            this.getClientFromStateAndFillForm(newValue.value);
        }

        this.setState({clientVatCode: newValue.value});
    }

    private getClientFromStateAndFillForm(clientVatCode: string) {
        const selectedClient: ClientData = this.state.clientList.filter((client: ClientData) => client.details.vatCode === clientVatCode)[0];

        this.setState({
            clientName: selectedClient.name,
            clientAddress: selectedClient.details.address,
            clientIban: selectedClient.details.iban,
            clientRegCode: selectedClient.details.regComCode,
            clientVatPayer: selectedClient.details.vatPayer ? selectedClient.details.vatPayer : false
        });
    }

    private handleRemoveProduct(index: number, resetDeliveryNotes: boolean): void {
        if (this.state.productList.length <= 1) {
            // display pop-up informing the user he can not remove the last product
            return;
        }

        const productListCopy = JSON.parse(JSON.stringify(this.state.productList));
        productListCopy.splice(index, 1);

        // we need to first set productList empty so the DOM would reset
        // if we update the state directly with the new list, the DOM won't get re-rendered 
        // React would see that there is still an array, but with oldLength-1 elements, so it will cut the last element from the DOM
        // even if we remove from the beginning / inside of the array
        this.setState({
            productList: [],
            deliveryNotes: resetDeliveryNotes ? [] : this.state.deliveryNotes
        }, () => this.reRenderProducts(productListCopy));

        if(resetDeliveryNotes) {
            this.preselectedScaleReportsIds = [];
        }
    }

    private reRenderProducts(productList: Array<Product>): void {
        this.setState(
            { productList: productList },
            this.computeInvoiceTotals
        )
    }

    private computeInvoiceTotals(): void {
        const { totalAmount, vatAmount, netAmount } = this.controller.computeTotals(this.state.productList);
        this.setState({ totalAmount, vatAmount, netAmount });
    }

    private handleAddProductClicked(): void {
        const productsCopy = JSON.parse(JSON.stringify(this.state.productList));
        productsCopy.push(this.getEmptyProduct());
        this.setState({
            productList: productsCopy,
            deliveryNotes: []
        });
        this.preselectedScaleReportsIds = [];
    }

    private handleAddTransportClicked(): void {
        const productsCopy = JSON.parse(JSON.stringify(this.state.productList));

        const transport = this.getEmptyProduct();
        transport.name = "Transport";
        transport.type = ProductType.TRANSPORT;
        transport.unitMeasure = UnitMeasureCodes.KILOMETER;

        productsCopy.push(transport);

        this.setState({
            productList: productsCopy
        });
    }

    private async companyHasDetailedAddressSet(): Promise<boolean> {
        const companyData = await this.controller.getCompanyData();

        if (!companyData.generalCompanyData || !companyData.generalCompanyData.detailedAddress) {
            return false;
        }

        return !!companyData.generalCompanyData.detailedAddress.cityName
            && !!companyData.generalCompanyData.detailedAddress.countyCode
            && !!companyData.generalCompanyData.detailedAddress.postalCode
            && !!companyData.generalCompanyData.detailedAddress.streetName
            && !!companyData.generalCompanyData.regComCode
            && !!companyData.generalCompanyData.socialAddress

        
    }

    private async handleCreateInvoiceClicked(): Promise<void> {
        if (!await this.companyHasDetailedAddressSet()) {
            dispatchPopupEvent(
                'Atentie',
                'Nu au fost identificate toate informatiile necesare pentru emiterea facturilor.'
                +'\n\n'
                +'Este necesar sa completati informatiile din profilul companiei cu date aduse direct de la ANAF.'
                +'\nPentru aceasta, accesati pagina \'Profil companie\' dand click pe numele companiei din meniu, si apasati pe lupa afisata in dreptul codului fiscal.'
            );
            return;
        }

        if (!this.state.clientName || !this.state.clientAddress || !this.state.clientRegCode || !this.state.clientIban || !this.state.clientVatCode) {
            dispatchPopupEvent('Atentie', 'Va rugam completati toate campurile.');
            return;
        }

        const negativeProductsPrices = this.state.productList.filter(product => parseFloat(product.unitPrice) < 0);
        if (negativeProductsPrices.length) {
            dispatchPopupEvent('Eroare', 'Nu puteti folosi valori negative pentru pretul produselor. Pentru stornare, va rugam setati cantitatea cu valori negative.');
            return;
        }

        try {
            await this.controller.createInvoice(this.state, this.preselectedScaleReportsIds);
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }

        if (this.vatCodeSelectRef.current) {
            (this.vatCodeSelectRef.current as any).clearValue();
        }

        dispatchPopupEvent('Success', 'Factura a fost creata cu succes.');

        // empty form and reload clientList
        this.setState({ productList: [] }, () => this.setState(this.getEmptyState));
        await this.loadClientList();
        await this.loadInvoiceSeriesData();

        this.props.handleInvoiceCreated();
    }

    private async handleUpdateInvoiceClicked(): Promise<void> {

        const negativeProductsPrices = this.state.productList.filter(product => parseFloat(product.unitPrice) < 0);
        if (negativeProductsPrices.length) {
            dispatchPopupEvent('Eroare', 'Nu puteti folosi valori negative pentru pretul produselor. Pentru stornare, va rugam setati cantitatea cu valori negative.');
            return;
        }

        try {
            await this.controller.updateInvoice(this.state, this.props.invoiceToBeUpdated!._id);
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }

        if (this.vatCodeSelectRef.current) {
            (this.vatCodeSelectRef.current as any).clearValue();
        }

        dispatchPopupEvent('Success', 'Factura a fost modificata cu succes.');

        // empty form and reload clientList
        this.setState({ productList: [] }, () => this.setState(this.getEmptyState));

        this.props.handleUpdateInvoice();
    }

    private handleCancelInvoiceClicked(): void {
        this.props.handleCancelInvoice();
    }
    
    private async searchCompanyToAnafAndFillForm(companyVatInput: string): Promise<{clientName: string} | undefined> {
        // the request to anaf API will not work with RO in vat code; if inserted, we should remove it;
        const companyVatCode = companyVatInput.replace('RO','');

        // todo: add validation here

        try {
            const companyData = await Utils.getCompanyDataFromAnaf(companyVatCode);

            if (!companyData) {
                dispatchPopupEvent('Sigur ai scris corect?', `Nu a fost gasita in baza de date ANAF nicio societate juridica cu C.I.F.: ${companyVatInput}.`);
                return;
            }

            const { adresa, denumire, iban, nrRegCom } = companyData.date_generale;
            const { scod_JudetAuto, scod_Judet, scod_Localitate, scod_Postal, sdenumire_Localitate, sdenumire_Strada } = companyData.adresa_sediu_social;
            const { scpTVA } = companyData.inregistrare_scop_Tva;

            // 40 is the code for Bucharest
            // if the company is from Bucharest, the city should be: SECTOR1, SECTOR2, ..., SECTOR6
            const cityName = scod_Judet === '40'
                ? `SECTOR${scod_Localitate}`
                : sdenumire_Localitate;

            const clientDetailedAddress = {
                countyCode: scod_JudetAuto,
                postalCode: scod_Postal,
                cityName,
                streetName: sdenumire_Strada,
            };

            this.setState({
                clientName: denumire,
                clientAddress: adresa,
                clientIban: iban,
                clientRegCode: nrRegCom,
                clientDetailedAddress: clientDetailedAddress,
                clientVatPayer: scpTVA
            });

            return {
                clientName: denumire 
            }

        } catch (err: any) {
            dispatchPopupEvent('Eroare', err.message);
        }
    }

    private getSelectOptions() {
        const clientList = this.state.clientList;

        if (!clientList.length) {
            return [];
        }

        return clientList.map(
            (client: ClientData) => { 
                const vatCode = client.details.vatCode;
                const name = client.name;
                return {
                    value: vatCode,
                    label: `${vatCode} - ${name}` 
                };
            }
        )
    }

    private handleQuantityChanged(productName: string, quantity: string) {
        let product = this.state.productList.find(product => product.name === productName);

        if(!product) {
            return;
        }

        if(product.type === ProductType.FROM_REPORTS) {
            const newQuantity = parseFloat(quantity);
            const oldQuantity = parseFloat(product.quantity);

            if(newQuantity !== oldQuantity && this.state.finishedLoadingDataFromScaleReports) {
                // we destroy the link to the scale reports only on products from reports whose quantity changes
                this.setState({ deliveryNotes: []});
                this.preselectedScaleReportsIds = [];
            }
        }
    }

    render() {
        const shouldRenderTransportButton = this.state.productList.filter((product) => product.type === ProductType.TRANSPORT).length === 0

        return (
            <div id='show-form-container' className='et-section'>
                <div id='invoice-header-data-container-1'>
                    <div id='invoice-client-name-container' className='invoice-header-data'>
                        <label id='client-name-label' className='invoice-header-label'>Client:</label>
                        <input
                            id='client-name-input'
                            className='et-form-input invoice-text-input'
                            value={this.state.clientName}
                            required
                            name='clientName'
                            onChange={this.handleInvoiceHeaderInputChanged}
                            disabled={!!this.props.invoiceToBeUpdated}
                        />
                    </div>
                    <div id='invoice-client-tax-code-container' className='invoice-header-data'>
                        <label id='client-tax-code-label' className='invoice-header-label'>C.I.F:</label>
                        <ETDropdown
                            id='client-tax-code-input'
                            selectRef={this.vatCodeSelectRef}
                            selectOptions={this.getSelectOptions()}
                            name='clientVatCode'
                            isClearable={true}
                            isMulti={false}
                            createLabelStartText="Adauga '"
                            createLabelEndText="'"
                            placeholder='Selecteaza companie...'
                            handleSelectChanged={this.handleSelectChanged}
                            handleNoOptionsMessage={() => 'Nu s-a gasit niciun client. Tastati CIF-ul pentru a adauga un client nou.'}
                            disabled={!!this.props.invoiceToBeUpdated}
                            creatable={true}
                        />
                    </div>
                    <div id='invoice-date-container' className='invoice-header-data'>
                        <label id='date-label' className='invoice-header-label'>Data:</label>
                        <DatePicker 
                            selected={new Date(this.state.date)} 
                            onChange={(date: Date) => this.handleInvoiceDateChanged(date)}
                            dateFormat="dd/MM/yyyy"
                            name='date'
                        />
                    </div>
                    <div id='invoice-due-date-container' className='invoice-header-data'>
                        <label id='due-date-label' className='invoice-header-label'>Data scadenta:</label>
                        <DatePicker
                            selected={new Date(this.state.dueDate)}
                            onChange={(date: Date) => this.handleInvoiceDueDateChanged(date)}
                            dateFormat="dd/MM/yyyy"
                            name='dueDate'
                        />
                    </div>
                    <div id='invoice-currency-container' className='invoice-header-data'>
                        <label id='currency-label' className='invoice-header-label'>Moneda:</label>
                        <select 
                            className='et-form-selector invoice-selector'
                            name='currency'
                            value={this.state.currency}
                            onChange={this.handleInvoiceHeaderInputChanged}
                        >
                            <option value={CurrencyCodes.RON}>RON</option>
                        </select>
                    </div>
                    <div id='invoice-number-container' className='invoice-header-data'>
                        <label id='number-label' className='invoice-header-label'>Numar:</label>
                        <input
                            id='number-input'
                            disabled
                            className='et-form-input invoice-text-input'
                            value={this.state.number}
                            required
                            name='number'
                            onChange={this.handleInvoiceHeaderInputChanged}
                        />
                    </div>
                    <div id='invoice-series-container' className='invoice-header-data'>
                        <label id='series-label' className='invoice-header-label'>Serie:</label>
                        <input
                            id='series-input'
                            disabled
                            className='et-form-input invoice-text-input'
                            value={this.state.series}
                            required
                            name='series'
                            onChange={this.handleInvoiceHeaderInputChanged}
                        />
                    </div>
                </div>
                <div id='invoice-header-data-container-2'>
                    <div id='invoice-client-reg-code-container' className='invoice-header-data'>
                        <label id='client-reg-code-label' className='invoice-header-label'>Nr. Reg. Com.:</label>
                        <input
                            id='client-reg-code-input'
                            className='et-form-input invoice-text-input'
                            value={this.state.clientRegCode}
                            required
                            name='clientRegCode'
                            onChange={this.handleInvoiceHeaderInputChanged}
                            disabled={!!this.props.invoiceToBeUpdated}
                        />
                    </div>
                    <div id='invoice-client-address-container' className='invoice-header-data'>
                        <label id='client-address-label' className='invoice-header-label'>Adresa:</label>
                        <input
                            id='client-address-input'
                            className='et-form-input invoice-text-input'
                            value={this.state.clientAddress}
                            required
                            name='clientAddress'
                            onChange={this.handleInvoiceHeaderInputChanged}
                            disabled={!!this.props.invoiceToBeUpdated}
                        />
                    </div>
                    <div id='invoice-client-iban-container' className='invoice-header-data'>
                        <label id='client-iban-label' className='invoice-header-label'>IBAN:</label>
                        <input
                            id='client-iban-input'
                            className='et-form-input invoice-text-input'
                            value={this.state.clientIban}
                            required
                            name='clientIban'
                            onChange={this.handleInvoiceHeaderInputChanged}
                            disabled={!!this.props.invoiceToBeUpdated}
                        />
                    </div>
                </div>
                <div id='invoice-products-container'>
                    {this.state.productList.map((product: Product, index) => (
                        <InvoiceProduct
                            key={`product_${index}`}
                            name={product.name}
                            unitMeasure={product.unitMeasure}
                            unitPrice={product.unitPrice}
                            quantity={product.quantity}
                            vatPercentage={product.vatPercentage}
                            netAmount={product.netAmount}
                            totalAmount={product.totalAmount}
                            type={product.type}
                            index={index}
                            handleAttributeChanged={this.handleProductAttributeChanged}
                            handleTotals={this.handleTotals}
                            handleRemoveProduct={this.handleRemoveProduct}
                            handleQuantityChanged={this.handleQuantityChanged}
                            createdWithScaleReports={this.state.createdWithScaleReports}
                        />
                    ))}
                </div>
                <div id='footer-1'>
                    <div id='add-product-container' className='footer-1-child'>
                        <input 
                            id='create-product-button'
                            className='et-button'
                            type='submit'
                            value='Adauga produs'
                            onClick={this.handleAddProductClicked}
                        ></input>
                        <span className="red-text">{this.state.createdWithScaleReports ? <> *</> : <></>}</span>
                    </div>
                    {shouldRenderTransportButton ? 
                        <div id='add-transport-container' className='footer-1-child'>
                            <input 
                                id='create-transport-button'
                                className='et-button'
                                type='submit'
                                value='Adauga transport'
                                onClick={this.handleAddTransportClicked}
                            ></input>
                    </div>
                    : <></>}
                    <div id='totals-container' className='footer-1-child'>
                        <span className='total-span'> Total fara TVA: {this.state.netAmount} RON </span>
                        <span className='total-span'> TVA: {this.state.vatAmount} RON </span>
                        <span className='total-span'> Total: {this.state.totalAmount} RON </span>
                    </div>
                </div>
                {this.state.createdWithScaleReports ? 
                    <div>
                        <p className="red-text">* Adaugarea, eliminarea sau modificarea cantitatii sau a numelor produselor va duce la decuplarea facturii de rapoartele de cantarire selectate anterior</p>
                    </div>
                :
                    <></>
                }
                <div id='footer-2'>
                    <input 
                        id='create-invoice-button'
                        className='et-button'
                        type='submit'
                        value={this.props.invoiceToBeUpdated ? 'Modifica factura' : 'Salveaza factura'}
                        onClick={this.props.invoiceToBeUpdated ? this.handleUpdateInvoiceClicked : this.handleCreateInvoiceClicked}
                    ></input>
                    <input 
                        id='cancel-invoice-button'
                        className='et-button-danger'
                        type='submit'
                        value='Renunta'
                        onClick={this.handleCancelInvoiceClicked}
                    ></input>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setScaleReportsForInvoice: (scaleReportsIds: Array<string>) => dispatch(setScaleReportsForInvoice(scaleReportsIds))
    }
}

const mapStateToProps = (rootState: RootState) => {
    return {
        scaleReportsForInvoice: rootState.application.scaleReportsForInvoice
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);