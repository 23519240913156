import { PopupButtonTypes } from "../components/ETComponents/ETPopup/constants";
import { RootState } from "../redux/types";

class StateLoader {

    loadState(): RootState {
        try {
            let serializedState = localStorage.getItem("state");

            if (serializedState === null) {
                return this.initializeState();
            }

            return JSON.parse(serializedState);
        }
        catch (err) {
            return this.initializeState();
        }
    }

    saveState(state: RootState) {
        try {
            let serializedState = JSON.stringify(state);
            localStorage.setItem("state", serializedState);
        }
        catch (err) {
        }
    }

    initializeState(): RootState {
        return {
            alerts: [],
            auth: {
                sessionId: localStorage.getItem('sessionId'),
                isAuthenticated: false,
                loading: true,
                user: {
                    id: '',
                    name: '',
                    email: '',
                    role: '',
                    phone: '',
                    jobTitle: ''
                },
                company: {
                    id: '',
                    name: '',
                    features: []
                }
            },
            application: {
                activeModule: '',
                scaleReportsForInvoice: [],
            },
            popup: {
                text: '',
                title: '',
                buttonsType: PopupButtonTypes.OK,
                visible: false,
                shouldLogoutUser: false
            }
        }
    };
}

export default StateLoader;