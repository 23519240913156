import { TimeRange } from "./etTimeRangeFilter.types";

export default class TimeRangeFilterHelper {
    static computeTimeRangeForTodayOption(): TimeRange {
        const midnightUTCDate = new Date();
        midnightUTCDate.setHours(0, 0, 0, 0);
        const midnightUTCTimestamp = midnightUTCDate.getTime();

        return {
            startInterval: midnightUTCTimestamp,
            endInterval: Date.now()
        }
    }

    static computeTimeRangeForLast24HoursOption(): TimeRange {
        return {
            startInterval: Date.now() - (24 * 60 * 60 * 1000),
            endInterval: Date.now()
        }
    }

    static computeTimeRangeForLast7DaysOption(): TimeRange {
        const sevenDaysAgoTimestamp = Date.now() - (7 * (24 * 60 * 60 * 1000));
        const dateSevenDaysAgo = new Date(sevenDaysAgoTimestamp);
        // set midnight hours
        dateSevenDaysAgo.setHours(0, 0, 0, 0);

        return {
            startInterval: dateSevenDaysAgo.getTime(),
            endInterval: Date.now()
        }
    }

    static computeTimeRangeForCustomDateOption(startDate: Date, endDate: Date): TimeRange {      
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        
        return {
            startInterval: startDate.getTime(),
            endInterval: endDate.getTime()
        }
    }
}