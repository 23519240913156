import ServerRequest from "../../../../lib/serverRequests/ServerRequest";
import { Company } from "../../../../lib/types";
import AdminPanelDataProvider from "../dataProvider/adminPanel.dataProvider";
import { LicenseKey } from "../types/adminPanel.types";

export class AdminPanelController {
    private dataProvider: AdminPanelDataProvider = new AdminPanelDataProvider();

    public async createUser(name: string, email: string, password: string, userRole: string, companyName: string, companyId: string): Promise<void> {
        return await ServerRequest.createUser({ name, email, password, userRole, companyName, companyId });
    }

    public async generateToken(companyName: string): Promise<void> {
        return await ServerRequest.generateToken({ companyName: companyName});
    }

    public async getExistingCompanies(): Promise<Array<Company>> {
        return await ServerRequest.getExistingCompanies();
    }

    public async getLicenseKeys(): Promise<Array<LicenseKey>> {
        return await this.dataProvider.getLicenseKeys();
    }

    public async generateNewLicenseKey(licenseKeyValabilityInMonths: number): Promise<LicenseKey> {
        return await this.dataProvider.generateNewLicenseKey(licenseKeyValabilityInMonths);
    }
}