export enum TokenType {
    E_TRANSPORT = 'eTransport',
    E_FACTURA = 'eFactura'
}

export interface TokensData {
    eTransportAccessToken: string;
    shouldAutomaticallyRefreshETransportToken: boolean;
    eFacturaAccessToken: string;
    shouldAutomaticallyRefreshEFacturaToken: boolean;
}