import { AlertsActionTypes } from '../actions/types';
import { AlertsAction, AlertsState, IAlert } from '../types';



const initialState: AlertsState = [];

export default function alerts(state: AlertsState = initialState, action: AlertsAction) {
	const { type, payload } = action;

	switch (type) {
		case AlertsActionTypes.SET_ALERT:
			return [...state, payload];
		case AlertsActionTypes.REMOVE_ALERT:
			return state.filter((alert: IAlert) => alert.id !== payload.id);
		default:
			return state;
	}
}
