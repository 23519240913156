import { PaymentData } from "../../../types/types";
import InvoicePaymentProvider from "../dataProvider/invoicePayment.dataProvider";
import { InvoicePaymentState, PaymentSeriesData } from "../types/invoicePayment.types";

export default class InvoicePaymentController {
    private dataProvider: InvoicePaymentProvider = new InvoicePaymentProvider();

    public async getInvoicePayment(paymentId: string): Promise<PaymentData> {
        return this.dataProvider.getInvoicePayment(paymentId);
    }

    public async createInvoicePayment(invoicePaymentData: InvoicePaymentState, invoiceId: string): Promise<string> {
        return this.dataProvider.createInvoicePayment(invoicePaymentData, invoiceId);
    }

    public async deleteInvoicePayment(invoicePaymentData: InvoicePaymentState, invoiceId: string): Promise<void> {
        this.dataProvider.deleteInvoicePayment(invoicePaymentData, invoiceId);
    }

    public async getPaymentSeriesData(): Promise<PaymentSeriesData> {
        const paymentSeriesData = await this.dataProvider.getPaymentSeriesData();

        return paymentSeriesData;
    }
}