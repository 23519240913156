export interface RequestConfig {
    headers: {
        [key: string]: string | null;
    },
    params?: any;
}

export class RequestError extends Error {

    public statusCode: number;

    constructor(msg: string, statusCode: number) {
        super(msg);
        Object.setPrototypeOf(this, RequestError.prototype);
        this.statusCode = statusCode;
    }
}