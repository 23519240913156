import React, { Component } from 'react';
import DatePicker from "react-datepicker";

import { TimeRange, TimeRangeFilterOptions, TimeRangeFilterProps, TimeRangeFilterState } from './etTimeRangeFilter.types';

import "react-datepicker/dist/react-datepicker.css";
import "./etTimeRangeFilter.style.css";
import TimeRangeFilterHelper from './etTimeRangeFilter.helper';

class EtTimeRangeFilter extends Component<TimeRangeFilterProps, TimeRangeFilterState> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedOption: TimeRangeFilterOptions.TODAY,
            disabledCustomOption: true,
            datePickerStartDate: new Date(Date.now()),
            datePickerEndDate: new Date(Date.now())
        }

        this.handleTimeRangeOptionChange = this.handleTimeRangeOptionChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    componentDidMount() {
        this.computeSelectedTimeRangeAndSendToParent();
    }

    private computeSelectedTimeRangeAndSendToParent(): void {
        let selectedTimeRange: TimeRange;
        switch (this.state.selectedOption) {
            case TimeRangeFilterOptions.TODAY:
                selectedTimeRange = TimeRangeFilterHelper.computeTimeRangeForTodayOption();
                break;
            case TimeRangeFilterOptions.LAST_24_HOURS:
                selectedTimeRange = TimeRangeFilterHelper.computeTimeRangeForLast24HoursOption();
                break;
            case TimeRangeFilterOptions.LAST_7_DAYS:
                selectedTimeRange = TimeRangeFilterHelper.computeTimeRangeForLast7DaysOption();
                break;
            case TimeRangeFilterOptions.CUSTOM_DATE:
                const { datePickerStartDate, datePickerEndDate } = this.state;
                selectedTimeRange = TimeRangeFilterHelper.computeTimeRangeForCustomDateOption(datePickerStartDate, datePickerEndDate);
                break;
            default:
                selectedTimeRange = TimeRangeFilterHelper.computeTimeRangeForTodayOption();
                break;
        }

        this.props.onChange(selectedTimeRange);
    }

    handleTimeRangeOptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newOption = event.target.value as TimeRangeFilterOptions;

        this.setState(
            {   
                selectedOption: newOption,
                disabledCustomOption: newOption !== TimeRangeFilterOptions.CUSTOM_DATE
            },
            this.computeSelectedTimeRangeAndSendToParent
        );
    };

    handleStartDateChange(date: Date) {
        this.setState(
            { datePickerStartDate: new Date(date) },
            this.computeSelectedTimeRangeAndSendToParent
        );
    }

    handleEndDateChange(date: Date) {
        this.setState(
            { datePickerEndDate: new Date(date) },
            this.computeSelectedTimeRangeAndSendToParent
        );
    }

    render() {
        const { selectedOption } = this.state; 
        return (
            <div id='timerange-filter'>
                <input
                    className='timerange-radio-input'
                    type="radio"
                    name="time-range-radio-group"
                    value={TimeRangeFilterOptions.TODAY}
                    checked={selectedOption === TimeRangeFilterOptions.TODAY}
                    onChange={this.handleTimeRangeOptionChange}
                />
                <label> Astazi </label>

                <input
                    className='timerange-radio-input'
                    type="radio"
                    name="time-range-radio-group"
                    value={TimeRangeFilterOptions.LAST_24_HOURS}
                    checked={selectedOption === TimeRangeFilterOptions.LAST_24_HOURS}
                    onChange={this.handleTimeRangeOptionChange}
                />
                <label> Ultimele 24 de ore </label>

                <input
                    className='timerange-radio-input'
                    type="radio"
                    name="time-range-radio-group"
                    value={TimeRangeFilterOptions.LAST_7_DAYS}
                    checked={selectedOption === TimeRangeFilterOptions.LAST_7_DAYS}
                    onChange={this.handleTimeRangeOptionChange}
                />
                <label> Ultimele 7 zile </label>
                
                <input
                    className='timerange-radio-input'
                    type="radio"
                    name="time-range-radio-group"
                    value={TimeRangeFilterOptions.CUSTOM_DATE}
                    checked={selectedOption === TimeRangeFilterOptions.CUSTOM_DATE}
                    onChange={this.handleTimeRangeOptionChange}
                />
                <div id='datepicker-container' className={this.state.disabledCustomOption ? 'disabled-div' : ''}>
                    <span>De la: </span>
                    <div id='align-start-date-picker'>
                        <DatePicker 
                            icon="fa fa-calendar"
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.datePickerStartDate}
                            onChange={(date: Date) => this.handleStartDateChange(date)} 
                        />
                    </div>
                    <span> Pana la: </span>
                    <DatePicker
                        icon="fa fa-calendar"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.datePickerEndDate}
                        onChange={(date: Date) => this.handleEndDateChange(date)}
                    />
                </div>
            </div>
        )
    }
}

export default EtTimeRangeFilter;