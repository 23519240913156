import { ChangeEvent, Component } from "react";
import './etfileuploadform.style.css'
import deleteImg from '../../../images/x-mark.png';
import reloadGif from '../../../images/reload-gif.gif';

export enum ETFileUploadFormats {
    IMAGES = "image/png, image/jpeg"
}

export interface ETFileUploadFormProps {
    allowMultipleFiles: boolean;
    acceptedFormats: ETFileUploadFormats;
    previewData: any;
    previewDataType: string;
    isLoading: boolean;
    onChange: (fileData: string | ArrayBuffer | null, fileType: string, errorMessage: string) => void;
    deleteUpload: () => void;
}

export class ETFileUploadForm extends Component<ETFileUploadFormProps> {
    
    constructor(props: ETFileUploadFormProps) {
        super(props)

        this.parseFile = this.parseFile.bind(this);
        this.deleteButtonClicked = this.deleteButtonClicked.bind(this);
    }

    private parseFile(event: ChangeEvent<HTMLInputElement>) {
        const { data, errorMessage } = this.parseFileList(event.target.files);

        if(data === null) {
            this.props.onChange('', '', errorMessage);
            return;
        }

        const file = data[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
            this.props.onChange(reader.result, file.type, errorMessage);
        };

        // TODO
        // multipleFiles not yet supported
    }

    private parseFileList(data: FileList | null): {data: FileList | null, errorMessage: string} {
        if(data === null) {
            return {
                data,
                errorMessage: "File does not exist"
            };
        }

        if(!this.props.allowMultipleFiles) {
            if(!data[0]) {
                return {
                    data: null,
                    errorMessage: "Fisierul nu exista."
                };
            }

            if(data[0].size > 1024 * 512) {
                return {
                    data: null,
                    errorMessage: "Fisierul este prea mare (> 0.5 MB)."
                };
            }

            return {
                data,
                errorMessage: ""
            };
        }

        return {
            data: null,
            errorMessage: "Multifile is not yet supported"
        };
    }

    private deleteButtonClicked() {
        this.props.deleteUpload();
    }

    public render() {
        const previewImageBuffer = this.props.previewData;
        const shouldShowPreview = previewImageBuffer !== null;
        const shouldShowLoadingState = this.props.isLoading;

        return <>
            <div className="et-file-upload-form">
                <input className="et-file-upload" type="file" id="fileUpload" name="file" accept={this.props.acceptedFormats} multiple={this.props.allowMultipleFiles} onChange={this.parseFile} disabled={shouldShowLoadingState}/>
                
                <label htmlFor="file">
                    <span className="et-drop-file"> {shouldShowPreview ? "" : "Upload file"} </span>
                </label>

                <span className="file-loading-state">
                    {
                        shouldShowLoadingState ?
                        <div className="file-loading-gif-container">
                            <img src={reloadGif} className="file-loading-gif" alt=""/>
                        </div>
                        :
                        <>
                        </>
                    }
                </span>

                <span className="file-preview">
                    {
                        shouldShowPreview ?
                        <img src={`data:${this.props.previewDataType};base64, ${previewImageBuffer}`} alt=""/>
                        :
                        <>
                        </>
                    }
                </span>
                {
                    shouldShowPreview ? 
                    <button className="file-button-delete" onClick={this.deleteButtonClicked}>
                        <img id='image-delete-icon' className='image-delete-icon' src={deleteImg} alt='delete'/>
                    </button>
                    :
                    <></>
                }
            </div>
        </>
    }
}