import { RequestError } from "../../../../lib/serverRequests/ServerRequest";
import { TimeRangeFilter } from "../../scaleReportsPage/types/types";
import InvoicePageDataProvider from "../dataProvider/invoicePage.dataProvider";
import { GetInvoicesResponse, RequestControllerResponse } from "../types/types";

export class InvoicePageController {
    private dataProvider: InvoicePageDataProvider = new InvoicePageDataProvider();

    public async getInvoices(timeRangeFilter: TimeRangeFilter): Promise<GetInvoicesResponse> {
        let result: GetInvoicesResponse = {
            valid: true
        }

        const allFilters: Array<TimeRangeFilter> = [timeRangeFilter];

        try {
            result.invoices = await this.dataProvider.getInvoices(allFilters);
        } catch (err: RequestError | any) {
            result = InvoicePageController.buildErrorResult(result, err)
            return result;
        }
        
        return result;
    }

    static buildErrorResult = (result: RequestControllerResponse, err: RequestError | any) => {
        result.valid = false;
        result.errorCode = (err as RequestError).statusCode;
        result.errorMessage = (err as RequestError).message;
        return result;
    }
}