import { BaseDataProvider } from "../../../../lib/baseDataProvider/base.dataProvider";
import { EvtLicenseKeyType, LicenseKey } from "../types/adminPanel.types";

export default class AdminPanelDataProvider extends BaseDataProvider {

    public async getLicenseKeys(): Promise<Array<LicenseKey>> {
        const requestResult = await this.launchGetRequest<Array<LicenseKey>>(`api/licenseKey/`);
        return requestResult.data;
    }

    public async generateNewLicenseKey(licenseKeyValabilityInMonths: number): Promise<LicenseKey> {
        const requestResult = await this.launchPostRequest(`api/licenseKey/generateLicenseKey`, {
            valabilityInMonths: licenseKeyValabilityInMonths,
            licenseType: EvtLicenseKeyType.PREMIUM
        });

        return requestResult!.data as LicenseKey;
    }
}