import { ScaleReport } from "../../types";
import { translateStatus } from "../../utils/translate";
import { Utils } from "../../utils/utils";
import { BaseCsvBuilder }  from "../baseCsvBuilder";

export class ScaleReportsCsvBuilder extends BaseCsvBuilder<ScaleReport> {
    protected computeContentString(): string {
        var csvData = "";

        this.headers.forEach( (header) => {
            csvData += header + ","
        });

        const workPointsIdToNameMap = this.additionalInfo.workPoints as Map<string, string>;

        csvData += "\n\r";

        this.elements.forEach( (element) => {
            csvData += element.details.licencePlateTruck + ",";
            csvData += element.details.licencePlateTrail + ",";
            csvData += element.details.driverName + ",";
            csvData += element.details.ticketNumber + ",";

            const dateIn = new Date(element.details.timestampIn);
            let { month: monthIn, day: dayIn, hour: hourIn, minute: minuteIn } = Utils.getDateElements(dateIn);
            csvData += `${dayIn} ${monthIn} ${hourIn}:${minuteIn},`;

            if(element.details.timestampOut)
            {
                const dateOut = new Date(element.details.timestampOut);
                let { month: monthOut, day: dayOut, hour: hourOut, minute: minuteOut } = Utils.getDateElements(dateOut);
                csvData += `${dayOut} ${monthOut} ${hourOut}:${minuteOut},`;
            }
            else
            {
                csvData += `-,`;
            }

            csvData += element.details.weightIn + ",";
            csvData += element.details.weightOut + ",";

            var netWeight = (element.status === 'Complete' && element.details.weightOut && element.details.weightIn) ? Math.abs(element.details.weightIn - element.details.weightOut) : 'N/A';

            csvData += netWeight === 'N/A' ? "-,": netWeight + " kg,";

            csvData += element.supplierName + ",";
            csvData += element.clientName + ",";
            csvData += (element.details.transporter ? element.details.transporter : "-") + " ,";
            csvData += element.productName + ",";
            csvData += (element.details.attachDocumentType !== "" ? element.details.attachDocumentType : "-") + ",";
            csvData += (("" + element.details.attachDocumentNumber) !== "" ? element.details.attachDocumentNumber : "-") + ",";
            csvData += element.details.operatorName + ",";
            csvData += (element.details.uitCode ? element.details.uitCode : "-") + " ,";
            csvData += (element.details.unloadingPlace ? "\"" + element.details.unloadingPlace + "\"" : "-") + ",";
            csvData += translateStatus(element.status) + ",";
            const workPointName = workPointsIdToNameMap.get(element.endpointId) ?? '-';
            csvData += workPointName + ",";

            csvData += "\r\n";
        });

        csvData += "\r\nTotal:," + this.additionalInfo.totalWeight + " kg, ( " + (this.additionalInfo.totalWeight / 1000).toFixed(2) + " t )";

        return csvData;
    }
}

