import { BaseDataProvider } from "../../../../lib/baseDataProvider/base.dataProvider";
import { FilterForBackend } from "../../scaleReportsPage/types/types";
import { ConveyorReport, TimeRangeFilter } from "../types/types";

export default class ConveyorReportsPageDataProvider extends BaseDataProvider {

    public async getConveyorReports(timeRangeFilter: Array<FilterForBackend | TimeRangeFilter | undefined>): Promise<ConveyorReport[]> {
        const result = await this.launchGetRequest<ConveyorReport[]>('api/agricostPackages/conveyorData', null, timeRangeFilter);
        return result.data;
    }
}