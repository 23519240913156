import PdfMake from "pdfmake/build/pdfmake";
import PdfFonts from "pdfmake/build/vfs_fonts";
import { BasePdfBuilder } from "../basePdfBuilder";
import { Content } from "pdfmake/interfaces";
import { InvoicePaymentState } from "../../../components/layout/invoicePage/innerComponents/invoicePayment/types/invoicePayment.types";
import store from "../../../redux/store";
import { parseNumbersToRoWords } from "../../utils/numberToRoWords";

export class ReceiptPdfBuilder extends BasePdfBuilder<InvoicePaymentState> {
    private userName = store.getState().auth.user.name;
    
    public downloadDocument(payment: InvoicePaymentState, additionalData?: any): void {
        const doc = this.generateDocument(payment, additionalData);
        var pdfName = "payment";
        doc.download(pdfName + '.pdf');
    }

    public generateDocument(payment: InvoicePaymentState, additionalData?: any): pdfMake.TCreatedPdf {
        PdfMake.vfs = PdfFonts.pdfMake.vfs;

        var docDefinition = {
            content: [
                this.generatePaymentContent(payment, additionalData)
            ],
            styles: {
                body: {
                    fontSize: 12
                },
                big_text: {
                    fontSize: 12
                },
                big_text_bold: {
                    fontSize: 12,
                    bold: true
                },
                medium_text: {
                    fontSize: 10
                },
                medium_text_bold: {
                    fontSize: 10,
                    bold: true
                },
                small_text: {
                    fontSize: 8
                },
                small_text_bold: {
                    fontSize: 8,
                    bold: true
                },
                table_text: {
                    fontSize: 9
                },
                table_text_bold: {
                    fontSize: 9,
                    bold: true
                },
                footer_text: {
                }
            }
        };

        return PdfMake.createPdf(docDefinition);
    }
    
    private generatePaymentContent(payment: InvoicePaymentState, additionalData: any): Content {
        return {
            table: {
                body: [
                    [this.generatePaymentFirstHalf(payment, additionalData)],
                    [this.generatePaymentSecondHalf(payment, additionalData)]
                ]
            }
        }
    }

    private generatePaymentFirstHalf(payment: InvoicePaymentState, additionalData: any): Content {
        return {
            columns: [
                this.generatePaymentSupplierData(additionalData),
                this.generatePaymentData(payment)
            ]
        };
    }

    private generatePaymentSupplierData(additionalData: any): Content {
        return {
            stack: [
                {text: additionalData.invoice.invoiceHeaderInfo.supplierData.name,
                 style: "big_text_bold"},
                " ",
                {text: "CUI: " + additionalData.invoice.invoiceHeaderInfo.supplierData.vatCode + ", Reg. Com.: " + additionalData.invoice.invoiceHeaderInfo.supplierData.regComCode,
                 style: "medium_text"},
                {text: "Capital social: -",
                 style: "medium_text"},
                {text: "Adresa: " + additionalData.invoice.invoiceHeaderInfo.supplierData.address,
                 style: "medium_text"},
                {text: additionalData.invoice.invoiceHeaderInfo.supplierData.bankName ? additionalData.invoice.invoiceHeaderInfo.supplierData.bankName : '-',
                 style: "medium_text"},
                {text: additionalData.invoice.invoiceHeaderInfo.supplierData.iban ? additionalData.invoice.invoiceHeaderInfo.supplierData.iban : '-',
                 style: "medium_text"}
            ]
        }
    }

    private generatePaymentData(payment: InvoicePaymentState): Content {
        return {
            stack: [
                " ",
                {text: "Chitanta",
                style: "big_text_bold"},
                " ",
                {text: "Serie/Numar " + payment.paymentData.series + payment.paymentData.number,
                style: "big_text_bold"},
                {text: "Data " + payment.paymentData.creationDate,
                style: "big_text_bold"}
            ]
        }
    }

    private generatePaymentSecondHalf(payment: InvoicePaymentState, additionalData: any): Content {
        return {
            stack: [
                {
                    columns: [
                        this.generateClientAndPaymentData(payment, additionalData),
                        this.generateClientDataExtra(additionalData)
                    ]
                },
                {text: "Casier, " + this.userName + "  ",
                 style: "medium_text",
                 alignment: "right"
                }
            ]
        };
    }

    private generateClientAndPaymentData(payment: InvoicePaymentState, additionalData: any): Content {
        return {
            stack: [
                {text: "Am primit de la " + additionalData.invoice.invoiceHeaderInfo.clientData.name,
                style: "medium_text_bold"},
                {text: "Adresa: " + additionalData.invoice.invoiceHeaderInfo.clientData.address,
                style: "medium_text"},
                {text: "Suma de " + payment.paymentData.totalValue + " lei",
                style: "medium_text_bold"},
                {text: "adica " + parseNumbersToRoWords(payment.paymentData.totalValue),
                style: "medium_text_bold"},
                {text: "Reprezentand factura " + additionalData.invoice.invoiceHeaderInfo.series + additionalData.invoice.invoiceHeaderInfo.number,
                style: "medium_text_bold"}
            ]
        }
    }

    private generateClientDataExtra(additionalData: any): Content {
        return {
            stack: [
                " ",
                {text: "CUI: " + additionalData.invoice.invoiceHeaderInfo.clientData.vatCode,
                style: "medium_text"},
                {text: "Reg. Com.: " + additionalData.invoice.invoiceHeaderInfo.clientData.regComCode,
                style: "medium_text"}
            ]
        }
    }
 }