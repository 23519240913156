import { InvoiceTableRowDataProvider } from "../dataProvider/invoiceTableRow.dataProvider";
import { CancelInvoiceResponse, GetZipFromEFacturaResponse, UploadInvoiceToEFacturaResponse } from "../types/invoiceTableRow.types";

export class InvoiceTableRowController {
    private dataProvider = new InvoiceTableRowDataProvider();
    
    public async uploadInvoiceToEFactura(invoiceId: string): Promise<UploadInvoiceToEFacturaResponse> {
        return await this.dataProvider.uploadInvoiceToEFactura(invoiceId);
    }

    public async waitForInvoiceUploadToFinish(invoiceId: string): Promise<UploadInvoiceToEFacturaResponse> {
        return await this.dataProvider.waitForInvoiceUploadToFinish(invoiceId);
    }

    public async downloadANAFInvoiceZip(downloadId: string): Promise<GetZipFromEFacturaResponse> {
        return await this.dataProvider.downloadANAFInvoiceZip(downloadId);
    }

    public async cancelInvoice(invoiceId: string): Promise<CancelInvoiceResponse> {
        return await this.dataProvider.cancelInvoice(invoiceId);
    }
}