import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import StateLoader from '../lib/StateLoader';

const middleware = [thunk];

const stateLoader = new StateLoader();

const store = createStore(
	rootReducer,
	stateLoader.loadState(),
	composeWithDevTools(applyMiddleware(...middleware))
);

store.subscribe(() => {
    stateLoader.saveState(store.getState());
});

export default store;
