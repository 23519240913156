import { PopupButtonTypes } from "../../components/ETComponents/ETPopup/constants";

export function dispatchPopupEvent(title: string, text: string, shouldLogoutUser: boolean = false, buttonsType: PopupButtonTypes = PopupButtonTypes.OK, handleYesButton = () => {}, handleNoButton = () => {}) {
    dispatchEvent(new CustomEvent(
        "etPopupEvent",
        {
            detail: {
                title: title, 
                text: text,
                buttonsType: buttonsType,
                shouldLogoutUser: shouldLogoutUser,
                handleYesButton: handleYesButton,
                handleNoButton: handleNoButton
            }
        }
    ));
}