import { BaseDataProvider } from "../../../../lib/baseDataProvider/base.dataProvider";
import { TimeRangeFilter } from "../../scaleReportsPage/types/types";
import { DashboardData, FilterForBackend } from "../types/types";

export default class ScaleReportsDashboardDataProvider extends BaseDataProvider {

    public async getDashboardData(appliedFilters: Array<FilterForBackend | TimeRangeFilter | undefined>): Promise<DashboardData> {
        const requestResult = await this.launchGetRequest<DashboardData>('/api/dashboards/scaleReports', null, appliedFilters);
        return requestResult.data;
    }
}