import { PieChart } from "@mui/x-charts/PieChart";
import { Component } from "react";

export interface ETDoughnutProps {
    value: number;
    fillLabel: string;
    max: number;
    unfilledLabel: string;
    colour: string;
    size: number;
}

export class ETDoughnutChart extends Component<ETDoughnutProps> {
    public constructor(props: ETDoughnutProps) {
        super(props);
    }

    render() {
        return (
            <PieChart
                series={[{
                    data: [{
                        value: this.props.value, label: this.props.fillLabel, color: this.props.colour
                    }, {
                        value: this.props.max - this.props.value, label: this.props.unfilledLabel, color: '#f3f3f3' 
                    }],
                    innerRadius: 40,
                    outerRadius: 50,
                    paddingAngle: 0,
                    cornerRadius: 0,
                    startAngle: 0,
                }]}
                margin={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                }}
                sx={{
                    "& .MuiChartsLegend-series": {
                        display: 'none'
                    },
                }}
                height={this.props.size}
                width={this.props.size}
            />
        )
    }
}