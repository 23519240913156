import { TimeRange } from "../../../ETComponents/EtTimeRangeFilter/etTimeRangeFilter.types";

export enum FILTER_NAMES {
    SUPPLIER = 'supplier',
    CLIENT = 'client',
    PRODUCT = 'product'
}

export interface IFilter {
    visible: boolean;
    appliedValue: string;
}

export type Filters  = {
    [name in FILTER_NAMES]: IFilter;
}

export interface InvoiceView {
    viewId: string;
    filters: Array<FilterForBackend>;
}

export interface FilterForBackend {
    name: string;
    values: Array<string>;
}

export interface RequestControllerResponse {
    valid: boolean;
    errorCode?: number;
    errorMessage?: string;
}

export interface LoadViewResponse extends RequestControllerResponse {
    view?: InvoiceView;
    transformedViewFilters?: Filters;
}

export interface GetInvoicesResponse extends RequestControllerResponse {
    invoices?: Array<Invoice>
}

export interface InvoicePageState {
    invoices: Array<Invoice>,
    selectedInvoice: Invoice | null,
    lastInvoice: Invoice | null,
    showInvoiceForm: boolean,
    timeRange: TimeRange,
    invoicePayment: Invoice | null
}

export interface Invoice {
    _id: string;
    invoiceHeaderInfo: {
        supplierData: {
            name: string;
            vatCode: string;
            vatPayer: boolean;
            regComCode: string;
            iban: string;
            bankName: string;
            address: string;
            detailedAddress : {
                countyCode: string;
                postalCode: string;
                cityName: string;
                streetName: string;
            }
        },
        clientData: {
            _id: string;
            name: string;
            vatCode: string;
            vatPayer: boolean;
            regComCode: string;
            iban: string;
            address: string;
            detailedAddress : {
                countyCode: string;
                postalCode: string;
                cityName: string;
                streetName: string;
            }
        },
        vatOnPayment: boolean;
        date: string;
        dueDate: string;
        number: string;
        series: string;
    },
    details: {
        netAmount: number;
        totalAmount: number;
        vatAmount: number;
    }
    productList: Array<InvoiceItem>,
    deliveryNotes: Array<DeliveryNote>,
    eFacturaData: {
        status: EFacturaProcessStatus;
        downloadId: string;
    }
    paymentId: string;
    invoiceCancellationInfo: {
        status: InvoiceCancellationStatus;
        pairInvoiceId: string;
    }
}

export interface PaymentData {
    _id: string;
    totalValue: number;
    type: string;
    number: number;
    series: string;
    encashmentDate: string;
    creationDate: string;
}

interface DeliveryNote {
    scaleReportId: string;
    series: string;
    number: string;
}

interface InvoiceItem {
    name: string;
    unitPrice: string;
    unitMeasure: string;
    quantity: string;
    vatPercentage: number;
    netAmount: number;
    totalAmount: number;
    type: ProductType;
}

export enum EFacturaProcessStatus {
    UNSYNCED = -1,
    INVALID_XML = 0,
    UPLOADING = 1,
    UPLOAD_OK = 2,
    UPLOAD_NOK = 3,
}

export enum ProductType {
    FROM_REPORTS = "from_reports",
    CUSTOM = "custom",
    TRANSPORT = "transport"
  };

export enum InvoiceCancellationStatus{
    STORNATA = "stornata",
	STORNO = "storno",
    NORMAL = "normal"
}