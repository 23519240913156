import { TimeRangeFilter } from "../../scaleReportsPage/types/types";
import ScaleReportsDashboardDataProvider from "../dataProvider/scaleReportsDashboard.dataProvider";
import { addComas, parseTime, splitNetWeightsWidgetArray } from "../helper/scaleReportsDashboard.helper";
import { DashboardData, DashboardWidgetsData } from "../types/types";

export class ScaleReportsDashboardController {
    private dataProvider: ScaleReportsDashboardDataProvider = new ScaleReportsDashboardDataProvider();

    public async getInvoices(timeRangeFilter: TimeRangeFilter): Promise<DashboardWidgetsData> {
        const allFilters: Array<TimeRangeFilter> = [timeRangeFilter];
        const data: DashboardData = await this.dataProvider.getDashboardData(allFilters);

        const mostWeightedProductsWidget = data.mostWeightedProductsWidget;

        mostWeightedProductsWidget.forEach((product) => {
            product.totalWeight = addComas(Number((Number(product.totalWeight)).toFixed(0)));
        })

        const [netWeightsWidgetLabels, netWeightsWidgetValues] = splitNetWeightsWidgetArray(data.netWeightsWidget);

        const topClientsWidgetValues = data.topClientsWidget.map(entry => { return Number((entry.totalWeight).toFixed(0)) });
        const topClientsWidgetLabels = data.topClientsWidget.map(entry => { return entry.name });
        let netTopClientsWidgetTotalValue = 0;
        topClientsWidgetValues.forEach(entry => {
            netTopClientsWidgetTotalValue += entry;
        });

        const parsedData: DashboardWidgetsData = {
            totalNetWeightWidgetText: `${addComas(data.totalNetWeightWidget)}`,
            numberOfProductsWidgetText: data.productsCountWidget.toString(),
            numberOfWeighingsWidgetText: `${addComas(data.scaleReportsCountWidget)}`,
            averageLoadingTimeWidgetText: parseTime(data.averageLoadingTimeWidget),
            netWeighsWidgetLabels: netWeightsWidgetLabels,
            netWeighsWidgetValues: netWeightsWidgetValues,
            mostWeighedProductsWidgetData: mostWeightedProductsWidget,
            topClientsWidgetLabels: topClientsWidgetLabels,
            topClientsWidgetValues: topClientsWidgetValues,
            topClientsWidgetTotalUnitMeasure: "t",
            topClientsWidgetTotalValue: addComas(netTopClientsWidgetTotalValue),
            doughnutWidgetsTotal: data.scaleReportsCountWidget,
            invoicedDoughnutValue: data.invoicedScaleReportsWidget,
            manualDoughnutValue: data.manualScaleReportsWidget,
            overweightDoughnutValue: data.overweightScaleReportsWidget,
            withUitCodeDoughnutValue: data.scaleReportsWithUitCodeWidget
        };

        return parsedData;
    }
}