import { Component } from "react";
import './loadingProgress.styles.css';
import reloadGif from '../../../../../images/reload-gif.gif';

interface LoadingProgressState {

}

interface LoadingProgressProps {
    currentProgress: number;
    maxProgress: number;
}

export class LoadingProgress extends Component<LoadingProgressProps, LoadingProgressState> {
    constructor(props: LoadingProgressProps) {
        super(props);

        this.state = this.getEmptyState();
    }

    private getEmptyState(): LoadingProgressState {
        return {};
    }

    render() {
        return (
            <div id='inside-loading-modal'>
                <div id='download-progress'>
                    <img src={reloadGif} className="file-downloading-gif" alt=""/>
                    <div>{this.props.currentProgress} / {this.props.maxProgress}</div>
                </div>
            </div>
        )
    }
}

export default LoadingProgress;