import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { grey } from "@mui/material/colors";
import { ETBarChart, ETBarChartProps } from "../../../ETComponents/ETBarChart/etbarchart.component";

export interface BarChartWidgetProps {
    title: string;
    underTitleElement?: JSX.Element;
    chartWidth: number
    chartProps: ETBarChartProps;
}

export interface BarChartWidgetState {
    
}

class BarChartWidget extends Component<BarChartWidgetProps, BarChartWidgetState> {

    render() {
        return (
            <Card sx={{borderRadius: 2}}>
                <Stack sx={{padding: 1.5}} spacing={2}>
                    <Box>
                        <Typography sx={{ color: grey[700]}} variant="h6" component="div">
                            <Box fontWeight='fontWeightMedium'>{this.props.title}</Box>
                        </Typography>
                        {this.props.underTitleElement}
                    </Box>
                    <Box height={250} width={this.props.chartWidth}>
                        <ETBarChart 
                            data={this.props.chartProps.data}
                            labels={this.props.chartProps.labels}
                            margins={this.props.chartProps.margins}
                            colours={this.props.chartProps.colours}
                            roundedBorders={this.props.chartProps.roundedBorders}
                            showValuesOnBars={this.props.chartProps.showValuesOnBars}
                            isHorizontal={this.props.chartProps.isHorizontal}
                            skipTicks={this.props.chartProps.skipTicks}
                            showYAxisValues={this.props.chartProps.showYAxisValues}
                        />
                    </Box>
                </Stack>
            </Card>
        )
    }
}

export default BarChartWidget;