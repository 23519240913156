import './profile.styles.css';
import profilePicture from  '../../../images/blank-avatar.png';
import { useDispatch } from 'react-redux';
import { setActiveModule } from '../../../redux/actions/application';
import { CONSTANTS } from '../../../lib/constants';
interface ProfileProps {
    name: string;
    companyName: string;
}


const Profile =  (props: ProfileProps) => {
    const dispatch = useDispatch();
    
    return (
        <div id='container'>
            <div id='profile-image'>
                <img  id='profile-cover' src={profilePicture} alt='profile-cover' />
            </div>
            <div id='profile-info'>
                <p id='profile-name'>{props.name}</p>
                <p id='company-name' onClick={() => dispatch(setActiveModule(CONSTANTS.MODULES.COMPANY_PROFILE_PAGE))}>{props.companyName}</p>
            </div> 
        </div>
    )
}

export default Profile;