import { BaseDataProvider } from "../../../../../../lib/baseDataProvider/base.dataProvider";
import { Company, ScaleReport } from "../../../../../../lib/types";
import { ClientData, InvoiceFormState, InvoiceSeriesData } from "../types/invoiceForm.types";

export default class InvoiceFormDataProvider extends BaseDataProvider {
    public async createInvoice(invoiceData: InvoiceFormState, preselectedScaleReportsIds: Array<string>) {
        await this.launchPostRequest('api/invoices/createInvoice', { invoiceData, preselectedScaleReportsIds });
    }

    public async updateInvoice(invoiceData: InvoiceFormState, invoiceId: string) {
        const updateInvoiceData = {
            productList: invoiceData.productList,
            netAmount: invoiceData.netAmount,
            vatAmount: invoiceData.vatAmount,
            totalAmount: invoiceData.totalAmount,
            date: invoiceData.date,
            dueDate: invoiceData.dueDate,
            currency: invoiceData.currency,
            deliveryNotes: invoiceData.deliveryNotes
        };

        const requestResult = await this.launchPostRequest(`api/invoices/updateInvoice`, {invoiceId, updateInvoiceData});
        return requestResult;
    }

    public async getAvailableClients(): Promise<Array<ClientData>> {
        const requestResult = await this.launchGetRequest<Array<ClientData>>('api/clients/getAvailableClients');
        return requestResult.data;
    }

    public async getInvoiceSeriesData(): Promise<InvoiceSeriesData> {
        const requestResult = await this.launchGetRequest<InvoiceSeriesData>('api/invoiceSeries/getInvoiceSeriesData');
        return requestResult.data;
    }

    public async getScaleReports(scaleReportsIds: Array<string>): Promise<Array<ScaleReport>> {
        const reportsIdsAsString = scaleReportsIds.join(',');
        const requestResult = await this.launchGetRequest<{ scaleReports: Array<ScaleReport> }>(`api/scaleReports/getReportsByIds?ids=${reportsIdsAsString}`);

        return requestResult.data.scaleReports;
    }

    public async getCompanyData(): Promise<Company> {
        const result = await this.launchGetRequest<Company>('api/companies/getUsersCompany');
        return result.data;
    }
}