export const CONSTANTS = {
    MODULES: {
        PROFILE_PAGE: 'profile_page',
        SUPERADMIN_PAGE: 'superadming_page',
        SCALE_REPORTS_PAGE: 'scale_reports_page',
        PURCHASE_ORDERS_PAGE: 'purchase_orders_page',
        WASTE_MANAGEMENT_PAGE: 'waste_management_page',
        ANAF_OAUTH_PAGE: 'anaf_ouath_page',
        INVOICE_PAGE: 'invoice_page',
        COMPANY_PROFILE_PAGE: 'company_profile_page',
        SCALE_REPORTS_DASHBOARD_PAGE: 'scale_reports_dashboard_page',
        CONVEYOR_REPORTS_PAGE: 'conveyor_reports_page',
        SCALE_DEVICES_RAW_EVENTS_PAGE: 'scale_devices_raw_events_page'
    },
    USER_ROLES: {
        ADMIN: 'admin',
        SUPERADMIN: 'superadmin'
    },
    FEATURES: {
        PURCHASE_ORDERS: 1000,
        WASTE_MANAGEMENT: 1001,
        SPV_INTEGRATION: 1002,
        CONVEYOR_REPORTS: 1003,
        SCALE_DEVICES_RAW_EVENTS: 1004,
    },
    SCALE_REPORT: {
        STATE: {
            COMPLETE: "Complete",
            PENDING: "Pending",
            CANCELLED: "Cancelled"
        }
    }
}