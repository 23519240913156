import { ANAFCompanyRecord } from "../types";
import UtilsDataProvider from "./utils.dataProvider";

export class Utils {
    public static getDateElements(date: Date): { year: string, month: string, day: string, hour: string, minute: string } {
        const year = new Intl.DateTimeFormat('en', { year: 'numeric'}).format(date);
        const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        const hour = new Intl.DateTimeFormat('en', { hour:'numeric', hour12:false }).format(date);
        const minute = new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(date);
        const parsedMinute = parseInt(minute) <= 9 ? '0' + minute : minute

        return { year, month, day, hour, minute: parsedMinute }
    }

    public static setColorByState(state: string): string {
        switch (state){
            case "Complete":
                return "green-color";
            case "Loading Truck":
            case "Sending Documents":
            case "Pending":
                return "orange-color";
            case "Cancelled":
                return "red-color";
            case "New":
            default:
                return "";
        }
    }
    
    public static async getCompanyDataFromAnaf(companyCui: string): Promise<ANAFCompanyRecord> {
        const dataProvider = new UtilsDataProvider();

        const date = new Date(Date.now());
        const { year, month, day } = Utils.getDateElements(date);
        
        const companyRecord = await dataProvider.getCompanyDataFromAnaf(companyCui, `${year}-${month}-${day}`);

        if (companyRecord.errMessage) {
            throw Error(companyRecord.errMessage);
        }

        return companyRecord.anafCompanyRecord;
    }

    public static isStringNonEmpty(input: string): boolean {
        return !!input && input.trim() !== "";
    }

    public static removeDuplicatesFromArray(array: Array<any>): Array<any> {
        if (!array || !array.length) {
            return [];
        }

        return [...new Set(array.map(element => JSON.stringify(element)))].map(element2 => JSON.parse(element2));
    }
}