import { ScaleReportsCsvBuilder } from "./scaleReportsCsvBuilder/scaleReportsCsvBuilder";
import { BaseCsvBuilder } from "./baseCsvBuilder";

export enum CsvDocumentType {
    ScaleReportsCsv
}

export class CsvBuilderFactory {
    public getCsvBuilder(documentType: CsvDocumentType): BaseCsvBuilder<any> {
        switch(documentType) {
            case CsvDocumentType.ScaleReportsCsv:
                return new ScaleReportsCsvBuilder();
            default:
                return new ScaleReportsCsvBuilder();
        }
    }
}
export { BaseCsvBuilder };

