import { ChangeEvent, Component, ReactElement } from 'react';
import { Company } from '../../../lib/types';
import './adminPanel.styles.css';
import { CONSTANTS } from '../../../lib/constants';
import { AdminPanelController } from './controller/adminPanel.controller';
import { LicenseKey } from './types/adminPanel.types';
import { Utils } from '../../../lib/utils/utils';
import { dispatchPopupEvent } from '../../../lib/events/popupEvent';

interface AdminPanelState {
    companies: Array<Company>,
    licenseKeyValabilityInMonths: number,
    licenseKeys: Array<LicenseKey>
}

interface AdminPanelProps {
}

class AdminPanel extends Component<AdminPanelProps, AdminPanelState> {

    private adminPanelController: AdminPanelController = new AdminPanelController();

    constructor(props: AdminPanelProps) {
        super(props);

        this.state = { 
            companies: [],
            licenseKeyValabilityInMonths: 6,
            licenseKeys: []
        };

        // bind event handlers here
        this.createUser = this.createUser.bind(this);
        this.getSelectedCompanyId = this.getSelectedCompanyId.bind(this);
        this.generateLicenseKey = this.generateLicenseKey.bind(this);
        this.reRenderLicenseKeys = this.reRenderLicenseKeys.bind(this);
        this.handleLicenseKeyValabilityChanged = this.handleLicenseKeyValabilityChanged.bind(this);
        this.handleLicenseKeyValabilityExit = this.handleLicenseKeyValabilityExit.bind(this);
    }

    async componentDidMount() {

        try {
            const getCompaniesPromise = this.adminPanelController.getExistingCompanies();
            const getLicenseKeysPromise = this.adminPanelController.getLicenseKeys();

            const [companies, licenseKeys] = await Promise.all([getCompaniesPromise, getLicenseKeysPromise]);
            this.setState({
                companies,
                licenseKeys 
            });
        } catch (error: any) {
            dispatchPopupEvent('Error', error.message);
        }

        
    }

    private async generateToken(): Promise<void> {
        const companyName = (document.getElementById('company-name-input') as HTMLInputElement).value;
        (document.getElementById('company-name-input') as HTMLInputElement).value = '';

        await this.adminPanelController.generateToken(companyName);

        this.setState({
            companies: await this.adminPanelController.getExistingCompanies()
        })
    }

    private async createUser(): Promise<void> {
        const userNameInput = (document.getElementById('user-name-input') as HTMLInputElement);
        const userEmailInput = (document.getElementById('user-email-input') as HTMLInputElement);
        const userPasswordInput = (document.getElementById('user-password-input') as HTMLInputElement);
        const userPasswordConfirmationInput = (document.getElementById('user-password-2-input') as HTMLInputElement);
        const userRole = CONSTANTS.USER_ROLES.ADMIN;
        const companyNameSelector = (document.getElementById('user-company-name') as HTMLInputElement);
        const companyId = this.getSelectedCompanyId(companyNameSelector);

        if (userPasswordInput.value !== userPasswordConfirmationInput.value) {
            //todo: create alert
            console.log('passwords do not match');
            return;
        }

        if (!companyId.length) {
            //todo: create alert
            console.log('company not found');
            return;
        }

        try {
            await this.adminPanelController.createUser(userNameInput.value, userEmailInput.value, userPasswordInput.value, userRole, companyNameSelector.value, companyId);
        } catch (error: any) {
            dispatchPopupEvent('User', error.message);
        }

        // clear form
        userNameInput.value = '';
        userEmailInput.value = '';
        userPasswordInput.value = '';
        userPasswordConfirmationInput.value = '';
        companyNameSelector.value = '';
    }

    private getSelectedCompanyId(companyNameSelector: HTMLInputElement): string {
        const companyArr: Array<Company> = this.state.companies.filter((company: Company) => company.companyName === companyNameSelector.value);
        const companyId = (!companyArr.length) ? '' : companyArr[0]._id;
        
        return companyId;
    }

    private async generateLicenseKey(): Promise<void> {
        try {
            const licenseKey = await this.adminPanelController.generateNewLicenseKey(this.state.licenseKeyValabilityInMonths);

            const licenseKeys = this.state.licenseKeys;
            licenseKeys.push(licenseKey);

            this.setState({
                licenseKeys: [],
                licenseKeyValabilityInMonths: 6
            }, () => this.reRenderLicenseKeys(licenseKeys))
        } catch (error: any) {
            dispatchPopupEvent('License key', error.message);
        }
    }

    private reRenderLicenseKeys(licenseKeys: Array<LicenseKey>): void {
        this.setState({
            licenseKeys: licenseKeys
        })
    }

    private handleLicenseKeyValabilityChanged(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
        const { value } = event.target;

        let parsedValue = 6;

        try {
            parsedValue = parseInt(value)
        } catch (error: any) {
            parsedValue = 6;
        }

        parsedValue = this.bindValueWithinBounds(parsedValue, 1, 1000000);

        this.setState({
            licenseKeyValabilityInMonths: parsedValue
        });
    }

    private handleLicenseKeyValabilityExit(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
        const { value } = event.target;

        let parsedValue = 6;

        try {
            parsedValue = parseInt(value)

            if(Number.isNaN(parsedValue)) {
                parsedValue = 6;
            }
        } catch (error: any) {
            parsedValue = 6;
        }

        parsedValue = this.bindValueWithinBounds(parsedValue, 1, 1000000);

        this.setState({
            licenseKeyValabilityInMonths: parsedValue
        });
    }

    private bindValueWithinBounds(value: number, lowerBound: number, upperBound: number): number {
        if(value < lowerBound) {
            value = lowerBound;
        }

        if(value > upperBound) {
            value = upperBound;
        }

        return value;
    }

    render() {

        const tableContent: Array<ReactElement | void> = this.state.licenseKeys.map((licenseKey) => {

            const activationDate = new Date(licenseKey.licenseKeyActivationTimestamp);
            const { year, month, day } = Utils.getDateElements(activationDate);
            const activationDateFormatted = `${day}/${month}/${year}`;
            const licenseKeyDurationFormatted = licenseKey.licenseKeyValabilityInMonths === 1 ? "luna" : "luni";
            const statusFormatted = licenseKey.status.charAt(0).toLocaleUpperCase() + licenseKey.status.slice(1);
            const typeFormatted = licenseKey.type.charAt(0).toLocaleUpperCase() + licenseKey.type.slice(1);

            return (
                <tr key={licenseKey._id} className='scale-reports-table-body-row'>
                    <td className='orders-reports-table-body-cell reports-cell'>
                        {licenseKey.key}
                    </td>
                    <td className='orders-reports-table-body-cell reports-cell'>
                        {licenseKey.licenseKeyValabilityInMonths} {licenseKeyDurationFormatted}
                    </td>
                    <td className='orders-reports-table-body-cell reports-cell'>
                        {typeFormatted}
                    </td>
                    <td className='orders-reports-table-body-cell reports-cell'>
                        {statusFormatted}
                    </td>
                    <td className='orders-reports-table-body-cell reports-cell'>
                        {licenseKey.licenseKeyActivationTimestamp === 0 ? "-" : activationDateFormatted}
                    </td>
                    <td className='orders-reports-table-body-cell reports-cell'>
                        {licenseKey.companyCui}
                    </td>
                </tr>
            )
        });

        return (
            <div className='etportal-page-container'>
                <h1 className='module-title'>Admin Panel</h1>

                <div className='et-section'>
                    <h3 className='et-section-title'>API Tokens</h3>
                    <div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Company Name:</label>
                            <input
                                id='company-name-input'
                                className='et-form-input'
                                type='text'
                                name='companyName'
                                required
                            />
                        </div>
                        <input 
                            className='et-button' 
                            id='generate-token-button' 
                            type='submit' 
                            value='Generate API Token'
                            onClick={this.generateToken}
                        />
                    </div>
                </div>

                <div className='et-section' id='user-section'>
                    <h3 className='et-section-title'>License keys</h3>
                    <div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>License valability in months:</label>
                            <input
                                id='license-key-valability-input'
                                className='et-form-input'
                                type='number'
                                name='licenseKeyValability'
                                onChange={this.handleLicenseKeyValabilityChanged}
                                onBlur={this.handleLicenseKeyValabilityExit}
                                value={this.state.licenseKeyValabilityInMonths}
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <input 
                                className='et-button' 
                                id='generate-token-button' 
                                type='submit' 
                                value='Generate license key'
                                onClick={this.generateLicenseKey}
                            />
                        </div>
                    </div>

                    <div id='table-container'>
                        <table id="scale-reports-table" className='header-fixed'>
                            <thead id="scale-reports-table-head">
                                <tr className='scale-reports-table-head-row'>
                                    <th className='orders-reports-table-head-cell reports-cell'>
                                        Cheie licenta
                                    </th>
                                    <th className='orders-reports-table-head-cell reports-cell'>
                                        Durata
                                    </th>
                                    <th className='orders-reports-table-head-cell reports-cell'>
                                        Tip
                                    </th>
                                    <th className='orders-reports-table-head-cell reports-cell'>
                                        Status
                                    </th>
                                    <th className='orders-reports-table-head-cell reports-cell'>
                                        Data activare
                                    </th>
                                    <th className='orders-reports-table-head-cell reports-cell'>
                                        Cui Companie
                                    </th>
                                </tr>
                            </thead>

                            <tbody id='license-keys-table-body'>
                                {tableContent}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='et-section' id='user-section'>
                    <h3 className='et-section-title'>Create User</h3>
                    <div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>User Name:</label>
                            <input
                                id='user-name-input'
                                className='et-form-input'
                                type='name'
                                name='userName'
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>User Email:</label>
                            <input
                                id='user-email-input'
                                className='et-form-input'
                                type='email'
                                name='userEmail'
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Password:</label>
                            <input
                                id='user-password-input'
                                className='et-form-input'
                                type='password'
                                name='userPassword'
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Confirm Password:</label>
                            <input
                                id='user-password-2-input'
                                className='et-form-input'
                                type='password'
                                name='userPassword'
                                required
                            />
                        </div>
                        <div className='et-form-group'>
                            <label className='et-form-label'>Company:</label>
                            <select name='companies' className='et-form-selector adminPanel-form-selector' id='user-company-name'>
                                {
                                    this.state.companies.map((company: Company, index: number) => {
                                        return <option key={index}>{company.companyName}</option>
                                    })
                                }
                            </select>
                        </div>
                        <input
                            className='et-button'
                            id='create-user-button'
                            type='submit'
                            value='Create User'
                            onClick={this.createUser}
                        />
                    </div>
                </div>
                
            </div>
        );
    }
}

export default AdminPanel;