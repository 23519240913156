import { BaseDataProvider } from "../../../../lib/baseDataProvider/base.dataProvider";
import { TimeRangeFilter } from "../../scaleReportsPage/types/types";
import { FilterForBackend, Invoice } from "../types/types";

export default class InvoicePageDataProvider extends BaseDataProvider {

    public async getInvoices(appliedFilters: Array<FilterForBackend | TimeRangeFilter | undefined>): Promise<Array<Invoice>> {
        const requestResult = await this.launchGetRequest<Array<Invoice>>('/api/invoices/getAllInvoices', null, appliedFilters);
        return requestResult.data;
    }
}