import { v4 as uuidv4 } from 'uuid';
import { AlertsAction } from '../types';
import { AlertsActionTypes } from './types';

export const setAlert = (msg: string, alertType: string) => (dispatch: any) => {
	const id: string = uuidv4();

	const setAlertAction: AlertsAction = {
		type: AlertsActionTypes.SET_ALERT,
		payload: { msg, alertType, id },
	};

	dispatch(setAlertAction);

	const removeAlertAction: AlertsAction = {
		type: AlertsActionTypes.REMOVE_ALERT,
		payload: { msg, alertType, id },
	}

	setTimeout(() => dispatch(removeAlertAction), 5000);
};
