export interface WorkPoint {
    _id: string;
    name: string;
    address: string;
    evtId: string;
    companyId: string;
    status: WorkpointStatus;
}

export interface Backup {
    id: string;
    type: BackupType;
    endpointId: string;
    date: Date;
}

export enum BackupType {
    MANUAL = "manual",
    AUTOMATIC = "automatic"
}

export interface GetBackupArchiveResponse {
    data: Blob;
    filename: string;
}

export enum WorkpointStatus {
    DISABLED = 'disabled',
    ENABLED = 'enabled'
}