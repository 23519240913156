import { ChangeEvent, Component } from "react";
import { ScaleDeviceRawEvent } from "../../types/types";

import "./scaleDeviceRawEventForm.styles.css";
import { dispatchRequestErrorEvent } from "../../../../../lib/events/requestErrorEvent";
import { ScaleDeviceRawEventFormController } from "./controller/scaleDeviceRawEventForm.controller";
import { dispatchPopupEvent } from "../../../../../lib/events/popupEvent";
import { Utils } from "../../../../../lib/utils/utils";

interface ScaleDeviceRawEventFormState {
    event: ScaleDeviceRawEvent;
}

interface ScaleDeviceRawEventFormProps {
    scaleDeviceRawEvent?: ScaleDeviceRawEvent;
    handleUpdateScaleDeviceRawEvent: (updatedEvent: ScaleDeviceRawEvent) => void;
    handleCancelUpdateScaleDeviceRawEvent: () => void;
}

class ScaleDeviceRawEventForm extends Component<ScaleDeviceRawEventFormProps, ScaleDeviceRawEventFormState> {
    
    constructor(props: any) {
        super(props);

        this.state = this.getEmptyState()

        // bind here event handlers
        this.handleUpdateEventClicked = this.handleUpdateEventClicked.bind(this);
        this.handleScaleDeviceRawEventNoteChanged = this.handleScaleDeviceRawEventNoteChanged.bind(this);
    }

    componentDidMount(): void {
        this.setState(this.props.scaleDeviceRawEvent ? {event: this.props.scaleDeviceRawEvent} : this.getEmptyState())
    }

    getEmptyState(): ScaleDeviceRawEventFormState {
        return {
            event: {
                id: "",
                metadata: {
                    deviceId: "",
                    macAddress: "",
                    dateInUTC: new Date(),
                },
                weighValue: "",
                note: ""
            }
        }
    }

    getDateString(date: Date) {
        const rawEventDate = new Date(date);
        const { month, day, hour, minute } = Utils.getDateElements(rawEventDate);
        return `${hour}:${minute} ${day}/${month}`;
    }

    async handleUpdateEventClicked() {
        try {
            await ScaleDeviceRawEventFormController.updateRawEvent(this.state.event);
        } catch (err: any) {
            dispatchRequestErrorEvent(err);
            return;
        }

        dispatchPopupEvent('Success', 'Evenimentul a fost modificat cu succes.');
        this.props.handleUpdateScaleDeviceRawEvent(this.state.event);
        this.setState(this.getEmptyState());
    }

    handleScaleDeviceRawEventNoteChanged(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { value } = event.target;
        const eventUpdate = this.state.event;
        eventUpdate.note = value;
        this.setState({
            event: eventUpdate
        });
    }

    render() {
        return (
            <div id='update-entity-form'>
                <div id='update-entity-header'>
                    <div id='entity-date' className='entity-header-data'>
                        <label id='entity-date-label'>Data</label>
                        <input 
                            id='entity-date-input'
                            className='et-form-input entity-text-input'
                            value={this.getDateString(this.state.event.metadata.dateInUTC)}
                            disabled={true}
                        />
                    </div>
                    <div id='entity-device' className='entity-header-data'>
                        <label id='entity-device-label'>Dispozitiv</label>
                        <input 
                            id='entity-date-input'
                            className='et-form-input entity-text-input'
                            value={this.state.event.metadata.macAddress}
                            disabled={true}
                        />
                    </div>
                    <div id='entity-weigh-value' className='entity-header-data'>
                        <label id='entity-weigh-value-label'>Valoare (kg)</label>
                        <input 
                            id='entity-date-input'
                            className='et-form-input entity-text-input'
                            value={this.state.event.weighValue}
                            disabled={true}
                        />
                    </div>
                    <div id='entity-note' className='entity-header-data'>
                        <label id='entity-note-label'>Nota</label>
                        <input 
                            id='entity-date-input'
                            className='et-form-input entity-text-input'
                            value={this.state.event.note}
                            onChange={this.handleScaleDeviceRawEventNoteChanged}
                        />
                    </div>
                </div>
                <div id='update-entity-form-footer'>
                    <input 
                        id='update-entity-button'
                        className='et-button'
                        type='submit'
                        value='Modifica evenimentul'
                        onClick={this.handleUpdateEventClicked}
                    ></input>
                    <input 
                        id='cancel-update-entity-button'
                        className='et-button-danger'
                        type='submit'
                        value='Renunta'
                        onClick={this.props.handleCancelUpdateScaleDeviceRawEvent}
                    ></input>
                </div>
            </div>
        )
    }
}

export default ScaleDeviceRawEventForm;