import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { grey } from "@mui/material/colors";

export interface TopWidgetProps {
    widgetIcon: JSX.Element;
    widgetTitle: string;
    widgetText: string | number;
}

export interface TopWidgetState {
}

class TopWidget extends Component<TopWidgetProps, TopWidgetState> {

    render() {
        return (
            <Card sx={{borderRadius: 2, flexGrow: 1}}>
                <Stack sx={{padding: 1.5}} spacing={1}>
                    <Stack direction="row" spacing={1}>
                        {this.props.widgetIcon}
                        <Typography gutterBottom variant="body2" color="gray" alignContent="center" component="div" sx={{ marginBottom: 0}}>
                            {this.props.widgetTitle}
                        </Typography>
                    </Stack>
                    <Typography sx={{ color: grey[700]}} alignContent="center" gutterBottom variant="h5" component="div">
                        <Box fontWeight='fontWeightMedium'>{this.props.widgetText}</Box>
                    </Typography>
                </Stack>
            </Card>
        )
    }
}

export default TopWidget;