export abstract class BaseCsvBuilder<T> {
    protected fileName: string = "";
    protected headers: Array<string> = [];
    protected elements: Array<T> = [];
    protected additionalInfo: any;

    public setFileName(fileName: string) {
        this.fileName = fileName;
    }

    public setHeaders(headers: Array<string>) {
        this.headers = headers;
    }

    public setContentElements(elements: Array<T>) {
        this.elements = elements;
    }

    public setAdditionalInfo(info: any) {
        this.additionalInfo = info;
    }

    protected abstract computeContentString(): string

    public downloadCsvFile() {
        const url = window.URL.createObjectURL(new Blob([this.computeContentString()]));
        const element = document.createElement('a');
        element.href = url;
        element.download = this.fileName;
        element.click();
    }
}