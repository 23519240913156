import PdfMake from "pdfmake/build/pdfmake";
import PdfFonts from "pdfmake/build/vfs_fonts";
import { Invoice } from "../../../components/layout/invoicePage/types/types";
import { Content } from "pdfmake/interfaces";
import logoImage from '../../../images/logo_evertec_v2.png';
import { BasePdfBuilder } from "../basePdfBuilder";

export class InvoicePdfBuilder extends BasePdfBuilder<Invoice> {
    public downloadDocument(invoice: Invoice): void {
        const doc = this.generateDocument(invoice);

        var pdfName = "Factura_" + invoice.invoiceHeaderInfo.series + invoice.invoiceHeaderInfo.number + "_" + invoice.invoiceHeaderInfo.clientData.name + "_" + invoice.invoiceHeaderInfo.date;
        doc.download(pdfName + '.pdf');
    }

    public generateDocument(invoice: Invoice) {
        PdfMake.vfs = PdfFonts.pdfMake.vfs;

        var docDefinition = {
            content: [
                this.generateInvoiceContent(invoice)
            ],
            styles: {
                body: {
                    fontSize: 12
                },
                big_text: {
                    fontSize: 12
                },
                big_text_bold: {
                    fontSize: 12,
                    bold: true
                },
                medium_text: {
                    fontSize: 10
                },
                medium_text_bold: {
                    fontSize: 10,
                    bold: true
                },
                small_text: {
                    fontSize: 8
                },
                small_text_bold: {
                    fontSize: 8,
                    bold: true
                },
                table_text: {
                    fontSize: 9
                },
                table_text_bold: {
                    fontSize: 9,
                    bold: true
                },
                footer_text: {
                }
            },
            footer: function(currentPage: number, pageCount: number) {
                return InvoicePdfBuilder.generatePageFooter(currentPage, pageCount);
            }
        };

        return PdfMake.createPdf(docDefinition);
    }

    public static generatePageFooter(currentPage: number, pageCount: number): Content {
        if(pageCount > 1) {
            return {
                text: currentPage.toString() + ' din ' + pageCount,
                alignment: "center"
            }
        }

        return {text:''}
    }

    private generateInvoiceContent(invoice: Invoice): Content {
        return [
            this.generateInvoiceDetails(invoice), // upper left corner
            this.generateInvoiceLogo(), // the logo on the upper right
            {
                columns: [
                    this.generateInvoiceSupplier(invoice),
                    [],
                    this.generateInvoiceClient(invoice)
                ]
            }, // middle section of the page, containing companies' informations
            this.generateInvoiceVATonPayment(invoice),
            this.generateInvoiceProductList(invoice),
            this.generateInvoiceAssociatedDeliveryNotes(invoice)
        ];
    }

    private generateInvoiceVATonPayment(invoice: Invoice): Content {
        if(invoice.invoiceHeaderInfo.vatOnPayment)
        {
            return {
                text: "TVA la incasare",
                alignment: "right",
                margin: [0, 0, 0, 10],
                style: 'medium_text'
            }
        }

        return []
    }

    private generateInvoiceDetails(invoice: Invoice): Content {
        return [
            {
                text: "FACTURA " + invoice.invoiceHeaderInfo.series + " " + invoice.invoiceHeaderInfo.number
            },
            {
                text: "Data emitere: " + invoice.invoiceHeaderInfo.date,
                style: 'medium_text'
            },
            {
                text: "Data scadenta: " + invoice.invoiceHeaderInfo.dueDate,
                margin: [0, 0, 0, 80], // bottom margin
                style: 'medium_text'
            },
        ];
    }

    private generateInvoiceLogo(): Content {
        return {
            image: logoImage,
            fit: [100, 100],
            absolutePosition: {x: 450, y: 50}
        };
    }

    private generateInvoiceSupplier(invoice: Invoice): Content {

        const vatCode = invoice.invoiceHeaderInfo.supplierData.vatPayer
            ? `RO${invoice.invoiceHeaderInfo.supplierData.vatCode}`
            : invoice.invoiceHeaderInfo.supplierData.vatCode;

        return [
            this.generateInvoiceCompanyField("", "Furnizor", [0, 0, 0, 0], false, false, 'big_text'),
            this.generateInvoiceCompanyField("", invoice.invoiceHeaderInfo.supplierData.name, [0, 0, 0, 10], false, false, 'big_text_bold'),
            this.generateInvoiceCompanyField("CUI", vatCode),
            this.generateInvoiceCompanyField("Reg. Com.", invoice.invoiceHeaderInfo.supplierData.regComCode),
            this.generateInvoiceCompanyField("Capital social", "-"),
            this.generateInvoiceCompanyField("Adresa", "", [0, 0, 0, 0]),
            this.generateInvoiceCompanyField("", invoice.invoiceHeaderInfo.supplierData.address, undefined, undefined, false),
            this.generateInvoiceCompanyField("Judet", invoice.invoiceHeaderInfo.supplierData.detailedAddress.countyCode),
            this.generateInvoiceCompanyField("Tara", "Romania"),
            this.generateInvoiceCompanyField(invoice.invoiceHeaderInfo.supplierData.bankName, "", undefined, false, false),
            this.generateInvoiceCompanyField(invoice.invoiceHeaderInfo.supplierData.iban, "", [0, 0, 0, 30], false, false),
            this.generateInvoiceCompanyField("Email", "-"),
            this.generateInvoiceCompanyField("Telefon", "-"),
            this.generateInvoiceCompanyField("Web", "-")
        ];
    }

    /*
    function parseBankNameByIBAN(iban: string)
    {
        var bankIdentifier = iban.substring(4, 8);
        console.log(bankIdentifier);

        /*
        switch(bankIdentifier)
        {
            case "ABNA":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "ABNA":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";

            case "ABNA":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "ABNA":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";

            case "ABNA":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "ABNA":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";

            case "ABNA":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "ABNA":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
            case "BUCU":
                return "";
        }
        

        return "nume banca";
    }
    */

    private generateInvoiceClient(invoice: Invoice): Content {

        const vatCode = invoice.invoiceHeaderInfo.clientData.vatPayer
            ? `RO${invoice.invoiceHeaderInfo.clientData.vatCode}`
            : invoice.invoiceHeaderInfo.clientData.vatCode;

        return [
            this.generateInvoiceCompanyField("", "Client", [0, 0, 0, 0], false, false, 'big_text'),
            this.generateInvoiceCompanyField("", invoice.invoiceHeaderInfo.clientData.name, [0, 0, 0, 10], false, false, 'big_text_bold'),
            this.generateInvoiceCompanyField("CUI", vatCode),
            this.generateInvoiceCompanyField("Reg. Com.", invoice.invoiceHeaderInfo.clientData.regComCode),
            this.generateInvoiceCompanyField("Tara", "Romania"),
            this.generateInvoiceCompanyField("Judet", invoice.invoiceHeaderInfo.clientData.detailedAddress.countyCode),
            this.generateInvoiceCompanyField("Localitate", invoice.invoiceHeaderInfo.clientData.detailedAddress.cityName),
            this.generateInvoiceCompanyField("Adresa", "", [0, 0, 0, 0]),
            this.generateInvoiceCompanyField("", invoice.invoiceHeaderInfo.clientData.address, undefined, undefined, false),
            this.generateInvoiceCompanyField("Email", "-"),
            this.generateInvoiceCompanyField("Telefon", "-", [0, 0, 0, 30]),
            this.generateInvoiceCompanyField("Contact", "-"),
            this.generateInvoiceCompanyField("Functie", "-")
        ];
    }

    private generateInvoiceProductList(invoice: Invoice): Content {

        let tableEntries = [];

        tableEntries.push([
            {
                text: '#',
                color: 'white'
            },
            {
                text: 'Produs / Serviciu',
                color: 'white',
                style: 'table_text'
            },
            {
                text: 'U.M.',
                color: 'white',
                style: 'table_text'
            },
            {
                text: 'Cant.',
                alignment: 'right',
                color: 'white',
                style: 'table_text'
            },
            {
                text: "Pret unitar",
                alignment: 'right',
                color: 'white',
                style: 'table_text'
            },
            {
                text: "Valoare",
                alignment: 'right',
                color: 'white',
                style: 'table_text'
            },
            {
                text: ""
            },
            {
                text: "TVA",
                alignment: 'right',
                color: 'white',
                style: 'table_text'
            }
        ]);
        
        let totalNetAmount = 0;
        let totalVAT = 0;
        let total = 0;
        
        for(let productID = 0; productID < invoice.productList.length; productID++) {
            const product = invoice.productList[productID];
            const isLast = productID <= invoice.productList.length - 1;
            const border = (isLast) ? ['#ffffff', '#848484', '#ffffff', '#ffffff'] :  ['#ffffff', '#ffffff', '#ffffff', '#848484']

            let tableItem = [
                {
                    text: productID + 1,
                    borderColor: border,
                    border: [false, true, false, false]
                },
                {
                    text: product.name,
                    borderColor: border,
                    border: [false, true, false, false]
                },
                {
                    text: this.parseUnitMeasure(product.unitMeasure),
                    borderColor: border,
                    border: [false, true, false, false]
                },
                {
                    text: product.quantity,
                    alignment: 'right',
                    borderColor: border,
                    border: [false, true, false, false]
                },
                {
                    text: product.unitPrice,
                    alignment: 'right',
                    borderColor: border,
                    border: [false, true, false, false]
                },
                {
                    text: parseFloat(product.netAmount.toFixed(2)),
                    alignment: 'right',
                    borderColor: border,
                    border: [false, true, false, false]
                },
                {
                    text: product.vatPercentage + "%",
                    alignment: 'right',
                    borderColor: border,
                    border: [false, true, false, false]
                },
                {
                    text: "" + parseFloat((product.totalAmount - product.netAmount).toFixed(2)),
                    alignment: 'right',
                    borderColor: border,
                    border: [false, true, false, false]
                }
            ];
            tableEntries.push(tableItem);

            totalNetAmount += parseFloat(product.netAmount.toFixed(2));
            totalNetAmount = parseFloat(totalNetAmount.toFixed(2));
            totalVAT += parseFloat((parseFloat(product.totalAmount.toFixed(2)) - parseFloat(product.netAmount.toFixed(2))).toFixed(2));
        }

        totalVAT = parseFloat(totalVAT.toFixed(2));

        tableEntries.push(
            [
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: "Total",
                    alignment: 'right',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: totalNetAmount,
                    alignment: 'right',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: totalVAT,
                    colSpan: 2,
                    alignment: 'right',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                }]
            );
        total = parseFloat((parseFloat(totalNetAmount.toFixed(2)) + parseFloat(totalVAT.toFixed(2))).toFixed(2));
        tableEntries.push(
            [
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: '',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: "TOTAL",
                    alignment: 'right',
                    style: 'table_text_bold',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                },
                {
                    text: total + " lei",
                    colSpan: 2,
                    alignment: 'right',
                    style: 'table_text_bold',
                    borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
                }]
            );
        
        let table = {
                headerRows: 1,
                widths: [10, '*', 40, 40, 60, 40, 20, 60],
                body: tableEntries
            };
        
        return {
            table: table,
            layout: {
                fillColor: function (rowIndex) {
                    return (rowIndex === 0) ? '#424242' : '#ffffff';
                },
                hLineColor: function () {
                    return 'white';
                },
                vLineColor: function () {
                    return 'white';
                },
                paddingTop: function() {
                    return 10;
                },
                paddingBottom: function() {
                    return 10;
                },
                paddingLeft: function(i) {
                    return (i < 3) ? 4 : 0;
                },
                paddingRight: function(i) {
                    return (i < 3) ? 0 : 4;
                }
            },
            style: 'small_text'
        };
    }

    private generateInvoiceAssociatedDeliveryNotes(invoice: Invoice): Content {
        if(!invoice.deliveryNotes || invoice.deliveryNotes.length === 0) {
            return [];
        }

        let deliveryNoteText = "Factura intocmita pe baza " + (invoice.deliveryNotes.length === 1 ? "avizului" : "avizelor") + ":";

        invoice.deliveryNotes.forEach(deliveryNote => {
            const deliveryNoteSeries = deliveryNote.series !== null && deliveryNote.series !== undefined ? deliveryNote.series : "";
            deliveryNoteText += " " + deliveryNoteSeries + deliveryNote.number + ","
        });

        deliveryNoteText = deliveryNoteText.substring(0, deliveryNoteText.length - 1);
        
        return {
            text: deliveryNoteText,
            alignment: "left",
            margin: [0, 0, 0, 10],
            style: 'medium_text'
        };
    }
}