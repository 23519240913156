import { ClientDetailedAddress, UnitMeasureCodes } from "../../../../../../lib/types";
import { ProductType } from "../../../types/types";

export interface ClientData {
    _id: string;
    companyId: string;
    name: string;
    details: ClientDetails;
}

export interface ClientDetails {
    vatCode: string;
    regComCode: string;
    address: string;
    iban: string;
    detailedAddress: ClientDetailedAddress;
    vatPayer: boolean;
}

export interface InvoiceFormState {
    clientList: Array<ClientData>;
    productList: Array<Product>;
    deliveryNotes: Array<DeliveryNote>;
    netAmount: number;
    vatAmount: number;
    totalAmount: number;
    clientId: string;
    clientName: string;
    clientVatCode: string;
    clientAddress: string;
    clientDetailedAddress: ClientDetailedAddress;
    clientIban: string;
    clientRegCode: string;
    clientVatPayer: boolean;
    date: string;
    dueDate: string;
    currency: CurrencyCodes;
    number: string;
    series: string;
    createdWithScaleReports: boolean;
    finishedLoadingDataFromScaleReports: boolean;
}

export interface DeliveryNote {
    scaleReportId: string;
    number: number;
    series: string;
}

export interface Product {
    name: string;
    unitMeasure: UnitMeasureCodes;
    unitPrice: string;
    quantity: string;
    vatPercentage: number;
    netAmount: number;
    totalAmount: number;
    type: ProductType;
}

export enum CurrencyCodes {
    RON = 'RON',
    EUR = 'EUR'
}

export interface InvoiceSeriesData {
    code: string,
    number: number
}