import { ChangeEvent, Component, RefObject } from "react";

import './invoiceProduct.styles.css';
import React from "react";
import { Product } from "../invoiceForm/types/invoiceForm.types";
import { ProductType } from "../../types/types";
import { AcceptedNumberType, ETNumberInput } from "../../../../ETComponents/ETNumberInput/etnumberinput.component";
import { UnitMeasureCodes } from "../../../../../lib/types";

interface InvoiceProductState {
    netValue: number;
    totalValue: number;
    unitPrice: number | string;
    vatPercentage: number;
    quantity: number | string;
    name: string;
    type: ProductType;
}

interface InvoiceProductProps extends Product {
    index: number;
    handleAttributeChanged: (attribute: string, value: number | string, index: number) => void;
    handleTotals: (totalValue: number, netValue: number, index: number) => void;
    handleRemoveProduct: (index: number, resetDeliveryNotes: boolean) => void;
    handleQuantityChanged: (productName: string, quantity: string) => void;
    createdWithScaleReports: boolean;
};

class InvoiceProduct extends Component<InvoiceProductProps, InvoiceProductState> {
    private netValueInput: RefObject<HTMLInputElement>;
    private totalValueInput: RefObject<HTMLInputElement>;

    private unitMeasures = [
        {label: 'KG', value: UnitMeasureCodes.KG},
        {label: 'TNE', value: UnitMeasureCodes.TNE},
        {label: 'MC', value: UnitMeasureCodes.METRIC_CUBE},
        {label: 'BUC', value: UnitMeasureCodes.BUC},
        {label: 'KM', value: UnitMeasureCodes.KILOMETER}
    ] 

    constructor(props: any) {
        super(props);

        this.state = {
            netValue: this.props.netAmount,
            totalValue: this.props.totalAmount,
            unitPrice: this.props.unitPrice,
            vatPercentage: this.props.vatPercentage,
            quantity: this.props.quantity,
            name: this.props.name,
            type: this.props.type
        }

        this.netValueInput = React.createRef<HTMLInputElement>();
        this.totalValueInput = React.createRef<HTMLInputElement>();

        this.removeButtonClicked = this.removeButtonClicked.bind(this);
        this.handleProductAttributeChange = this.handleProductAttributeChange.bind(this);
        this.handleProductAttributeChangeWrapper = this.handleProductAttributeChangeWrapper.bind(this);
    }

    private removeButtonClicked() {
        this.props.handleRemoveProduct(this.props.index, this.state.type === ProductType.TRANSPORT ? false : true);
    }

    private handleProductAttributeChange(event:ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        this.handleProductAttributeChangeWrapper({name: event.target.name, value: event.target.value});
    }

    private handleProductAttributeChangeWrapper(changedAttribute: {name: string; value: string}) {
        const { name, value } = changedAttribute;

        this.props.handleAttributeChanged(name, value, this.props.index);

        if (name === 'name') {
            this.setState({ name: value });
        }
        
        if (name === 'unitPrice') {
            this.setState({
                unitPrice: value
            }, this.computeLocalAmmountsAndSendToParent);
        } else if (name === 'vatPercentage') {
            this.setState({
                vatPercentage: parseFloat(value)
            }, this.computeLocalAmmountsAndSendToParent);
        } else if (name === 'quantity') {
            // if the quantity of the produst is changed, we have to know in the form component
            this.setState({
                quantity: value
            }, this.computeLocalAmmountsAndSendToParent);
            this.props.handleQuantityChanged(this.state.name, value);
        }
    }

    private ensureNumber(input: string | number): number {
        const inputAsString = typeof input === 'string' ? input : JSON.stringify(input);
        return inputAsString.indexOf('.') > 0
            ? parseFloat(inputAsString)
            : parseInt(inputAsString);
    }

    private computeLocalAmmountsAndSendToParent() {
        const unitPriceEnsured = this.ensureNumber(this.state.unitPrice);
        const quantityEnsured = this.ensureNumber(this.state.quantity);
        const vatPercentageEnsured = this.ensureNumber(this.state.vatPercentage);

        // if any of these variables is NaN it means it's an intermediarry value (e.g: '11.' or '')
        // in which case we shouldn't change totals but wait for next input
        if (
            isNaN(unitPriceEnsured)
            || isNaN(quantityEnsured)
            || isNaN(vatPercentageEnsured)
        ) {
            return;
        }

        
        const netAmmount = parseFloat((unitPriceEnsured * quantityEnsured).toFixed(2));
        const vatAmmount = parseFloat((netAmmount * (vatPercentageEnsured / 100)).toFixed(2));
        const totalAmmount = parseFloat((netAmmount + vatAmmount).toFixed(2));

        this.setState(
            {
                netValue: netAmmount,
                totalValue: totalAmmount
            },
            () => {
                this.props.handleTotals(totalAmmount, netAmmount, this.props.index);
            }
        );
    }

    render() {
        return (
            <div id='product-data-container' className={this.props.index === 0 ? 'container-margin-top' : ''}>
                <div id='product-remove-button-container' className='product-data'>
                    <input 
                        className='et-button-danger remove-product-button' 
                        type='submit' 
                        value='Elimina' 
                        onClick={() => this.removeButtonClicked() }
                    ></input>
                    <span className="red-text">{this.props.createdWithScaleReports && this.state.type === ProductType.FROM_REPORTS ? <> *</> : <></>}</span>
                </div>
                <div id='product-name-container' className='product-data'>
                    <label id='product-name-label' className='product-label'>Articol <span className="red-text">{this.props.createdWithScaleReports && this.state.type === ProductType.FROM_REPORTS ? <> *</> : <></>}</span>:</label>
                    <input
                        id='product-name-input'
                        className='et-form-input invoice-text-input'
                        required
                        name='name'
                        onChange={this.handleProductAttributeChange}
                        value={this.state.name}
                        disabled={this.state.type === ProductType.TRANSPORT ? true : false}
                    />
                </div>
                <div id='product-unit-measure-container' className='product-data'>
                    <label id='product-unit-measure-label' className='product-label'>UM:</label>
                    <select 
                        className='et-form-selector invoice-selector'
                        onChange={this.handleProductAttributeChange}
                        name='unitMeasure'
                        value={this.props.unitMeasure}
                    >
                        {this.unitMeasures.map((unitMeasureItem, index) => (
                            <option key={`unitMeasure_${index}`} value={unitMeasureItem.value}>{unitMeasureItem.label}</option>
                        ))}
                    </select>
                </div>
                <div id='product-vat-container' className='product-data'>
                    <label id='product-vat-label' className='product-label'>TVA(%):</label>
                    <ETNumberInput 
                        id='product-vat-input'
                        className='et-form-input invoice-text-input'
                        name='vatPercentage'
                        defaultValue={this.props.vatPercentage}
                        acceptedNumberType={AcceptedNumberType.INTEGER}
                        onChange={this.handleProductAttributeChangeWrapper}
                    />
                </div>
                <div id='product-quantity-container' className='product-data'>
                    <label id='product-quantity-label' className='product-label'>Cantitate<span className="red-text">{this.props.createdWithScaleReports && this.state.type === ProductType.FROM_REPORTS ? <> *</> : <></>}</span>:</label>
                    <ETNumberInput 
                        id='product-quantity-input'
                        className='et-form-input invoice-text-input'
                        name='quantity'
                        defaultValue={parseFloat(this.props.quantity)}
                        acceptedNumberType={AcceptedNumberType.FLOAT}
                        onChange={this.handleProductAttributeChangeWrapper}
                    />
                </div>
                <div id='product-unit-price-container' className='product-data'>
                    <label id='product-unit-price-label' className='product-label'>Pret unitar:</label>
                    <ETNumberInput 
                        id='product-unit-price-input'
                        className='et-form-input invoice-text-input'
                        name='unitPrice'
                        defaultValue={parseFloat(this.props.unitPrice)}
                        acceptedNumberType={AcceptedNumberType.POSITIVE_FLOAT}
                        onChange={this.handleProductAttributeChangeWrapper}
                    />
                </div>
                <div id='product-net-value-container' className='product-data'>
                    <label id='product-net-value-label' className='product-label'>Valoare neta:</label>
                    <input
                        id='product-net-value-input'
                        className='et-form-input invoice-text-input'
                        required
                        disabled
                        name='netAmmount'
                        value={this.state.netValue}
                        onChange={this.handleProductAttributeChange}
                        ref={this.netValueInput}
                    />
                </div>
                <div id='product-total-value-container' className='product-data'>
                    <label id='product-total-value-label' className='product-label'>Total:</label>
                    <input
                        id='product-total-value-input'
                        className='et-form-input invoice-text-input'
                        required
                        disabled
                        name='totalAmmount'
                        value={this.state.totalValue}
                        onChange={this.handleProductAttributeChange}
                        ref={this.totalValueInput}
                    />
                </div>
            </div>
        )
    }
}

export default InvoiceProduct;